import React from "react";

// Customizable Area Start
import {Typography, Box, styled} from "@material-ui/core";
import Header from "../../../components/src/Header";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Footer from "../../../components/src/Footer";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
// Customizable Area End

import TermsConditionsDetailController, {
  Props,
  configJSON,
} from "./TermsConditionsDetailController";
import Loader from "../../../components/src/LoaderContainer";
import i18n from "../../../components/src/i18next/i18n";

export default class TermsConditionsDetail extends TermsConditionsDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderDesc = () => {
    return <> 
      {this.state.desc ? <div dir={i18n.dir()} className="description" dangerouslySetInnerHTML={{ __html: this.state.desc }}/> :
        <Box className="loaderContainer">
          <Typography className="CommingSoon">
          {this.transFash(configJSON.ComingSoonMsg)}
          </Typography>
        </Box> 
      }
    </>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (<>
      {this.state.buyertylish ? <StylishBuyerHeader navigation={this.props.navigation} /> : <Header navigation={this.props.navigation} /> }
      <Box dir={i18n.dir()}>
        <BreadCrumb>
          <Typography className="active" data-test-id="homeAbout" onClick={this.handleLandingPageFromAbout}>{this.transFash("Home")}</Typography>
          <ChevronRightRoundedIcon style={{ color: "rgba(55, 82, 128, 1)", transform: i18n.dir() === "rtl" ? "rotate(180deg)": "rotate(0deg)"}} />
          <Typography className="deActive">{this.transFash('About Fash')}</Typography>
        </BreadCrumb>
        <MainContainer>
          <Box className="firstSection">
            <Typography className="title">{this.transFash(configJSON.AboutFashMsg)}</Typography>
          </Box>
          <Box className="secondSection">
            <Box className="descBox">
              {this.state.isLoading ?  
              <Box className="loaderContainer"><Loader id={"fashloader"} dataTestId={"fashLoaderTest"}/></Box>
              : this.renderDesc()}
            </Box>
          </Box>
        </MainContainer>
      </Box>
      <Footer navigation={this.props.navigation}/>
    </>)
    // Customizable Area End
  }
}

// Customizable Area Start

const MainContainer = styled(Box)({
  backgroundColor: "#f2efef",
  paddingBottom: "2.5rem",
  display: "flex",
  flexDirection: "row",
  gap: "1.25rem",
  "& .firstSection": {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Lato",
    padding: "0 0.9375rem 0.9375rem 1.5625rem",
    width: "15%",
    "& .title": {
      color: "#375280",
      fontSize: "1.75rem",
      fontWeight: 800,
      fontFamily: "Lato",
      width: "max-content",
      padding: "0",
    },
  },
  "& .secondSection": {
    width: "85%",
    margin: "0.25rem 1.5rem 1.125rem 1.25rem",
    "& .descBox": {
      backgroundColor: "#fff",
      borderRadius: "0.125rem",
      border: "0.0625rem solid #E2E8F0",
      padding: "0.625rem 1.25rem",
      fontFamily: "Lato",
    },
    "& .CommingSoon": {
      fontSize: "2.5rem",
      fontWeight: 900,
      fontFamily: "Lato",
      color: "#375280",
      margin: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 0.625rem 0.625rem",
    },
    "& .description": {
      fontFamily: "Lato",
      color: "#375280",
      maxWidth: "100%",
      wordBreak: "break-word"
    },
    "& .loaderContainer":{
      height: "calc(100vh - 350px)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }
  },

  "@media (max-width: 768px)": {
    flexDirection: "column",
    "& .firstSection": {
      width: "100%",
      padding: "0.9375rem",
      paddingBottom: 0,
    },
    "& .secondSection": {
      width: "initial",
      margin: "0 0.9375rem 0.9375rem"
    },
  },
});

const BreadCrumb = styled(Box)({
  display: "flex",
  gap: "0.3125rem",
  flexDirection: "row",
  padding: "1.5625rem 1.5625rem 1.875rem 1.5625rem",
  width: "100%",
  backgroundColor: "#f2efef",
  cursor: "default",
  "& p": {
    fontFamily: "Lato",
    color: "#375280",
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  "& .active": {
    cursor: "pointer",
  },
  "& .deActive": {
    color: "#94A3B8",
  },

  "@media (max-width: 768px)": {
    padding: "1rem",
  },
});


// Customizable Area End
