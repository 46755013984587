import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { Button, Card, Typography, IconButton, Menu, MenuItem, styled, Box, InputAdornment, TextField, Avatar,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox
 } from "@material-ui/core";
import { Formik } from "formik";
import OneToOneChatController, {
  Message1,
  OrderRequest,
  paymentRequest,
  Props,
  validationReportSchema,
  WishlistMsg,
} from "./OneToOneChatCotroller.web";
import { ThemeProvider } from "react-native-elements";
import { ClientChatMore, chatFashionIMg, linkImageIcon, sendMessageIcon, profileImage } from "./assets";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/src/LoaderContainer";
import ReusableSnackbar from "../../../components/src/AlertContainer";
import FileInputPreview from "../../../components/src/Chat/FileInputPreivew.web";
import ChatFilePreview from "../../../components/src/Chat/ChatFilePreview.web";
import i18n from "../../../components/src/i18next/i18n";
import { FormError, FormErrorTouched } from "../../customform/src/CreateSellerStoreController";
import { ClientNewRequestCustomInput } from "./ClientNewPaymentRequest.web";
import { FormHelperTextContainer, OrderReqSelectCustom } from "./ClientNewOrderRequest.web";
import { selectEndIcon } from "../../customform/src/assets";
import { handleImgUrl } from "../../../components/src/Chat/chat.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class OneToOneChat extends OneToOneChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderWelcomeContent = () => {
    const { senderFullName, senderProfile } = this.state
    return <WelcomeSellerContainer>
      <Box>
      <img src={senderProfile || profileImage}
        style={{
          marginRight: i18n.dir() === "rtl" ? 0 :"7px",
          marginLeft: i18n.dir() === "ltr" ? 0 :"7px",
        }}
        alt="profile"/>
      </Box>
      <Box>
      <Typography style={{ fontSize: "17px"}} className="title">{this.transChat("Welcome!")}</Typography>
      <Typography style={{ fontSize: "25px", textTransform: "capitalize"}} className="title">{senderFullName ||  ""}</Typography>
      </Box>
  </WelcomeSellerContainer>
  }

  renderOrderRequest = (message: OrderRequest, isSender: boolean) => {
      const {
        product_name,
        gender,
        size,
        color,
        product_quantity,
        price_per_unit,
        shipping_cost,
        product_display_image_url,
        status
      } = message;
      const total_cost =
        Number(shipping_cost) +
        Number(price_per_unit) * Number(product_quantity);
      const url = handleImgUrl(product_display_image_url);
      const disabled = isSender || status === "in_cart"
      return (
        <OrderCardContainer>
          <Box className="avatar-box">
            <img className="avatar" src={url || profileImage} alt="profile" />
            <Typography variant="h6" className="name-text">
              {product_name}
            </Typography>
          </Box>
          <Box className="action-box">
            <Box className="menu">
              <Typography className="title">{this.transChat("Gender")}</Typography>
              <Typography className="value">{gender}</Typography>
            </Box>
            <Box className="menu">
              <Typography className="title">{this.transChat("Size")}</Typography>
              <Typography className="value">{size}</Typography>
            </Box>
            <Box className="menu">
              <Typography className="title">{this.transChat("Color")}</Typography>
              <Typography className="value">{color}</Typography>
            </Box>
            <Box className="menu">
              <Typography className="title">{this.transChat("Quantity")}</Typography>
              <Typography className="value">{product_quantity}</Typography>
            </Box>
            <Box className="menu">
              <Typography className="title">{this.transChat("Price Per Unit")}</Typography>
              <Typography className="value">${price_per_unit}</Typography>
            </Box>
            <Box className="menu">
              <Typography className="title">{this.transChat("Shipping Cost")}</Typography>
              <Typography className="value">${shipping_cost}</Typography>
            </Box>
            <Box className="menu">
              <Typography className="title">{this.transChat("Total Cost")}</Typography>
              <Typography className="value">${total_cost}</Typography>
            </Box>
          </Box>
          <Button disabled={disabled} data-test-id="addToCart" onClick={() => this.addToCart(message.order_request_id)} fullWidth className="order-button" style={{ backgroundColor: isSender ? "#CCBEB1" : "#375280" }}>
            {status === "in_cart" ? this.transChat("Order Accepted") :this.transChat("Accept & Add to Cart")}
          </Button>
        </OrderCardContainer>
      );
  }

  renderPaymentRequest = (message: paymentRequest, isSender: boolean) => {
    const {chat_message, amount, status } = message.data.attributes
    const isPayId = this.state.isPay === message.data.id 
    const disabled = isSender || status !== "pending"
    return  <OrderCardContainer>
      <Box className="avatar-box">
        <Typography variant="h6" className="payment-text">{chat_message}</Typography>
      </Box>
      <div id="chekboxContainer" style={{ display: 'flex', marginBottom: '10px' }}>
        <CustomCheckbox 
          id="checkboxPay" 
          disabled={disabled}
          data-test-id="setpricechecked" 
          inputProps={{ 'data-test-id': 'setpricechecked' } as React.InputHTMLAttributes<HTMLInputElement> & { 'aria-label'?: string; 'data-testid'?: string; }} 
          checked={isPayId} 
          style={{ 
            marginRight: i18n.dir() === "rtl" ? 0 :"7px",
            marginLeft: i18n.dir() === "ltr" ? 0 :"7px",
          }}
          onChange={()=>this.handleCheckboxChange(message.data.id)} 
        />
        &nbsp;
        <div id="checboxLabel">
            <div style={{ fontSize: '14px', color: '#94A3B8', fontFamily: 'Lato', fontWeight: 500 }}>
            {this.transChat("I agree to the stylist’s")}&nbsp;<span style={{ fontSize: '14px', fontFamily: 'Lato', fontWeight: 500, borderBottom: '1px solid #375280', color: '#375280', cursor: 'pointer' }} data-testid="policiesStylistPage" onClick={() => this.handleNavigationPage("TermsConditionsStylist")}>{this.transChat("Terms and Conditions")}</span>&nbsp;{this.transChat("of services")}.
            </div>
        </div>
      </div>
      <Button disabled={disabled} data-test-id="payNewPayment" onClick={() => this.payNewPayment(message.data.id)} fullWidth className="order-button" style={{ backgroundColor: isSender ? "#CCBEB1" : "#375280"}}>
      {isPayId && this.state.isPayLoad ? <Loader id="loadPay" color="white" dataTestId="loadPayTest" /> : `${this.renderPayButtonTitle(status)} $${amount} `}
      </Button>
    </OrderCardContainer> 
  }

  renderPayButtonTitle = (status: string) => {
    return status !== "pending" ? this.transChat("Paid") : this.transChat("Pay")
  }

  renderWishlist = (message: WishlistMsg, isSender: boolean) => {
    const { catalogues, wishlist_id } = message
    return <OrderCardContainer style={{ maxWidth: "270px"}}>
      <Box className="imgBox">
        {catalogues && catalogues.length > 0 && catalogues.slice(0, 8).map((cata) => {
          const url = handleImgUrl(cata.images[0])
          return <img key={cata.catalogue_id} className="wishImage" src={url} alt={`img-${cata.catalogue_id}`}/>
        })}
      </Box>
      <Button data-test-id="expWishlist" onClick={() => {this.handleExploreWishlist(wishlist_id)}} fullWidth className="order-button" style={{ backgroundColor: isSender ? "#CCBEB1" : "#375280"}}>
      {this.transChat("Explore Wishlist")}
    </Button>
    </OrderCardContainer>
  }

  renderTextChatContent = (message: Message1, isSender: boolean) => {
    return (
      <Box className="chatMessageContentContainer" 
        style={{
          backgroundColor: isSender ? '#CCBEB1' : '#E2E8F0',
        }}
      >
      <Typography className="chatMessageText">
        {message || this.transChat("UI: coming soon")}
      </Typography>
      </Box>
    );
  }

  renderFileChatContent = (url: string, isSender: boolean) => {
    return <Box style={{ boxShadow: `1px 1px 5px 0px ${isSender ? '#CCBEB1' : '#E2E8F0'}`, marginBottom: 5}}>
        <ChatFilePreview url={url} isSender={isSender}/>
    </Box>
  }

  renderNoChatContent = (isSender: boolean) => {
    return (
      <Box className="chatMessageContentContainer" 
        style={{
          backgroundColor: isSender ? '#CCBEB1' : '#E2E8F0',
          textAlign: isSender ? 'right' : 'left'
        }}
      >
      <Typography className="chatMessageText">
        {this.transChat("No UI available")}
      </Typography>
      </Box>
    );
  }

  renderChatContent = (message: Message1, isSender: boolean) => {
    if (typeof message === 'string') {
      return this.renderTextChatContent(message, isSender);
    }
  
    if (Array.isArray(message) && message.length > 0 && message[0].url) {
      const url = handleImgUrl(message[0].url);
      return this.renderFileChatContent(url, isSender)
    }

    if(typeof message === "object") {
      if ("data" in message && message.data.type === "stylist_payment_request"){
        return this.renderPaymentRequest(message, isSender)
      }

      if ("order_request_id" in message){
        return this.renderOrderRequest(message, isSender)
      }

      if ("wishlist_name" in message){
        return this.renderWishlist(message, isSender)
      }
    }
  
    return this.renderNoChatContent(isSender)
  };
  
  renderInfiniteScroll = () => {
    const { chatMeta, chatMessages} = this.state

    return <Box style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column-reverse' }} id="scrollableChatListDiv">
      <InfiniteScroll
        inverse={true}
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
        dataLength={chatMessages.length}
        next={this.getMoreApiChatList}
        hasMore={chatMeta.next_page ? !!chatMeta.next_page : false}
        loader={
          <div style={{height: "100px"}}>
          <Loader
            id="chat-list-request-loader"
            dataTestId="chat-list-request-loader-test"
          />
          </div>
        }
        scrollableTarget="scrollableChatListDiv"  
      >
        {chatMessages.map((message) => {
            const isSender = message.role === "sender"
            return <Box 
              key={message.id}
              padding={2} className="chatMessageContainer"
              style={{
                padding: "10px 24px",
                justifyContent: isSender ? "end" : 'flex-start',
                alignItems: isSender ? "flex-start": "end",
              }}
              dir="ltr"
            >
              <Box dir={i18n.dir()} style={{ 
                display: "flex",
                flexDirection: "column",
                alignItems: isSender ? "end": "start",
              }}>
                {this.renderChatContent(message.message, isSender)}
                <Box className="messageTimeTextContainer">
                <Typography className="messageTimeText" style={{ width: '100%', textAlign: this.renderTimeTextAlign(isSender)}} dir="ltr">{message.created_at}</Typography>
              </Box>
          </Box>
          </Box>
          })
        }
      </InfiniteScroll>
      </Box>
  }

  renderChatInput = () => {
    return <Box display="flex" style={{ marginTop: "auto"}} padding={2} alignItems="center">
        <TextField
          fullWidth
          minRows={1}
          maxRows={4}
          multiline
          data-test-id="chat_input"
          placeholder={this.transChat("Type your message here")}
          variant="outlined"
          value={this.state.message}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.setState({ message: event.target.value})}
          onKeyDown={(event)=>{
            if (event.key === "Enter" && !event.shiftKey) {
              this.sendMessageChat();
            }
          }}
          InputProps={{
          endAdornment: (
              <InputAdornment position="end" data-test-id="inputAdornment">
                <label htmlFor="file-upload">
                    <IconButton component="span">
                        <img style={{ transform: i18n.dir() === 'rtl' ? 'rotate(270deg)' : 'none'}} src={linkImageIcon} alt={this.transChat("attach file")} />
                    </IconButton>
                </label>
                <input
                    data-test-id="sendMedia"
                    accept=""
                    style={{ display: 'none' }}
                    id="file-upload"
                    type="file"
                    onChange={this.handleFileUpload}
                />
              <IconButton data-test-id="sendMsg" onClick={this.sendMessageChat} >
                  <img style={{ transform: i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'none'}} src={sendMessageIcon} alt="end" />
              </IconButton>
              </InputAdornment>
          )
          }}
        />
      </Box>
  }

  renderChat = () => {
    const { receiver_id, chat_id } = this.props
    const { receiverProfile, fileLoader, receiverFullName, blockMessage } = this.state
    return (receiver_id || chat_id) ? <Box className={`chatMessageContainerMain ${blockMessage ? 'chatMessageContainerMainBlur' : ''}`} flex={1} display="flex" flexDirection="column">
        <Box className="chatProfileContainer">
        <Box className="chatProfileNameContainer">
        <Avatar style={{ marginRight: i18n.dir()==="rtl" ? 0 : 16, marginLeft: i18n.dir()==="ltr" ? 0 : 16 }} src={receiverProfile ?  handleImgUrl(receiverProfile) : chatFashionIMg}/>
        <Typography className="userProfileName" variant="h6">{receiverFullName}</Typography>
        </Box>
        <Box>
            <img data-test-id="handleClickChatMore" onClick={this.handleClickChatMore} src={ClientChatMore} alt="" />
        </Box>
        </Box>
        {!blockMessage && this.renderInfiniteScroll()}
        {this.state.media && <FileInputPreview loading={fileLoader} file={this.state.media} handleCloseAttachment={this.removeMediaPreview}/>}
        {this.renderChatInput()}
      </Box> : this.renderWelcomeContent()
  }

  renderBlockDialog = () => {
    const { blockOpen, receiverFullName } = this.state

   return <StyledBlockDialog
        open={blockOpen}
        onClose={() => this.handleBlockOpen(false)}
        data-test-id="blockChatDialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        dir={i18n.dir()}
      >
        <StyledBlockDialogTitle id="alert-dialog-title">{`${this.transChat("Block")} ${receiverFullName}.`}</StyledBlockDialogTitle>
        <DialogContent>
          <StyledBlockDialogContentText id="alert-dialog-description">
          {`${this.transChat("Are you sure you want to block")} ${receiverFullName.split(' ')[0]}${this.transChat("?")}`}
          </StyledBlockDialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '0', marginTop: '10px'}}>
          <DeleteButton style={{ marginRight: i18n.dir() === "ltr" ? "14px" : "0",
            marginLeft: i18n.dir() === "rtl" ? "14px" : "0"
          }} data-test-id="blockBtn" onClick={() => this.blockChat()} color="primary" autoFocus>
            {this.transChat("Block")}
          </DeleteButton>
          <CancelButton data-test-id="blockBtn" onClick={() => this.handleBlockOpen(false)} color="primary">
            {this.transChat("Cancel")}
          </CancelButton>
        </DialogActions>
      </StyledBlockDialog>
  }

  renderReportPersonAlert = () => {
    const { isReportPerson, receiverFullName } = this.state
    return <StyledBlockDialog
        open={isReportPerson}
        onClose={() => this.handleReportPersonClick(false)}
        data-test-id="reportChatDialog"
        aria-labelledby="alert-report-dialog-title"
        aria-describedby="alert-report-dialog-description"
        dir={i18n.dir()}
      >
        <StyledBlockDialogTitle id="alert-report-dialog-title">{`${this.transChat("Report")} ${receiverFullName}.`}</StyledBlockDialogTitle>
        <DialogContent id="reportContent">
          <StyledBlockDialogContentText id="alert-report-dialog-description1">
          {`${this.transChat("Are you sure you want to report")} ${receiverFullName.split(' ')[0]}${this.transChat("?")}`}
          </StyledBlockDialogContentText>
        </DialogContent>
        <DialogActions id="report-dialog-action" style={{ padding: '0', marginTop: '10px'}}>
          <DeleteButton style={{ marginRight: i18n.dir() === "ltr" ? "8px" : "0",
            marginLeft: i18n.dir() === "rtl" ? "8px" : "0"
          }} data-test-id="reportBtn" onClick={() => this.handleOpenReportField(true)} color="primary" autoFocus>
            {this.transChat("Report")}
          </DeleteButton>
          <CancelButton data-test-id="reportBtn" onClick={() => this.handleReportPersonClick(false)} color="primary">
            {this.transChat("Cancel")}
          </CancelButton>
        </DialogActions>
      </StyledBlockDialog>
  }

  selectIconReportRender = () => (
    <img
      src={selectEndIcon}
      alt="dropdown"
      style={{
        paddingRight: 10,
        position: "absolute",
        zIndex: 1,
        right: 0,
        pointerEvents: "none",
      }}
      data-test-id="select-drop-down-img"
    />
  );

  renderReasonError = (touched?: boolean, error?: string) => {
    return touched && error ? (
      <FormHelperTextContainer style={{ textAlign: i18n.dir() === "rtl" ? "right" : "left" }}>{this.transChat(error)}</FormHelperTextContainer>
    ) : (
      <></>
    );
  };

  renderReportPersonField = () => {
    const { isReportField, report_reasons } = this.state;
    return (
      <StyledBlockDialog
        open={isReportField}
        onClose={() => this.handleOpenReportField(false)}
        data-test-id="reportFieldDialog"
        aria-labelledby="alert-report-dialog-field-title"
        aria-describedby="alert-report-dialog-field-description"
        dir={i18n.dir()}
      >
        <Formik
          initialValues={{
            chat_report_reason_id: "-1",
            comment: "",
          }}
          validationSchema={validationReportSchema}
          onSubmit={(values) => {
            this.handleSubmitReport(values);
          }}
          data-test-id="formikSubmit"
        >
          {({ errors, touched, getFieldProps, handleChange, handleBlur, values, submitForm }) => (
            <PersonFieldContainer>
              <Box className="inputBoxContainer">
                <Typography className="inputNameTxn">
                  * {this.transChat("Select the Reason")}
                </Typography>
                <OrderReqSelectCustom
                  labelId="reason-select-label"
                  id="reason-select"
                  fullWidth
                  data-test-id="reportSelect"
                  name="chat_report_reason_id"
                  label="chat_report_reason_id"
                  disableUnderline={true}
                  IconComponent={this.selectIconReportRender}
                  value={values.chat_report_reason_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  MenuProps={{
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  error={
                    this.getErrorAndHelperTextReport(
                      "chat_report_reason_id",
                      errors as FormError,
                      touched as FormErrorTouched
                    ).isError
                  }
                  dir={i18n.dir()}
                >
                  <MenuItem dir={i18n.dir()} value="-1" disabled>
                    {this.transChat("Select the reason for report")}
                  </MenuItem>
                  {report_reasons.map((reason) => (
                    <MenuItem dir={i18n.dir()} value={reason.id} key={reason.id}>
                      {reason.reason}
                    </MenuItem>
                  ))}
                </OrderReqSelectCustom>
                {this.renderReasonError(touched.chat_report_reason_id, errors.chat_report_reason_id)}
              </Box>
              <Box className="inputBoxContainer">
                <Typography className="inputNameTxn">
                  * {this.transChat("Other Details")}
                </Typography>
                <ClientNewRequestCustomInput
                  {...getFieldProps("comment")}
                  id="standard-adornment-comment"
                  data-test-id="report-comment"
                  variant="standard"
                  style={{maxWidth: "400px"}}
                  fullWidth
                  placeholder={this.transChat("Tell us what happened")}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  FormHelperTextProps={{
                    style: {
                      textAlign: i18n.dir() === "rtl" ? "right" : "left",
                    },
                  }}
                  error={
                    this.getErrorAndHelperTextReport(
                      "comment",
                      errors as FormError,
                      touched as FormErrorTouched
                    ).isError
                  }
                  helperText={
                    this.getErrorAndHelperTextReport(
                      "comment",
                      errors as FormError,
                      touched as FormErrorTouched
                    ).helperText
                  }
                />
              </Box>
              <Box id="reportPersonField" className="inputBoxContainer">
                <Button
                  type="button"
                  className="submitBtn"
                  onClick={submitForm}
                  data-test-id="submitReport"
                >
                  {this.transChat("Report")}
                </Button>
              </Box>
            </PersonFieldContainer>
          )}
        </Formik>
      </StyledBlockDialog>
    );
  };
  

  // Customizable Area End
  render() {
  // Customizable Area Start

  // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
            <ClientChatContainer dir={i18n.dir()}>
              {this.renderChat()}
              {this.state.blockMessage && <ChatErrorMessage data-test-id="chatErrorMsg">{this.state.blockMessage}</ChatErrorMessage>}
              <ClientChatCustomMenu
                id="simple-menu"
                keepMounted
                anchorEl={this.state.anchorElClientsChat}
                open={Boolean(this.state.anchorElClientsChat)}
                data-test-id="chatCustomeMenu"
                onClose={this.handleCloseChatMore}
                dir={i18n.dir()}
              >
                <Box className="menuItemContainer">
                {this.state.role === "stylist" && <><MenuItem className="chatfeaturetext" data-test-id="navigateToNewOrderReq" onClick={() => this.navigateToNewPaymentReq("NewOrderRequestId")}>{this.transChat("Send New Order Request")}</MenuItem>
                <MenuItem data-test-id="navigateToNewPaymentReq" onClick={() => this.navigateToNewPaymentReq("NewPaymentRequestId")} className="chatfeaturetext">{this.transChat("Send Payment Request")}</MenuItem>
                <MenuItem className="chatfeaturetext" data-test-id="shareWishlist" onClick={() => this.handleShareWishlist()}>{this.transChat("Share Wishlist")}</MenuItem></>}
                {!!this.props.isStylistPlan && <MenuItem data-test-id="servicePlan" className="chatfeaturetext" 
                onClick={() => this.handleSubmitPropsPayment()}>{this.transChat("Choose Service Plan")}</MenuItem>}
                <MenuItem data-test-id="reportPerson" className="chatfeaturetext" onClick={()=> this.handleReportPersonClick(true)}>{this.transChat("Report person")}</MenuItem>
                <MenuItem data-test-id="blockChat" className="chatfeatureblocktext" onClick={() => this.handleBlockOpen(true)}>{this.transChat("Block")}</MenuItem>
                </Box>
            </ClientChatCustomMenu>
            {this.renderBlockDialog()}
            {this.renderReportPersonAlert()}
            {this.renderReportPersonField()}
            <ReusableSnackbar
              data-test-id="snackbar"
              open={this.state.isChatAlert}
              message={this.state.errorMessage}
              onClose={this.handleCloseChatSnack}
              severity={this.state.severity}
              dataTestId="alertTestId"
              autoHideDuration={3000}
            />
            </ClientChatContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const ClientChatContainer = styled(Box)({
    position:"relative",
    "& .chatProfileContainer": {
      padding: 16, display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
      borderBottom: "1px solid #E2E8F0"
    },
    "& .chatMessageContainerMain": {
      boxShadow: "0px 4px 8px 0px #00000008",
      height: "732px"
    },
    "& .chatMessageContainerMainBlur": {
      backgroundColor: "rgba(200, 200, 200, 0.3)", 
      pointerEvents: "none",
      userSelect: "none",
      opacity: 0.7
    },
    "& .chatProfileNameContainer": {
      display: "flex",
      alignItems: "center"
    },
    "& .chatMessageContainer": {
      display: 'flex', alignItems: 'start', justifyContent: 'end'
    },
    "& .chatMessageContentContainer": {
      padding: 10, marginBottom: 5,
      borderRadius: '2px',
      width: "fit-content"
    },
    "& .messageTimeTextContainer": {
      display: "flex",
      justifyContent: "end"
    },
    "& .messageTimeText": {
      fontSize: "12px",
      fontWeight: 400,
      color: "#8A91A8",
      fontFamily: "lato"
    },
    "& .chatMessageText": {
      fontSize: "16px",
      fontWeight: 400,
      color: "#4D4742",
      fontFamily: "lato"
    },
    "& .userProfileName":{
      fontSize: "18px",
      fontFamily: "lato",
      color: "#375280",
      fontWeight: 500
    },
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      background: "#F1F5F9",
      borderBottom: "1px solid #CBD5E1"
    },
    "& .MuiInputBase-root":{
      backgroundColor: "#F8FAFC",
      fontSize: "18px",
      color: "#375280",
      fontFamily: "lato",
      fontWeight: 400
    },
    "& .MuiListItem-gutters":{
      borderBottom: "1px solid #CBD5E1"
    },
    "& .MuiListItemText-primary": {
        fontSize: "18px",
        color: "#375280",
        fontFamily: "lato",
        fontWeight: 700,
    },
    "& .MuiOutlinedInput-root": {
      height: "60px",
      '& fieldset': {
        border: 'none'
      },
      border: "1px solid #F1F5F9",
      borderRadius: "2px",
    },
    "& .MuiListItemText-secondary":{
        fontSize: "16px",
        fontFamily: "lato",
        color: "#94A3B8",
        fontWeight: 400,
    }
  });

const ClientChatCustomMenu = styled(Menu)({
  height: '395px',
  width: '260px',
  marginTop: "40px",
  left: '-30px !important',
  top: "55px !important",
  "& .menuItemContainer": {
    padding: '5px 15px'
  },
  "& .chatfeaturetext": {
    padding: '10px',
    color: "#375280",
    fontFamily: 'Lato',
    borderBottom: '1px solid #E2E8F0',
    fontSize: '16px',
    fontWeight: 500,
  },
  "& .chatfeatureblocktext": {
    padding: '10px',
    color: "#F87171",
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: 'Lato',
  }
});

const WelcomeSellerContainer = styled(Box)({
   display: "flex",
   alignItems: "center",
   fontSize: 18,
   fontWeight: 600,
   justifyContent: "center",
   marginTop: "100px",
   fontFamily: "Lato, sans-serif",
   "& img":{
    height: "100px",
    width: "100px",
    borderRadius: "50%"
   },
   "& .title": {
    fontWeight: 600,
    fontFamily: 'Lato, sans-serif',
    color: "#375280",
    margin: '0',
    lineHeight: "26px"
   }
})

const OrderCardContainer = styled(Card)({
  border: "1px solid #E2E8F0",
  boxShadow: "0px 2px 8px 0px #00000014",
  maxWidth: "350px",
  minWidth: "300px",
  padding: '24px 16px',
  flexDirection: "column",
  marginBottom: 5,
  display: 'flex',
  alignItems: 'center',
  "& .imgBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    "& .wishImage" :{
      height: "64px",
      width: "64px",
      borderRadius: "4px"
    }
  },
  '& .avatar-box': {
    marginBottom: '12px',
    alignItems: 'center',
    width: "100%",
    gap: "12px",
    flexWrap:"nowrap",
    display: 'flex',
  },
  "& .action-box": {
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    width: "100%",
    "& .menu": {
      display: "flex",
      flexDirection: 'row',
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      gap: "30px",
      "& .title": {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        color: "#375280",
        lineHeight: "24px",
        textAlign: "left",
      },
      "& .value": {
        fontSize: "16px",
        fontFamily: "Lato",
        color: "#375280",
        lineHeight: "24px",
        fontWeight: 900,
        textAlign: "right",
      }
    }
  },
  '& .avatar': {
    width: 50,
    borderRadius: "2px",
    marginRight: '12px',
    height: 50,
  },
  "& .payment-text": {
    fontSize: "16px",
    color: "#375280",
    fontFamily: "Lato",
    lineHeight: "24px",
    letterSpacing: "0.01em",
    fontWeight: 400,
  },
  "& .terms":{
    fontSize: "14px",
    fontFamily: "Lato",
    lineHeight: "21px",
    fontWeight: 500,
  },
  '& .name-text': {
    fontSize: "14px",
    color: "#375280",
    fontFamily: "Lato",
    lineHeight: "22px",
    letterSpacing: "0.01em",
    textAlign: "left",
    fontWeight: 700,
  },
  '& .order-button': {
    fontSize: "20px",
    marginTop: "16px",
    color: 'white',
    borderRadius: '2px 0px 0px 0px',
    fontWeight: 800,
    fontFamily: 'lato',
    "& span": {
      borderRadius: '2px 0px 0px 0px',
    },
    textTransform: "none",
    height: '56px',   
    padding: '10px 16px', 
  },
});

const StyledBlockDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    borderRadius: '4px',
    padding: '43px',
    maxWidth: '400px',
    width: "100%"
  },
});

const StyledBlockDialogTitle = styled(DialogTitle)({
  "& h2":{
    fontSize: '30px',
    fontWeight: 500,
    fontFamily: "lato",
    lineHeight: "40px",
    color: '#375280',
  },
  padding: 0,
  textAlign: 'center',
});

const StyledBlockDialogContentText = styled(DialogContentText)({
  "& p": {
  },
  fontSize: '18px',
  fontWeight: 400,
  fontFamily: "lato",
  lineHeight: "26px",
  color: '#375280',
  textAlign: 'center',
});

const DeleteButton = styled(Button)({
  backgroundColor: '#F87171',
  width: "252px",
  height: "54px",
  lineHeight: "24px",
  borderRadius: '2px',
  fontSize: '18px',
  textTransform: 'none',
  fontFamily: "lato",
  color: "#fff",
  fontWeight: 800,
  padding: '8px 20px',
  '&:hover': {
    backgroundColor: '#ff4940',
  },
});

const CancelButton = styled(Button)({
  width: "252px",
  height: "54px",
  fontSize: '18px',
  textTransform: 'none',
  fontWeight: 500,
  lineHeight: "24px",
  borderRadius: '2px',
  border: "1px solid #CCBEB1",
  fontFamily: "lato",
  color: "#375280",
  padding: '8px 20px',
});

const ChatErrorMessage = styled(Box)({
  position: "absolute",
  bottom: "40px",
  left: "50%",
  background: "white",
  boxShadow: "1px 1px 5px 0px grey",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "600px",
  borderRadius: "3px",
  margin: "0 10px -10px",
  padding: "8px 10px",
  textAlign: "center",
  fontSize: '18px',
  fontWeight: 500,
  fontFamily: "lato",
  lineHeight: "24px",
  color: "#F87171"
})

const PersonFieldContainer = styled(Box)({
  marginTop: "-24px",
  marginBottom: "-15px", 
  maxWidth: "400px",
  "& .inputBoxContainer": {
    padding: "24px 0 0",
  },
  "& .submitBtn": {
    width: "100%",
    height: "53px",
    fontWeight: 800,
    fontSize: "20px",
    textTransform: "none",
    background: "#CCBEB1",
    color: "white",
    borderRadius: "4px",
    fontFamily: "Lato, sans-serif"
  },
  "& .inputNameTxn": {
    color: "#375280",
    fontFamily: "Lato, sans-serif",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: "10px",
  },
});

const CustomCheckbox = styled(Checkbox)({
  height: 21,
  width: 21,
  '&.MuiCheckbox-root': {
    color: '#94A3B8',
    padding: 0,
    '&.Mui-checked': {
      color: '#375280',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiSvgIcon-root': {
    borderRadius: 3,
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg, hsla(0,0%,100%,.8), hsla(0,0%,100%,0))',
    '&.Mui-focusVisible': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    '&:disabled': {
      background: 'rgba(206,217,224,.5)',
      boxShadow: 'none',
    },
    '&:hover': {
      backgroundColor: '#ebf1f5',
    },
  },
  '& .Mui-checked': {
    backgroundColor: '#375280',
  },
})

// Customizable Area End
