export const enAccount = {
    "Account Activation": "Account Activation",
    "Create Profile": "Create Profile",
    "Address": "Address",
    "Documents": "Documents",
    "Email Address": "Email Address",
    "IBAN Number": "IBAN Number",
    "Bank Account Holder Name": "Bank Account Holder Name",
    "Account Number": "Account Number",
    "Back": "Back",
    "Next": "Next",
    "Enter Full Name": "Enter Full Name",
    "Enter Social Media Account":"Enter Social Media Account",
    "Full Name":"Full Name",
    "Phone Number":"Phone Number",
    "Enter Contact Number":"Enter Contact Number",
    "Enter Bank Account Name":"Enter Bank Account Name",
    "Enter IBAN number":"Enter IBAN number",
    "Block":"Block",
    "Area":"Area",
    "City":"City",
    "Zip Code":"Zip Code",
    "Address Name":"Address Name",
    "Add Phone Number":"Add Phone Number",
    "Enter your email":"Enter your email",
    "Enter Holder Account Name":"Enter Holder Account Name",
    "Enter Account Number":"Enter Account Number",
    "Owner’s Address":"Owner’s Address",
    "Enter Full Address":"Enter Full Address",
    "Enter Zip Code":"Enter Zip Code",
    "Company’s Address":"Company’s Address",
    "Full Address":"Full Address",
    "Civil ID/Passport":"Civil ID/Passport",
    "Upload png, jpg, jpeg or pdf":"Upload png, jpg, jpeg or pdf",
    "Commercial License":"Commercial License",
    "Upload png, jpg, jpeg":"Upload png, jpg, jpeg",
    "Authorized Signatures":"Authorized Signatures",
    "MOA or AOA":"MOA or AOA",
    "Business Bank Account":"Business Bank Account",
    "Address Created Successfully":"Address Created Successfully",
    "Address Updated successfully":"Address Updated successfully",
    "Address deleted successfully":"Address deleted successfully",
    "Please select a different address as a default":"Please select a different address as a default",
    "Please Enter valid data": "Please Enter valid data",
    "The above info must be provided with a Stamped official Bank Certificate.It cannot be a personal bank account": "The above info must be provided with a Stamped official Bank Certificate.It cannot be a personal bank account",
    "Passport is accepted for only \"Kuwaitis\". Required for all shareholders with 25% shares or more plus all authorized signatories as well": "Passport is accepted for only \"Kuwaitis\". Required for all shareholders with 25% shares or more plus all authorized signatories as well",
    "Please select an address.": "Please select an address.",
    "Create": "Create",
    "*please upload passport":"*please upload passport",
    "*email is required":"*email is required",
    "*Please enter a valid Account Number":"*Please enter a valid Account Number",
    "*Please enter a valid Iban" :"*Please enter a valid Iban" ,
    "*Account Number must be numeric":"*Account Number must be numeric",
    "*Please enter a valid email":"*Please enter a valid email",
    "*Please enter a valid full name":"*Please enter a valid full name",
    "*Only characters are allowed":"*Only characters are allowed",
    "*Please enter a valid Account holder name":"*Please enter a valid Account holder name",
    "*Please enter a valid Contact number":"*Please enter a valid Contact number",
    "*Please enter a valid Company Address":"*Please enter a valid Company Address",
    "*Please enter a valid Contact Number":"*Please enter a valid Contact Number",
    "*Please enter a valid Owner Address":"*Please enter a valid Owner Address",
    "*Company Contact Number must be numeric":"*Company Contact Number must be numeric",
    "*Owner Contact Number must be numeric":"*Owner Contact Number must be numeric",
    "*Zipcode must be numeric":"*Zipcode must be numeric",
    "*please upload moa":"*please upload moa",
    "*please upload license":"*please upload license",
    "*please upload authorized signature":"*please upload authorized signature",
    "*please upload business bank account":"*please upload business bank account",
    "Copy of the Memorandum of Association or Articles of association of shareholding entities with 25% shares or more is required":"Copy of the Memorandum of Association or Articles of association of shareholding entities with 25% shares or more is required"
}

export const arAccount = {
    "Account Activation": "تفعيل الحساب",
    "Create Profile": "إنشاء حساب",
    "Address": "العنوان",
    "Documents": "المستندات",
    "Email Address": "عنوان البريد الإلكتروني",
    "IBAN Number": "رقم الآيبان",
    "Bank Account Holder Name": "اسم صاحب الحساب البنكي",
    "Account Number": "رقم الحساب",
    "Next": "التالي",
    "Back": "خلف",
    "Enter Full Name": "أدخل الاسم الكامل",
    "Enter Social Media Account":"أدخل حساب وسائل التواصل الاجتماعي",
    "Full Name":"الاسم الكامل",
    "Phone Number":"رقم الهاتف",
    "Enter Contact Number":"أدخل رقم الاتصال",
    "Enter Bank Account Name":"أدخل اسم الحساب البنكي",
    "Enter IBAN number":"أدخل رقم الآيبان",
    "Block":"البلوك",
    "Area":"المنطقة",
    "City":"المدينة",
    "Zip Code":"الرمز البريدي",
    "Address Name":"نوع العنوان",
    "Add Phone Number":"أضف رقم الهاتف",
    "Enter your email":"أدخل بريدك الإلكتروني",
    "Enter Holder Account Name":"أدخل اسم حساب المالك",
    "Enter Account Number":"أدخل رقم الحساب",
    "Owner’s Address":"عنوان المالك",
    "Enter Full Address":"أدخل العنوان الكامل",
    "Enter Zip Code":"أدخل الرمز البريدي",
    "Company’s Address":"عنوان الشركة",
    "Full Address":"العنوان الكامل",
    "Civil ID/Passport":"البطاقة المدنية / جواز السفر",
    "Upload png, jpg, jpeg or pdf":"",
    "Commercial License":"رخصة تجارية",
    "Upload png, jpg, jpeg":"تحميل png، jpg، jpeg",
    "Authorized Signatures":"التوقيعات المعتمدة",
    "MOA or AOA":"وزارة الزراعة أو AOA",
    "Business Bank Account":"حساب بنك الأعمال",
    "Address Created Successfully":"تم إنشاء العنوان بنجاح",
    "Address Updated successfully":"تم تحديث العنوان بنجاح",
    "*Please enter a valid Account Number":"*الرجاء إدخال رقم حساب صالح",
    "Address deleted successfully":"تم حذف العنوان بنجاح",
    "Please select a different address as a default":"الرجاء تحديد عنوان مختلف كعنوان افتراضي",
    "Please Enter valid data": "الرجاء إدخال بيانات صحيحة",
    "The above info must be provided with a Stamped official Bank Certificate.It cannot be a personal bank account": "يجب تقديم المعلومات المذكورة أعلاه مع شهادة بنكية رسمية مختومة. ولا يمكن أن يكون حسابًا مصرفيًا شخصيًا",
    "Passport is accepted for only \"Kuwaitis\". Required for all shareholders with 25% shares or more plus all authorized signatories as well": "يتم قبول جواز السفر \"للكويتيين\" فقط. مطلوب لجميع المساهمين الذين يملكون 25% من الأسهم أو أكثر بالإضافة إلى جميع المفوضين بالتوقيع أيضًا",
    "Please select an address.": "الرجاء تحديد عنوان.",
    "Create": "يخلق",
    "*please upload passport":"*يرجى تحميل جواز السفر",
    "*email is required":"*البريد الإلكتروني مطلوب",
    "*Please enter a valid Iban" :"*الرجاء إدخال رقم Iban صالح",
    "*Account Number must be numeric":"*يجب أن يكون رقم الحساب رقميًا",
    "*Please enter a valid email":"*يرجى إدخال بريد إلكتروني صالح",
    "*Please enter a valid full name":"*الرجاء إدخال اسم كامل صالح",
    "*Only characters are allowed":"*يسمح بالأحرف فقط",
    "*Please enter a valid Account holder name":"*الرجاء إدخال اسم صالح لصاحب الحساب",
    "*Please enter a valid Contact number":"*الرجاء إدخال رقم اتصال صالح",
    "*Please enter a valid Company Address":"*الرجاء إدخال عنوان صالح للشركة",
    "*Please enter a valid Contact Number":"*الرجاء إدخال رقم اتصال صالح",
    "*Please enter a valid Owner Address":"*الرجاء إدخال عنوان مالك صالح",
    "*Owner Contact Number must be numeric":"*يجب أن يكون رقم الاتصال بالمالك رقميًا",
    "*Company Contact Number must be numeric":"*يجب أن يكون رقم الاتصال بالشركة رقميًا",
    "*Zipcode must be numeric":"*يجب أن يكون الرمز البريدي رقميًا",
    "*please upload moa":"*يرجى تحميل moa",
    "*please upload license" : "*يرجى تحميل الترخيص",
    "*please upload authorized signature":"*يرجى تحميل التوقيع المعتمد",
    "*please upload business bank account":"*يرجى تحميل الحساب البنكي التجاري",
    "Copy of the Memorandum of Association or Articles of association of shareholding entities with 25% shares or more is required" :"مطلوب نسخة من عقد التأسيس أو النظام الأساسي للشركات المساهمة التي تملك 25% أسهم أو أكثر"
}