import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  roleData:string,
  profileName: string,
  value: number,
  orderStatus: string | undefined;
  searchQuery:string;
  search: string;
  selectedStoreId: number | string;
  totalOrder: {
    total_orders: number,
    total_shipped_orders: number,
    total_delivered_orders: number,
    total_rejected_orders: number,
    total_process_orders: number,
    total_in_process_orders: number,
    total_new_orders: number,
    total_return_refund_orders: number
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellersAllOrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMy_OrdersCallId: string = "";
  getCancelOrderCallId: string = "";
  private debounceTimeout: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      roleData:"",
      search: "",
      searchQuery:"",
      profileName: "",
      value: 0,
      orderStatus: "all_orders",
      selectedStoreId: "-1",
      totalOrder: {
        total_orders: 0,
        total_shipped_orders: 0,
        total_delivered_orders: 0,
        total_rejected_orders: 0,
        total_process_orders: 0,
        total_in_process_orders: 0,
        total_new_orders: 0,
        total_return_refund_orders: 0
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const navigationParams = message.getData(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    if(typeof navigationParams?.tabIndex === configJSON.numberMsg){
      this.setState({value:navigationParams.tabIndex})
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCancelOrderCallId !== null &&
      this.getCancelOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ totalOrder: responseJsonCategory })
    }
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    const selectedStore = await getStorageData("selectedSellerStore", true);
    this.setState({
      selectedStoreId: selectedStore ? selectedStore.id.toString() : "-1"
    }, () => {
      this.allOrderData()
    })
    const userData = await getStorageData("userRole", true)
    this.setState({ roleData: userData.userType })
  }
  handleTabChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    this.setState({ value: newValue });
  };
  allOrderData = async () => {
    const token = await getStorageData("auth-token");
    const countEndPoint = this.state.selectedStoreId.toString() === "-1" ? configJSON.getAllOrder : `${configJSON.getAllOrder}?store_ids[]=${this.state.selectedStoreId}`
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCancelOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      countEndPoint
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  handleInputChange = (event: { target: { value: string }; }) => {
    const query = event.target.value.toLowerCase();
    this.setState((prevState) => ({
      ...prevState,
      search: query,
    }));
    this.debounceTime(() => this.propsHandleSearch(query), 800)();
  };

  propsHandleSearch = (value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      searchQuery: value,
    }));
  };

  debounceTime(func: (...args: any[]) => void, delay: number) {
    return (...args: any[]) => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  handleStoreChange = (store_id: string | number) => {
    this.setState({
      selectedStoreId: store_id.toString()
    }, () => {
      this.allOrderData();
    })
  }

  transSellerAllOrderDetails = (transKey: string) => {
    return i18n.t( transKey, {ns: "orderDetail"})
  }
  // Customizable Area End
}
