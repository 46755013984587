import React, { useState } from "react";
import {
  Select,
  MenuItem,
  Typography,
  SelectProps,
  styled,
} from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";
import { selectEndIcon } from "../../blocks/customform/src/assets";
import i18n from "./i18next/i18n";

interface Country {
  numeric_code: string;
  country_full_name: string;
  country_code: string;
  country_flag: string;
}

interface CountrySelectProps {
  id: string;
  name: string;
  value: string;
  placeholder?: string;
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  countryCode: Country[];
  renderValue?: ((value: SelectProps["value"]) => React.ReactNode) | undefined;
  menuHeight?: string;
  menuWidth?: string;
  menuAnchorOrigin?: {
    vertical: "top" | "bottom";
    horizontal: "left" | "right";
  };
  menuTransformOrigin?: {
    vertical: "top" | "bottom";
    horizontal: "left" | "right";
  };
  [key: string]: any;
  isBorder?: boolean
}

const CountrySelect: React.FC<CountrySelectProps> = ({
  id,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  countryCode,
  renderValue,
  menuHeight = "320px",
  menuWidth = "180px",
  menuAnchorOrigin = { vertical: "bottom", horizontal: "left" },
  menuTransformOrigin = { vertical: "top", horizontal: "left" },
  isBorder,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleIconCSS = () => {
    return i18n.dir()==='ltr' ? {right: isOpen ? 10 : 0, paddingRight: 10,} : {left: isOpen ? 10 : 0, paddingLeft: 10}
  }
  const selectIconRender = () => (
    <img
      src={selectEndIcon}
      alt="dropdown"
      style={{
        position: "absolute",
        zIndex: 1,
        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s ease",
        pointerEvents: "none",
        ...handleIconCSS()
      }}
      data-test-id="select-drop-down-img"
    />
  );

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  

  
  return (
    <CustomSelect
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      style={{ borderRadius: isBorder ? 8: 2, backgroundColor: "#F0F0F0" }}
      IconComponent={selectIconRender}
      onOpen={handleOpen}
      onClose={handleClose}
      renderValue={(selected) => {
        const selectedValue = selected as string;
        const selectedCountry = countryCode.find(
          (country) => country.numeric_code === selectedValue
        );
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <ReactCountryFlag
              countryCode={selectedCountry?.country_code || ""}
              svg
            />
            <span style={{fontSize: 14}}>
              ({selectedCountry?.numeric_code})
            </span>
          </div>
        );
      }}
      MenuProps={{
        style: {
          minWidth: menuWidth,
          height: menuHeight,
        },
        anchorOrigin: menuAnchorOrigin,
        transformOrigin: menuTransformOrigin,
        getContentAnchorEl: null,
      }}
      {...rest}
    >
      {countryCode.map((country) => (
        <MenuItem
          key={country.numeric_code}
          value={country.numeric_code}
          style={{
            fontFamily: "Lato, sans-serif",
            fontSize: 12,
            color: "#375280",
            padding: "10px",
            display: "flex",
          }}
        >
          <Typography
            style={{
              gap: 3,
              color: "#375280",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ReactCountryFlag countryCode={country.country_code} svg />{" "}
            {country.country_full_name}{ " " }
            ({country.numeric_code})
          </Typography>
        </MenuItem>
      ))}
    </CustomSelect>
  );
};


export default CountrySelect;

const CustomSelect = styled(Select)({
  width: "100%",
  height: "100%",
  borderRadius: "8px",
  "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input":
    {
      color: "#375280",
      height: "100%",
      paddingLeft: 10,
      paddingTop: 0,
      paddingBottom: 0,
      display: "flex",
      alignItems: "center",
      "&:hover": {
        border: "none !important",
      },
      "&:active": {
        backgroundColor: "#F0F0F0",
      },
      "&:focus": {
        backgroundColor: "#F0F0F0",
      },
    },
  "&:before": {
    borderBottom: "none !important",
  },
  "&:after": {
    borderBottom: "none !important",
  },
  "&:active": {
    backgroundColor: "#F0F0F0",
  },
  "&:focus": {
    backgroundColor: "#F0F0F0",
  },
});

