import { BlockComponent } from "../../framework/src/BlockComponent";
import { Box, Grid, Typography, styled, Snackbar } from "@material-ui/core";
import React from "react";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { facewhite, instawhite, tiwiterwhite, linkdinwhite, fashwhitelogo } from "../../blocks/email-account-registration/src/assets";
import {getStorageData, setStorageData } from "../../framework/src/Utilities";
import i18n from "./i18next/i18n";
import Alert from "@material-ui/lab/Alert";
import AuthenticateUser from "./AuthenticateUser";
import ReusableSnackbar from "./AlertContainer";
interface S {
    auth: string;
    modalOpen: boolean;
    modalName: string;
}

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class Footer extends BlockComponent<Props, S, SS>{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state={
            auth:"",
            modalOpen: false,
            modalName: ""
        }
        // Customizable Area End
    }

    // Customizable Area Start
    headerRedirect = (pageLink: string,tabIndex?:number) => {
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message( getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage),{tabIndex:tabIndex} );
        toNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(toNavigate);
    };
    footerNavigation = async (pageLink: string, name?: string) => {
        await setStorageData("navigationGender", name)
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);      
        this.send(toNavigate);
    };

    handleHeaderButtonClick = (component: string, name: string) => {
        if(this.state.auth === null) {
            this.setState({ modalOpen: true, modalName: name})
          } else {
            this.footerNavigation(component)
        }
    }

    renderDialog = (): JSX.Element | null => {
        const { modalName, auth, modalOpen } = this.state;
        const parsedUrl = this.props.navigation.history.location.pathname;
        const isLoginSignup = ["/login", "/signup"].includes(parsedUrl.toLowerCase());
    
        if (isLoginSignup) {
            return (
                <Snackbar
                  open={modalOpen}
                  autoHideDuration={3000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  data-test-id="alertTestId"
                  dir={i18n.dir()}
                  onClose={this.closeModal}
                >
                  <Alert severity={"error"}>{this.transFooter("Sign in first !!")}</Alert>
                </Snackbar>
            );
        }

        let title = "Sign in first !!";
        let desc = "";
    
        switch (modalName) {
            case "Stylists":
                title = "Log in to view stylists.";
                desc = "View list of stylists";
                break;
            case "Customer Service":
                title = "Log in to view customer service.";
                desc = "Access customer service options.";
                break;
            case "Store":
                title = "Log in to access the store.";
                desc = "Browse available store options.";
                break;
            default:
                title = "Access restricted";
                desc = "Please log in to access this page.";
        }
    
        return (
            <AuthenticateUser
                auth={auth}
                title={this.transFooter(title)}
                description={this.transFooter(desc)}
                btnText={this.transFooter("Log In or Sign up")}
                navigation={this.props.navigation}
                onClose={this.closeModal}
            />
        );
    };    

    closeModal = () => {
        this.setState({modalOpen: false, modalName: ""});
    };

    async componentDidMount() {
        const authtokenData = await getStorageData('auth-token');
        this.setState({auth:authtokenData})
        const currentLang = await getStorageData('lang');
        if (currentLang) {
            i18n.changeLanguage(currentLang);
        }
    }

    transFooter = (fooKey: string) => {
        return i18n.t(fooKey, { ns: "footer"})
    }
    // Customizable Area End 
    render() {
        // Customizable Area Start
        return (
            <StyledWrapper>
                <Grid className="FooterMainGrid" container dir={i18n.dir()}>
                    <Grid
                        item
                        lg={12}
                        xl={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="footerContent"
                    >
                        <Box className="footerContentBox">
                            <Grid container>
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={6}
                                    className="contactUS"
                                >
                                    <img src={fashwhitelogo} alt="" className="logo" onClick={() => {
                                        this.headerRedirect("LandingPage")
                                    }} />
                                    <Box className="socialMedia" style={{display: "none"}}>
                                        <img src={facewhite} alt="facewhite" className="appLogo" />
                                        <img src={tiwiterwhite} alt="tiwiterwhite" className="appLogo" />
                                        <img src={linkdinwhite} alt="linkdinwhite" className="appLogo" />
                                        <img src={instawhite} alt="instawhite" className="appLogo" />
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={6}
                                    className="support"
                                >
                                    <Box className="shop">
                                        <Typography
                                            className="supportHeading"
                                            style={{ fontWeight: 700 }}
                                        >
                                            {this.transFooter("SHOP")}
                                        </Typography>
                                        <Typography className="supportText" onClick={() => { this.footerNavigation("CategoriessubcategoriesWebPage", "Women") }}>{this.transFooter("Women")}</Typography>
                                        <Typography className="supportText" onClick={() => {
                                            this.footerNavigation("CategoriessubcategoriesWebPage", "Men")
                                        }}>{this.transFooter("Men")}</Typography>
                                        <Typography className="supportText" onClick={() => { this.footerNavigation("CategoriessubcategoriesWebPage", "Kids") }}>
                                            {this.transFooter("Kids")}
                                        </Typography>
                                        <Typography className="supportText" onClick={()=>this.footerNavigation("BuyerStore")}>{this.transFooter("Stores")}</Typography>
                                        <Typography className="supportText" onClick={()=>this.handleHeaderButtonClick("HiredStylist", "Stylists")}>
                                            {this.transFooter("Stylist's")}
                                        </Typography>
                                    </Box>
                                    <Box className="help">
                                        <Typography
                                            className="supportHeading"
                                            style={{ fontWeight: 700 }}
                                        >
                                            {this.transFooter("HELP")}
                                        </Typography>
                                        <Typography className="supportText"
                                            onClick={() => this.handleHeaderButtonClick("Contactus", "Customer Service")}>
                                            {this.transFooter("Customer Service")}
                                        </Typography>
                                        <Typography className="supportText" onClick={() => this.headerRedirect("Faqs")}>{this.transFooter("FAQ")}</Typography>
                                        <Typography className="supportText"
                                             onClick={() => {this.headerRedirect("TermsConditions", 0)}}>
                                            {this.transFooter("Terms Of Use")}
                                        </Typography>
                                        <Typography className="supportText"
                                            onClick={() => {this.headerRedirect("TermsConditions", 1)}}>
                                            {this.transFooter("Privacy Policy")}
                                        </Typography>
                                        <Typography className="supportText"
                                         onClick={() => {this.headerRedirect("TermsConditions", 2)}}>
                                            {this.transFooter("Shipping Policy")}
                                        </Typography>
                                        <Typography className="supportText"
                                         onClick={() => {this.headerRedirect("TermsConditions", 3)}}>
                                            {this.transFooter("Return Policy")}
                                        </Typography>
                                        <Typography className="supportText"
                                        onClick={() => this.headerRedirect("AboutFash")}>{this.transFooter("About Fash")}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        xl={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="copyrightSection"
                    >
                        <Box className="copyrightBox">
                            <Typography>{this.transFooter("Fash")} &copy; 2024</Typography>
                        </Box>
                    </Grid>
                </Grid>
                {this.state.modalOpen && this.renderDialog()}

            </StyledWrapper>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const StyledWrapper = styled(Box)({
    width: "100%",
    scrollbarWidth: "none",
    "& .FooterMainGrid": {
        backgroundColor: "#334155",
        padding: "10px",
        bottom: 0
    },
    "& .footerContent": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "30px",
        paddingBottom: "20px",
    },
    "& .footerContentBox": {
        padding: `${i18n.dir() === "rtl" ? "10px 10px 10px 0": "10px 0 10px 10px"}`,
        width: "90%",
    },
    "& .copyrightSection": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .copyrightBox": {
        padding: `${i18n.dir() === "rtl" ? "30px 10px 30px 0px": "30px 0px 30px 10px"}` ,
        borderTop: "2px solid #B2A69B",
        color: "var(--Grey-50---White, #FFF)",
        width: "90%",
        fontSize: "16px",
        fontFamily: "Poppins , sans-serif;",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "160%"
    },
    "& .logo": {
        margin: `${i18n.dir() === "rtl" ? "10px 10px 10px 0": "10px 0 10px 10px"}`,
        cursor: "pointer",
        width: "118.768px",
        height: "28.073px",
    },
    "& .appLogo": {
        margin: `${i18n.dir() === "ltr" ? "10px 0 10px 20px !important": "10px 20px 10px 0px !important"}`,
        cursor: "pointer",
    },
    "& .support": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
    },
    "& .shop": {
        padding: "10px !important",
        alignItems: "flex-start",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "130px",

    },
    "& .help": {
        padding: "10px !important",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "130px",

    },
    "& .supportHeading": {
        color: "#ffff !important",
        fontSize: "24px !important",
        fontStyle: "normal !important",
        marginBottom: "5px !important",
        lineHeight: "100% !important",
    },
    "& .supportText": {
        marginTop: "3px !important",
        cursor: "pointer",
        color: "#d2d1d1 !important",
        fontFamily: "Lato , sans-serif;",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 400,
        lineHeight: "160% !important"
    },
});

// Customizable Area End
