import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import i18n from "../../../components/src/i18next/i18n";
import {
  Grid,
  Box,
  Typography,
  Button,
  styled,
  FormHelperText,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import EditSellerStoreController, {
  configJSON,
  Props,
  step2Schema,
} from "./EditSellerStoreController";
import {
  backBtnIcon,
  editFillUploadIcon,
  endLocationIcon,
  removeIcon,
  selectEndIcon,
  uploadIcon,
} from "./assets";
import { DialogEditContainer } from "./SellerStoreProfile.web";
import {
  CountrySuccess,
  FormError,
  FormErrorTouched,
  paymentModes,
} from "./CreateSellerStoreController";
import ReactCountryFlag from "react-country-flag";
import { Formik } from "formik";
import ReusableSnackbar from "../../../components/src/AlertContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class EditSellerStore extends EditSellerStoreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  selectIconRender = () => (
    <img
      src={selectEndIcon}
      alt="dropdown"
      style={{
        paddingRight: 10,
        position: "absolute",
        right: 0,
        zIndex: 1,
        pointerEvents: "none",
      }}
      data-test-id="select-drop-down-img"
    />
  );

  handleHelperTextStyle = (): React.CSSProperties => {
    return {
        textAlign: i18n.dir() === "rtl" ? "right" : "left" as const,
    }
  }

  renderUploadImgDialog = () => (
    <DialogEditContainer
      data-test-id="upload-store-img-dialog"
      onClose={() => this.handleEditClose()}
      open={this.state.isImageDialog}
      dir={i18n.dir()}
    >
      <Box className="content-container">
        <Typography className="text-container">{this.transSellerStore("Edit Store Profile")}</Typography>
        <form onSubmit={this.handleSubmit} data-test-id="formSubmit">
          {!this.state.storeProfile.previewUrl ? (
            <Button
              variant="contained"
              className="upload-btn"
              component="label"
            >
              <img alt="upload" src={uploadIcon} />
              <Typography className="upload-title">
                {this.transSellerStore("Upload Store Profile Image")}
              </Typography>
              <input
                type="file"
                onChange={this.handleFileChange}
                hidden
                accept=".png, .jpg, .jpeg"
                data-test-id="upload-image-test"
              />
            </Button>
          ) : (
            <Box
              className="image-preview-box"
              style={{ position: "relative", marginTop: "16px" }}
            >
              <img
                src={this.state.storeProfile.previewUrl}
                alt="Uploaded Profile"
                className="image-preview"
                style={{
                  height: 240,
                  width: "100%",
                  objectFit: "cover",
                }}
              />
              <Button
                className="edit-btn"
                component="label"
                variant="contained"
                style={{
                  position: "absolute",
                  right: "0px",
                  top: "-10px",
                  boxShadow: "none",
                  background: "transparent",
                }}
              >
                <img src={editFillUploadIcon} alt="edit" />
                <input
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  onChange={this.handleFileChange}
                  data-test-id="upload-image-test"
                  hidden
                />
              </Button>
            </Box>
          )}
          <FormHelperText
            style={{ color: "#f44336" }}
            data-test-id="upload-img-error"
          >
            {this.state.errorsMessage.errorStoreUpload
              ? this.transSellerStore(this.state.errorsMessage.errorStoreUpload)
              : ""}
          </FormHelperText>
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className={"upload-btn"}
          >
            {this.transSellerStore("Upload")}
          </Button>
        </form>
      </Box>
    </DialogEditContainer>
  );

  renderBackBtn = () => {
    return <img
      src={backBtnIcon}
      data-test-id="back-btn-test-id"
      alt="backIcon"
      style={{
        transform: i18n.dir() === "rtl" ? "rotate(180deg)" : "rotate(0)"
      }}
      onClick={() => this.handleRedirectPage("SellerStoreProfile")}
    />
  }

  renderEditIcon = () => {
    return <img
    className="store-profile-edit-img"
    src={editFillUploadIcon}
    alt="edit"
    onClick={() => this.handleEditClose()}
    style={{
      right: i18n.dir() === "ltr" ? 0 : 'auto',
      left: i18n.dir() === "rtl" ? 0 : 'auto',
    }}
    data-test-id="upload-img-icon"
  />
  }

  renderFormik = () => (
    <Formik
      initialValues={this.state.intialValue}
      validationSchema={step2Schema}
      enableReinitialize
      onSubmit={this.handleEditSubmit}
      data-test-id="stepper-2-form-data"
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        getFieldProps,
        values,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box className="header-container">
              <Box className="title-container">
                {this.renderBackBtn()}
                <Typography className="title">{this.transSellerStore("Edit Details")}</Typography>
              </Box>
              <Button
                variant="contained"
                className={"next-btn"}
                disableElevation
                data-test-id="store-edit-test"
                type="submit"
              >
                {this.transSellerStore("Save Details")}
              </Button>
            </Box>
            <Box className="edit-store-profile-container">
              <Grid container spacing={3}>
                <Grid item xl={12} xs={12} sm={12} md={12} lg={12}>
                  <Box className="store-profile-image">
                    <img
                      className="store-profile-img"
                      src={this.state?.storeProfile?.previewUrl}
                      alt="img"
                    />
                    {this.renderEditIcon()}
                  </Box>
                </Grid>
                <Grid item xl={12} xs={12} sm={12} md={12} lg={12}>
                  <Typography className={"inputHeading"}>
                    {this.transSellerStore(configJSON.storeName)}
                  </Typography>
                  <TextField
                    data-test-id="store-name"
                    {...getFieldProps("storeName")}
                    fullWidth
                    variant="outlined"
                    placeholder={this.transSellerStore(configJSON.storeNamePlace)}
                    className="inputFeild2"
                    inputProps={{ maxLength: 120 }}
                    FormHelperTextProps={{style: this.handleHelperTextStyle()}}
                    error={
                      this.getErrorAndHelperText(
                        "storeName",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "storeName",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    onChange={(e) => {
                      if (this.state.isStoreNameError)
                        this.handleStoreNameErrorState();
                      handleChange(e);
                    }}
                  />
                  {this.state.isStoreNameError && (
                    <FormHelperTextContainer data-test-id="store-name-error">
                      {this.state.storeNameErrorMessage}
                    </FormHelperTextContainer>
                  )}
                </Grid>
                <Grid item xl={12} xs={12} sm={12} md={12} lg={12}>
                  <Typography className={"inputHeading"}>
                    {this.transSellerStore(configJSON.storeDescription)}
                  </Typography>
                  <TextField
                    {...getFieldProps("storeDescription")}
                    data-test-id="store-description"
                    fullWidth
                    variant="outlined"
                    FormHelperTextProps={{style: this.handleHelperTextStyle()}}
                    className="inputFeild2"
                    placeholder={this.transSellerStore(configJSON.storeDescriptionPlace)}
                    multiline
                    inputProps={{ maxLength: 420 }}
                    rows={3}
                    error={
                      this.getErrorAndHelperText(
                        "storeDescription",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "storeDescription",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                  />
                </Grid>
                <Grid item xl={6} xs={12} sm={12} md={12} lg={6}>
                  <Typography className={"inputHeading"}>
                    {this.transSellerStore(configJSON.address)}
                  </Typography>
                  <TextField
                    {...getFieldProps("address")}
                    fullWidth
                    FormHelperTextProps={{style: this.handleHelperTextStyle()}}
                    id="address"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {<img src={endLocationIcon} alt="location" />}
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    placeholder={`${this.transSellerStore(configJSON.enterString)} ${this.transSellerStore(configJSON.address)}`}
                    type="text"
                    className="inputFeild2"
                    error={
                      this.getErrorAndHelperText(
                        "address",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "address",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    inputProps={{ maxLength: 120 }}
                    data-test-id="create-store-address"
                  />
                </Grid>
                <Grid item xl={6} xs={12} sm={12} md={12} lg={6}>
                  <Typography className={"inputHeading"}>
                    {this.transSellerStore(configJSON.area)}*
                  </Typography>
                  <TextField
                    {...getFieldProps("area")}
                    fullWidth
                    id="area"
                    variant="outlined"
                    placeholder={`${this.transSellerStore(configJSON.enterString)} ${this.transSellerStore(configJSON.area)}`}
                    type="text"
                    className="inputFeild2"
                    error={
                      this.getErrorAndHelperText(
                        "area",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    FormHelperTextProps={{style: this.handleHelperTextStyle()}}
                    inputProps={{ maxLength: 50 }}
                    helperText={
                      this.getErrorAndHelperText(
                        "area",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    data-test-id="create-store-area"
                  />
                </Grid>
                <Grid item xl={6} xs={12} sm={12} md={12} lg={6}>
                  <Typography className={"inputHeading"}>
                    {this.transSellerStore(configJSON.block)}*
                  </Typography>
                  <TextField
                    {...getFieldProps("block")}
                    fullWidth
                    id="block"
                    variant="outlined"
                    placeholder={`${this.transSellerStore(configJSON.enterString)} ${this.transSellerStore(configJSON.block)}`}
                    type="text"
                    className="inputFeild2"
                    error={
                      this.getErrorAndHelperText(
                        "block",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    FormHelperTextProps={{style: this.handleHelperTextStyle()}}
                    inputProps={{ maxLength: 50 }}
                    helperText={
                      this.getErrorAndHelperText(
                        "block",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    data-test-id="create-store-block"
                  />
                </Grid>
                <Grid item xl={6} xs={12} sm={12} md={12} lg={6}>
                  {" "}
                  <Typography className={"inputHeading"}>
                    {`${this.transSellerStore(configJSON.mallName)} (${this.transSellerStore(configJSON.optionalString)})`}
                  </Typography>
                  <TextField
                    {...getFieldProps("mallName")}
                    fullWidth
                    id="mallName"
                    variant="outlined"
                    placeholder={`${this.transSellerStore(configJSON.enterString)} ${this.transSellerStore(configJSON.mallName)}`}
                    type="text"
                    className="inputFeild2"
                    error={
                      this.getErrorAndHelperText(
                        "mallName",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    inputProps={{ maxLength: 50 }}
                    helperText={
                      this.getErrorAndHelperText(
                        "mallName",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    data-test-id="create-store-mall-name"
                  />
                </Grid>
                <Grid item xl={6} xs={12} sm={12} md={12} lg={6}>
                  <Typography className={"inputHeading"}>
                    {`${this.transSellerStore(configJSON.floor)} (${this.transSellerStore(configJSON.optionalString)})`}
                  </Typography>
                  <TextField
                    {...getFieldProps("floor")}
                    fullWidth
                    id="floor"
                    variant="outlined"
                    placeholder={`${this.transSellerStore(configJSON.enterString)} ${this.transSellerStore(configJSON.floor)}`}
                    type="text"
                    FormHelperTextProps={{style: this.handleHelperTextStyle()}}
                    className="inputFeild2"
                    inputProps={{ maxLength: 50 }}
                    error={
                      this.getErrorAndHelperText(
                        "floor",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "floor",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    data-test-id="create-store-floor"
                  />
                </Grid>
                <Grid item xl={6} xs={12} sm={12} md={12} lg={6}>
                  <Typography className={"inputHeading"}>
                    {`${this.transSellerStore(configJSON.unitNumber)} (${this.transSellerStore(configJSON.optionalString)})`}
                  </Typography>
                  <TextField
                    {...getFieldProps("unitNumber")}
                    fullWidth
                    id="unitNumber"
                    inputProps={{ maxLength: 50 }}
                    variant="outlined"
                    placeholder={`${this.transSellerStore(configJSON.enterString)} ${this.transSellerStore(configJSON.unitNumber)}`}
                    type="text"
                    className="inputFeild2"
                    error={
                      this.getErrorAndHelperText(
                        "unitNumber",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    helperText={
                      this.getErrorAndHelperText(
                        "unitNumber",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    FormHelperTextProps={{style: this.handleHelperTextStyle()}}
                    data-test-id="create-store-unit-number"
                  />
                </Grid>
                <Grid item xl={6} xs={12} sm={12} md={12} lg={6}>
                  <Typography className={"inputHeading"}>
                    {configJSON.city}
                  </Typography>
                  <TextField
                    {...getFieldProps("city")}
                    inputProps={{ maxLength: 50 }}
                    id="city"
                    variant="outlined"
                    placeholder={`${this.transSellerStore(configJSON.enterString)} ${this.transSellerStore(configJSON.city)}`}
                    fullWidth
                    helperText={
                      this.getErrorAndHelperText(
                        "city",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    type="text"
                    className="inputFeild2"
                    error={
                      this.getErrorAndHelperText(
                        "city",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    FormHelperTextProps={{style: this.handleHelperTextStyle()}}
                    data-test-id="create-store-city"
                  />
                </Grid>
                <Grid item xl={6} xs={12} sm={12} md={12} lg={6}>
                  <Typography className={"inputHeading"}>
                    {configJSON.zipCode}
                  </Typography>
                  <TextField
                    {...getFieldProps("zipCode")}
                    placeholder={`${this.transSellerStore(configJSON.enterString)} ${this.transSellerStore(configJSON.zipCode)}`}
                    fullWidth
                    id="zipCode"
                    FormHelperTextProps={{style: this.handleHelperTextStyle()}}
                    helperText={
                      this.getErrorAndHelperText(
                        "zipCode",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                    type="text"
                    variant="outlined"
                    data-test-id="create-store-zip-code"
                    className="inputFeild2"
                    error={
                      this.getErrorAndHelperText(
                        "zipCode",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    inputProps={{ maxLength: 6 }}
                  />
                </Grid>
                <Grid xs={12} xl={6} sm={12} item md={12} lg={6}>
                  <Typography className={"inputHeading"}>
                    {this.transSellerStore(configJSON.instruction)}
                  </Typography>
                  <TextField
                    {...getFieldProps("instruction")}
                    fullWidth
                    id="instruction"
                    variant="outlined"
                    type="text"
                    className="inputFeild2"
                    data-test-id="create-store-instruction"
                    FormHelperTextProps={{style: this.handleHelperTextStyle()}}
                    error={
                      this.getErrorAndHelperText(
                        "instruction",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).isError
                    }
                    placeholder={`${this.transSellerStore(configJSON.enterString)} ${this.transSellerStore(configJSON.instructionForDrivePlace)}`}
                    inputProps={{ maxLength: 120 }}
                    helperText={
                      this.getErrorAndHelperText(
                        "instruction",
                        errors as FormError,
                        touched as FormErrorTouched
                      ).helperText
                    }
                  />
                </Grid>
                <Grid item xl={6} sm={12} lg={6} md={12} xs={12}>
                  <Typography className={"inputHeading"}>
                    {this.transSellerStore(configJSON.phoneNumber)}
                  </Typography>
                  <Box className="phone-num-container">
                    <Box className="phone-code-container">
                      <Select
                        id="phoneCode"
                        name="phoneCode"
                        className="inputFeild2"
                        variant="outlined"
                        fullWidth
      
                        placeholder={`${this.transSellerStore(configJSON.selectString)} ${this.transSellerStore(configJSON.phoneNumber)}`}
                        value={values.phoneCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          borderRadius: 2,
                          backgroundColor: "#F0F0F0",
                        }}
                        dir={i18n.dir()}
                        IconComponent={this.selectIconRender}
                        data-test-id="country-code-data-test-id"
                        renderValue={(selected) => {
                          const selectedCountry = this.state.countryCode.find(
                            (country) => country.numeric_code === selected
                          );
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: i18n.dir() === "rtl" ? "flex-end" : "flex-start" 
                              }}
                            >
                              <ReactCountryFlag
                                countryCode={
                                  selectedCountry?.country_code ||
                                  this.state.intialValue.phoneCode
                                }
                                svg
                              />
                              <span style={{ marginLeft: i18n.dir() === "rtl" ? 0 : 8, marginRight: i18n.dir() === "rtl" ? 8 : 0 }}>
                                ({selectedCountry?.numeric_code})
                              </span>
                            </div>
                          );
                        }}
                        MenuProps={{
                          style: {
                            minWidth: "180px",
                            height: "320px",
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: i18n.dir() === "rtl" ? "right":"left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: i18n.dir() === "rtl" ? "right":"left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {this.state.countryCode.map(
                          (country: CountrySuccess) => (
                            <MenuItem
                              dir={i18n.dir()}
                              key={country.numeric_code}
                              value={country.numeric_code}
                              style={{
                                fontFamily: "Lato, sans-serif",
                                fontSize: 12,
                                color: "#375280",
                                padding: "10px",
                                display: "flex",
                              }}
                            >
                              <Typography
                                style={{
                                  gap: 3,
                                  color: "#375280",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <ReactCountryFlag
                                  countryCode={country.country_code}
                                  svg
                                />{" "}
                                {country.country_full_name}
                                {" "}({country.numeric_code})
                              </Typography>
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </Box>
                    <TextField
                      {...getFieldProps("phoneNumber")}
                      fullWidth
                      id="phoneNumber"
                      variant="outlined"
                      FormHelperTextProps={{style: this.handleHelperTextStyle()}}
                      placeholder={`${this.transSellerStore(configJSON.enterString)} ${this.transSellerStore(configJSON.phoneNumber)}`}
                      type="text"
                      className="inputFeild2"
                      error={
                        this.getErrorAndHelperText(
                          "phoneNumber",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "phoneNumber",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      data-test-id="create-store-phone-number"
                      onChange={(e) => {
                        if (this.state.isPhoneNoError)
                          this.handlePhoneErrorState();
                        handleChange(e);
                      }}
                    />
                  </Box>
                  {this.state.isPhoneNoError && (
                    <FormHelperTextContainer
                      className="phone-no-error"
                      data-test-id="phone-no-error"
                    >
                      {this.state.phoneNoErrorMessage}
                    </FormHelperTextContainer>
                  )}
                </Grid>
              </Grid>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Grid container dir={i18n.dir()}>
          <Grid
            item
            xl={2}
            className="edit-seller-sidebar"
            md={3}
            lg={2}
            sm={3}
            xs={3}
          >
            <SellerSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid
            item
            xl={10}
            md={9}
            lg={10}
            className="edit-seller-header"
            sm={9}
            xs={9}
          >
            <SellerHeader navigation={this.props.navigation} />
            <EditSellerDetailContainer>
              {this.state.isLoader ? (
                <div className="loader-container">
                  <CircularProgress
                    id="seller-edit-store-loader"
                    style={{ color: "#375280" }}
                  />
                </div>
              ) : (
                this.renderFormik()
              )}
            </EditSellerDetailContainer>
            {this.renderUploadImgDialog()}
            <ReusableSnackbar
              open={this.state.isAlert}
              onClose={this.oncloseAlert}
              message={this.state.message}
              severity={this.state.severity}
              autoHideDuration={3000}
              dir={i18n.dir()}
              dataTestId="alertTestId"
            />
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const EditSellerDetailContainer = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
  "& .header-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 44,
    "& .title-container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 20,
      height: "100%",
      "& .title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 700,
        fontSize: 24,
        color: "#375280",
      },
    },
    "& .next-btn": {
      textTransform: "capitalize",
      color: "white",
      gap: "8px",
      backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
      height: "100%",
      borderRadius: "2px",
      "& .MuiButton-label": {
        fontSize: "18px",
        fontWeight: 600,
      },
    },
    "@media(max-width:800px)": {
      height: "max-content",
      "& .title-container": {
        "& .title": {
          fontWeight: 600,
          fontSize: "18px !important",
        },
      },
      "& .next-btn": {
        height: "44px !important",
        "& .MuiButton-label": {
          fontSize: "16px !important",
        },
      },
    },
  },
  "& .loader-container": {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .edit-store-profile-container": {
    padding: 20,
    border: "1px solid #E2E8F0",
    marginTop: 20,
    "& .MuiGrid-root.MuiGrid-container": {
      paddingTop: 20,
      "& .MuiGrid-root.MuiGrid-item": {
        "& .store-profile-image": {
          display: "flex",
          gap: 20,
          position: "relative",
          width: 100,
          "& .store-profile-img": {
            borderRadius: "50%",
            width: 100,
            aspectRatio: 1,
          },
          "& .store-profile-edit-img": {
            position: "absolute",
            bottom: 0,
            right: 0,
            width: 30,
            aspectRatio: 1,
          },
        },
        "& .inputHeading": {
          color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
          textOverflow: "ellipsis",
          fontFamily: "Lato, sans-serif",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "26px",
        },
        "& .MuiFormControl-root": {
          "& .MuiInputBase-root.MuiOutlinedInput-root.inputFeild2.Mui-error": {
            border: "1px solid #f44336",
          },
        },
        "& .inputFeild2": {
          "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error": {
            border: "1px solid #f44336",
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              color: "#f44336",
            },
          },
          "& .MuiTextField-root": {
            "&:hover": {
              border: "1px solid #F0F0F0 !important",
            },
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#F0F0F0",
            },
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
          },
          "& .MuiFormHelperText-contained": {
            margin: "0 !important",
          },
          "& .MuiInputBase-input": {
            color: "#375280",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
          },
        },
        "& .phone-num-container": {
          display: "flex",
          gap: 14,
          "& .phone-code-container": {
            width: "35%",
          },
          "@media(max-width:600px)": {
            flexDirection: "column",
            "& .phone-code-container": { width: "100%" },
          },
        },
        "& .phone-no-error": {
          marginLeft: "28%",
          "@media(max-width:600px)": {
            marginLeft: 0,
          },
        },
      },
    },
  },
});

const FormHelperTextContainer = styled(FormHelperText)({
  paddingTop: 5,
  color: "f44336",
});
// Customizable Area End
