import i18n from "../../../components/src/i18next/i18n";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

export interface OrderItemSeller {
  type: "order_item_seller";
  id: string;
  attributes: {
    reason_of_rejection: string | null;
    catalogue_name: string;
    currency_logo: string;
    brand_name: string;
    catalogue_variant_color: string;
    catalogue_variant_sku: string;
    store_name: string;
    catalogue_variant_size: string;
    catalogue_variant_front_image: string;
    catalogue_variant_back_image: string;
    catalogue_variant_side_image: string;
    driver_name: string | null;
    driver_latitude: number | null;
    driver_longitude: number | null;
    driver_phone_number: string | null;
    otp: string | null;
    status: string;
    placed_at: string;
    confirmed_at: string;
    in_transit_at: string | null;
    delivered_at: string | null;
    cancelled_at: string | null;
    rejected_at: string | null;
    process_at: string;
    shipped_at: string | null;
    return_at: string | null;
    return_cancel_at: string | null;
    return_pick_at: string | null;
    quantity: number;
    unit_price: string;
    total_price: string;
  };
}

interface DeliveryAddress {
  type: "delivery_address";
  id: string;
  attributes: {
    house_or_building_number: string;
    address_name: string;
    is_default: boolean;
    latitude: number;
    longitude: number;
    name: string;
    country_code: string;
    phone_number: string;
    contact_number: string;
    street: string;
    zip_code: string;
    area: string;
    block: string;
    city: string;
  };
}

interface PaymentDetail {
  refund_amount: string | null;
  refund_reason: string | null;
  seller_order_id: string | null;
  last_four_card_digit: string;
  payment_type: string;
  deleted: boolean;
  id: number;
  status: string;
  created_at: string;
  updated_at: string;
  charge_id: string;
  merchant_id: string | null;
  order_id: string;
  amount: number;
  currency: string;
  customer_id: string;
  reason: string;
  account_id: number;
  order_management_order_id: number;
  refund_id: string | null;
}

interface OrderSellerSide {
  type: "order_seller_side";
  id: string;
  attributes: {
    created_at: string;
    updated_at: string;
    order_deliver_date: string | null;
    order_deliver_time: string | null;
    delivery_addresses: DeliveryAddress;
    order_return_date: string | null;
    order_return_time: string | null;
    payment_detail: PaymentDetail;
    order_number: string;
    account: string;
    sub_total: string;
    total: string;
    status: string;
    placed_at: string;
    confirmed_at: string;
    in_transit_at: string | null;
    delivered_at: string | null;
    cancelled_at: string | null;
    refunded_at: string | null;
    returned_at: string | null;
    deliver_by: string | null;
    order_status_id: number;
  };
}

export interface SellerOrderSeller {
  type: "seller_order_seller";
  id: string;
  attributes: {
    order_items: OrderItemSeller[];
    order_management_order: OrderSellerSide;
    id: number;
    status: string;
    accept_order_upload_time: string | null;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  searchAllOrder:string;
  storeIds: string | number
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoadingDelivered: boolean
  SellerDeliveredView: SellerOrderSeller[];
  isAlertDelivered: boolean
  deliveredForPage: number
  metaDeliveredData: {
    next_page: number,
    total_pages: number,
    current_page: number,
    total_record: number,
    prev_page: number,
  },
  isLoading1:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerDeliveredController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSellerDeliveredApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoadingDelivered: true,
      SellerDeliveredView: [],
      metaDeliveredData: {
        total_pages: 0,
        current_page: 0,
        total_record: 0,
        prev_page: 0,
        next_page: 0
      },
      deliveredForPage: 1,
      isAlertDelivered: false,
      isLoading1:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getSellerDeliveredApiCallId){
      this.setState({ metaDeliveredData: responseJson.meta, SellerDeliveredView: responseJson.data,isLoading1:false, isLoadingDelivered: false, isAlertDelivered: responseJson.error === "No orders found." ? true : false })
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    this.getSellerDeliveredApi()
    // Customizable Area End
  }
  componentDidUpdate(prevProps: { searchAllOrder: string; storeIds: string | number }) {
    if (prevProps.searchAllOrder !== this.props.searchAllOrder || prevProps.storeIds !== this.props.storeIds) {
      this.setState({isLoading1:true, deliveredForPage: 1},()=>this.getSellerDeliveredApi());
    }
  }
  navigateToDelivered = ({productDetailId,props,screenName}: {productDetailId: string | undefined,props: unknown,screenName: string}) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productDetailId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productDetailId);
    runEngine.sendMessage(message.id, message);
  }

  getSellerDeliveredApi = async () => {
    const token = await getStorageData("auth-token");
    
    const headers = {
      "token": token
    };
    const countEndPoint = this.props.storeIds.toString() === "-1" ? `${configJSON.getSellerOrdersProcessed}?type=delivered&page=${this.state.deliveredForPage}&per_page=10&search=${this.props.searchAllOrder}` : `${configJSON.getSellerOrdersProcessed}?type=delivered&page=${this.state.deliveredForPage}&per_page=10&search=${this.props.searchAllOrder}&store_ids[]=${this.props.storeIds}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerDeliveredApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      countEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  transSellerDelivered = (transKey: string) => {
    return i18n.t( transKey, {ns: "orderDetail"})
  }
  // Customizable Area End
}
