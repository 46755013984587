import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
import i18n from "../../../../components/src/i18next/i18n";
export const configJSON = require("../config");
interface INavigateTo {
  productId: string | undefined;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
interface DeliveryAddressAttributes {
  phone_number: string;
  name: string;
  contact_number: string;
  country_code: string;
  zip_code: string;
  street: string;
  city: string;
  block: string;
  area: string;
  house_or_building_number: string;
  longitude: number;
  is_default: boolean;
  address_name: string;
  latitude: number;
}

interface DeliveryAddress {
  type: string;
  id: string;
  attributes: DeliveryAddressAttributes;
}

interface PaymentDetail {
  updated_at: string;
  status: string;
  order_id: string;
  created_at: string;
  merchant_id: string | null;
  id: number;
  charge_id: string;
  currency: string;
  customer_id: string;
  reason: string;
  amount: number;
  order_management_order_id: number;
  account_id: number;
  refund_amount: string | null;
  refund_id: string | null;
  seller_order_id: string | null;
  refund_reason: string | null;
  last_four_card_digit: string;
  payment_type: string;
  deleted: boolean;
}

interface OrderManagementOrderAttributes {
  account: string;
  placed_at: string;
  order_number: string;
  total: string;
  sub_total: string;
  confirmed_at: string;
  status: string;
  in_transit_at: string | null;
  returned_at: string | null;
  refunded_at: string | null;
  cancelled_at: string | null;
  order_deliver_date: string | null;
  delivered_at: string | null;
  order_status_id: number;
  created_at: string;
  deliver_by: string | null;
  order_return_date: string | null;
  order_deliver_time: string | null;
  updated_at: string;
  payment_detail: PaymentDetail;
  order_return_time: string | null;
  delivery_addresses: DeliveryAddress;
}

interface OrderManagementOrder {
  id: string;
  type: string;
  attributes: OrderManagementOrderAttributes;
}

interface OrderItemAttributes {
  confirmed_at: string;
  status: string;
  currency_logo: string;
  placed_at: string;
  in_transit_at: string | null;
  cancelled_at: string | null;
  delivered_at: string | null;
  shipped_at: string | null;
  process_at: string;
  return_pick_at: string | null;
  rejected_at: string | null;
  return_at: string | null;
  reason_of_rejection: string | null;
  return_cancel_at: string | null;
  quantity: number;
  catalogue_variant_sku: string;
  catalogue_variant_color: string;
  unit_price: string;
  total_price: string;
  catalogue_variant_side_image: string;
  brand_name: string;
  catalogue_name: string;
  store_name: string;
  catalogue_variant_front_image: string;
  catalogue_variant_back_image: string;
  catalogue_variant_size: string;
  driver_name: string | null;
  driver_phone_number: string | null;
  driver_latitude: number | null;
  otp: string | null;
  driver_longitude: number | null;
}

interface OrderItem {
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}
interface SellerOrderAttributes {
  order_items: OrderItem[];
  id: number;
  accept_order_upload_time: string | null;
  order_management_order: OrderManagementOrder;
  status: string;
}

interface SellerOrder {
  id: string;
  type: string;
  attributes: SellerOrderAttributes;
}
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  apiRecall: Function,
  searchAllOrder:string,
  storeIds: string | number
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  tableAllOrder: SellerOrder[];
  page: number;
  numberData: string;
  rejectId: number,
  visible: boolean,
  short: string[],
  isAlert: boolean,
  reloadTime: number,
  rejectOpen: boolean;
  metaData: {
    total_pages: number,
    current_page: number,
    total_record: number,
    prev_page: number,
    next_page: number
  },
  newOrderaOpen: boolean,
  confirmId: number,
  tableHaderName: string[],
  isLoading: boolean,
  isAlertOrder: boolean,
  already: string,
  timerFlag: boolean,
  timer: number,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellersNewOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMy_OrdersCallId: string = "";
  getAllNewOrderCallId: string = "";
  getRejectNewOrderCallId: string = "";
  getTimeOrderCallId: string = "";
  timerId: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      already: "",
      tableHaderName: ["Order ID", "Order Time", "Products", "Shop", "Total Price", "Status", "Action"],
      txtInputValue: "",
      page: 1,
      isAlertOrder: false,
      rejectId: 0,
      tableAllOrder: [],
      numberData: "",
      short: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
      rejectOpen: false,
      metaData: {
        total_pages: 0,
        current_page: 0,
        total_record: 0,
        prev_page: 0,
        next_page: 0
      },
      isAlert: false,
      newOrderaOpen: true,
      reloadTime: 45,
      confirmId: 0,
      visible: false,
      isLoading: true,
      timerFlag: true,
      timer: 60,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllNewOrderCallId !== null &&
      this.getAllNewOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonNewOrder = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({metaData: responseJsonNewOrder?.meta, tableAllOrder: responseJsonNewOrder?.data, isLoading: false, isAlert: responseJsonNewOrder.error === "No orders found." ? true : false })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getRejectNewOrderCallId !== null &&
      this.getRejectNewOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategoryReject = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategoryReject) {
        this.setState({ already: this.tranSellerNewOrder("Order Rejected"), isAlertOrder: true })
        this.props.apiRecall()
        setTimeout(() => {
          this.allOrderData()
        }, 3000)
      }
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTimeOrderCallId !== null &&
      this.getTimeOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonAppouvedCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonAppouvedCategory) {
        this.setState({ already: this.tranSellerNewOrder("Order Accepted"), isAlertOrder: true })
        this.props.apiRecall()
        setTimeout(() => {
          this.allOrderData()
        }, 3000)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };
  componentDidUpdate(prevProps: { searchAllOrder: string; storeIds: string | number }) {
    if (this.state.timerFlag && !this.timerId &&this.state.tableAllOrder !== undefined) {
      this.timerId = setInterval(() => {
        this.setState(prevState => {
          if (prevState.timer > 0) {
            return { timer: prevState.timer - 1 };
          } else {
            return { timer: 0 };
          }
        });
      },1000);
      this.state.tableAllOrder.length==0 &&clearInterval(this.timerId);
    }
    if (prevProps.searchAllOrder !== this.props.searchAllOrder || prevProps.storeIds !== this.props.storeIds) {
      this.setState({isLoading:true, page: 1 },()=>this.allOrderData())
    }
  }
  async componentWillUnmount() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }
  async componentDidMount() {
    this.allOrderData()
  }
  handleCancel = () => {
    this.setState({ visible: false });
  };
  handleOk = (confirmid: number) => {
    this.setState({ visible: true, confirmId: confirmid });
  };
  oncloseAlert = () => {
    this.setState({ isAlertOrder: false });
  };
  handleSingleChangeNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ numberData: event.target.value as string });
  };

  dateFormetTime = (created_at: string) => {
    if (!created_at) return "";
    const orderDate = new Date(created_at);
    let hoursOrder = orderDate.getHours();
    const minutes = orderDate.getMinutes();
    const ampm = hoursOrder >= 12 ? this.tranSellerNewOrder('PM') : this.tranSellerNewOrder('AM');
    hoursOrder = hoursOrder % 12;
    hoursOrder = hoursOrder ? hoursOrder : 12;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${hoursOrder}:${minutesStr} ${ampm}`;
  }

  incrementTime = () => {
    this.setState((prevState) => ({ reloadTime: prevState.reloadTime + 5 }));
  };

  decrementTime = () => {
    this.setState((prevState) => ({ reloadTime: prevState.reloadTime - 5 }));
  };
  rejectData = () => {
    this.setState({ rejectOpen: false })
    this.rejectOrderData()
    this.allOrderData()
  }
  timeData = () => {
    this.setState({ visible: false })
    this.timeOrderData()
    this.allOrderData()
  }

  rejectOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageNewList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectNewOrderCallId = requestMessageNewList.messageId;
    requestMessageNewList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageNewList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.rejectId}&type=reject&reason_of_rejection=${this.state.numberData}`
    );
    requestMessageNewList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageNewList.id, requestMessageNewList);
  }
  navigateTo = ({
    productId,
    props,
    screenName,
  }: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    runEngine.sendMessage(message.id, message);
  }
  allOrderData = async () => {
    const token = await getStorageData("auth-token");
    const countEndPoint = this.props.storeIds.toString() === "-1" ? `${configJSON.getnew_ordersAllOrder}&page=${this.state.page}&per_page=10&search=${this.props.searchAllOrder}` : `${configJSON.getnew_ordersAllOrder}&page=${this.state.page}&per_page=10&search=${this.props.searchAllOrder}&store_ids[]=${this.props.storeIds}`
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageNewOrderList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllNewOrderCallId = requestMessageNewOrderList.messageId;
    requestMessageNewOrderList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageNewOrderList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      countEndPoint
    );
    requestMessageNewOrderList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageNewOrderList.id, requestMessageNewOrderList);
  }
  handleOpen1 = (rejectid: number) => {
    this.setState({ rejectOpen: true, rejectId: rejectid })
  };
  timeOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListNewOrderTime = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeOrderCallId = requestMessageListNewOrderTime.messageId;
    requestMessageListNewOrderTime.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListNewOrderTime.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.confirmId}&type=accept&accept_order_upload_time=${this.state.timer}`
    );
    requestMessageListNewOrderTime.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListNewOrderTime.id, requestMessageListNewOrderTime);
  }
  handleClose1 = () => {
    this.setState({ rejectOpen: false })
  };

  tranSellerNewOrder = (transKey: string) => {
    return i18n.t( transKey, {ns: "orderDetail"})
  }
  // Customizable Area End
}
