import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
import React from "react"
import AuthenticateUser from "../../../components/src/AuthenticateUser";
import i18n from "../../../components/src/i18next/i18n";
export const configJSON = require("./config");

// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface INavigateTo {
    id: string | undefined;
    props: unknown;
    screenName: string;
    raiseMessage?: Message;
}
interface Product {
    id: string;
    type: string;
    attributes: {
        name: string;
        description: string;
        primary_image: string | undefined;
        primary_price: string | number;
        is_wishlist:boolean;
        primary_main_price: string | number;
        primary_discounted_percentage: number;
    };
}
interface CatalogueVariantColor {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
    };
}
interface CatalogueVariantSize {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
    };
}
interface Category {
    id: string | undefined;
    type: string;
    subSubData: SubCategory[] | undefined
    attributes: {
        id: number | undefined;
        name: string;
        status: string;
        created_at: string;
        updated_at: string;
        image: string;
    };
    subSubSubData?: SubCategory[]
}
interface SubCategory {
    id: string | number;
    type: string;
    subSubSubData?: SubsubsubCategory[]
    attributes: {
        id: number;
        name: string;
        status: string;
        created_at: string;
        updated_at: string;
        image: string;
    };
}
interface SubsubsubCategory {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        status: string;
        created_at: string;
        updated_at: string;
        image: string;
    };
}
interface S {
    // Customizable Area Start
    nextpage: number | null
    sampleState: string;
    numberData: string;
    short: string[];
    dummySizes: CatalogueVariantSize[],
    value: number[]
    products: Product[],
    brandData: string[],
    filterColor: CatalogueVariantColor[],
    sortedCategory: Category[],
    sortedCategoryLoading: boolean,
    subCategoryLoading: boolean,
    todos: object[],
    currentPage: number,
    showLoader: boolean,
    subCategory?: SubCategory[],
    subCategoryTreu: boolean,
    selectId: number | undefined;
    subsubSelectId: number[];
    componentHight: number | undefined;
    dummySizesPush: number[];
    gender: string;
    categoryName: string;
    total_pages: number;
    showScorl: boolean;
    colorePush: number[];
    latestGen: string | undefined;
    pruiceValueSlider: boolean;
    subsubsubPush: number[];
    circularProgress: boolean;
    circularWishlish:boolean;
    genderActive: boolean;
    setErrors: string,
    filterStart: boolean,
    navigationGender:string;
    catalogusName:string;
    wishlistLike:string,
    already:string | JSX.Element,
    isAlert:boolean,
    errorWishlist:string,
    wishlistId:string | number
    modalOpen:boolean,
    tokens: string, currencySign: string;
    exploreBuyer: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CategoriessubcategoriesControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallId: string = "";
    postUserCallId: string = "";
    getColorCallId: string = "";
    getSizeCallId: string = "";
    getCategoryCallId: string = "";
    getSub_categoriesCallId: string = "";
    getSubSub_categoriesCallId: string = "";
    getproductCallId: string = "";
    getproductGetCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage)
        ];
        this.state = {
            nextpage: null,
            circularProgress: false,
            circularWishlish:false,
            subsubsubPush: [],
            colorePush: [],
            wishlistId:"",
            showScorl: false,
            total_pages: 1,
            categoryName: "",
            currencySign: "$",
            gender: "Men",
            dummySizesPush: [],
            subCategoryLoading: false,
            exploreBuyer: false,
            subsubSelectId: [],
            selectId: 0,
            subCategoryTreu: false,
            subCategory: [],
            todos: [],
            currentPage: 1,
            showLoader: false,
            sortedCategory: [],
            filterColor: [],
            brandData: ["Celio", "Jack & Jones", "John Player", "Mufti"],
            value: [0, 100],
            dummySizes: [],
            sampleState: 'sample',
            numberData: '',
            short: ["What’s Old"],
            products: [],
            sortedCategoryLoading: false,
            componentHight: 0,
            latestGen: "",
            pruiceValueSlider: false,
            genderActive: false,
            setErrors: '',
            filterStart: false,
            navigationGender:"",
            wishlistLike:"",
            already:"",
            isAlert:false,
            errorWishlist:"",
            catalogusName:"",
            modalOpen:false,
            tokens:""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        const token = await getStorageData("auth-token")
        const userDetails = await getStorageData("userRole", true);
        const exploreBuyer = await getStorageData("exploreBuyer", true);
        if(exploreBuyer){
            this.setState({exploreBuyer})
        }
        if(userDetails?.currencyLogo){
            this.setState({currencySign: userDetails.currencyLogo, tokens: token})
        }
        this.categoryTole()
        this.coloreTole()
        this.sizeTole()
        const dataBox = document.getElementById('scroll') as HTMLElement;
        dataBox.addEventListener('scroll', this.handleScroll);
        !this.state.showLoader && this.state.setErrors == '' && this.loadTodos(this.state.currentPage);
        this.getDynamicHight()
        const navigationGender = await getStorageData("navigationGender")
        this.setState({gender:navigationGender,showLoader: true, circularProgress: true, tokens:token})
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.getSub_productsCall(message)
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getColorCallId !== null &&
            this.getColorCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonColor = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ filterColor: responseJsonColor.data })
        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getSizeCallId !== null &&
            this.getSizeCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonSize = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ dummySizes: responseJsonSize.data })
        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getCategoryCallId !== null &&
            this.getCategoryCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonCategory = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.getDynamicHight()
            this.setState({ sortedCategory: responseJsonCategory.data, sortedCategoryLoading: false })
            this.categoryNameData(this.state.gender)
        }
        this.getSub_categoriesCall(message)
        this.getSubSub_categories(message)
        this.getLikeproductsCall(message)
        this.getLikeproductsDeleteCall(message)
        // Customizable Area End
    }
    getLikeproductsCall = (message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getproductCallId !== null &&
            this.getproductCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonPortable = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJsonPortable) {
                const { errors, meta } = responseJsonPortable;
                const errorWishlist = errors && errors.length > 0 ? errors[0].token : null;
                const already = this.metaMessage(meta)
                const productIdToRemove = responseJsonPortable.data.attributes.favouriteable_id;
                const updatedProducts = this.state.products.map(product => { if (product.id == productIdToRemove) { return { ...product, attributes: { ...product.attributes, is_wishlist: !product.attributes.is_wishlist } } } return product });
                this.setState({ products: updatedProducts, errorWishlist: errorWishlist, already: already, isAlert: true, circularWishlish: false },()=>{
                    const dataBox = document.getElementById('scroll') as HTMLElement;
                    dataBox.addEventListener('scroll', this.handleScroll);
                });
            }
        }
    }
    metaMessage = (meta: { message: string; }) => {
        return meta && meta.message === "Added to wishlist." ? "item added to wishlist" : this.renderDialog()
    }
    handleCondition = (condition: any, trueSta: any, falseSta: any) => {
        return condition ? trueSta : falseSta
    }
    getLikeproductsDeleteCall = (message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getproductGetCallId !== null &&
            this.getproductGetCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonPortable = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJsonPortable.message === "Destroy successfully") {
                const updatedProducts = this.state.products.map(product => { if (product.id == this.state.wishlistId) { return { ...product, attributes: { ...product.attributes, is_wishlist: !product.attributes.is_wishlist } } } return product });
                this.setState({ products: updatedProducts, already: "The item has been removed from wishlist", isAlert: true, circularWishlish: false },()=>{
                    const dataBox = document.getElementById('scroll') as HTMLElement;
                    dataBox.addEventListener('scroll', this.handleScroll);
                });
            }
        }
    }
    getSub_productsCall = (message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserCallId !== null &&
            this.postUserCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonPortable = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.errorsResponse(responseJsonPortable)
        }
    }
    errorsResponse = (responseJsonPortable: { errors: string, data: any, meta: { next_page: number | null } }) => {
        if (!!responseJsonPortable.errors) {
            const errorMessage = this.state.showScorl ? '' : 'Catalogues Not Found. '
            this.setState({ products: this.state.filterStart && !this.state.showScorl ? [] : this.state.products, setErrors: errorMessage, circularProgress: false, showLoader: false, showScorl: false, filterStart: false, nextpage: null })
        } else {
            if( this.state.tokens && responseJsonPortable?.data?.length > 0 ){
                this.setState(prevState => {
                    const updatedProducts = this.responsePortable(responseJsonPortable, prevState);
                    const currentPage = !responseJsonPortable.errors ? prevState.currentPage + 1 : prevState.currentPage;
                    return { products: updatedProducts, nextpage: responseJsonPortable.meta.next_page, circularProgress: false, showLoader: false, currentPage: currentPage, showScorl: false, setErrors: '', filterStart: false, currencySign: responseJsonPortable.data[0]?.attributes?.currency_logo };
                });
            }else{
                this.setState(prevState => {
                    const updatedProducts = this.responsePortable(responseJsonPortable, prevState);
                    const currentPage = !responseJsonPortable.errors ? prevState.currentPage + 1 : prevState.currentPage;
                    return { products: updatedProducts, nextpage: responseJsonPortable.meta?.next_page, circularProgress: false, showLoader: false, currentPage: currentPage, showScorl: false, setErrors: '', filterStart: false };
                });
            }
        }
    }
    getSub_categoriesCall = (message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getSub_categoriesCallId !== null &&
            this.getSub_categoriesCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonSub_categories = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const datas = this.state.sortedCategory.map((value: Category) => {
                return value.id == this.state.selectId ? { ...value, subSubData: responseJsonSub_categories.data } : value
            })
            this.setState({ sortedCategory: datas, subCategoryLoading: false, filterStart: false },()=>this.getDynamicHight())
        }
    }

    getDynamicHight = () => {
        const dataBoxId = document.getElementById("scrollId") as HTMLElement
        const height = dataBoxId.offsetHeight;
        this.setState({ componentHight: height })
    }
    getSubSub_categories = (message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getSubSub_categoriesCallId !== null &&
            this.getSubSub_categoriesCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonSubSub_categories = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const datas = this.state.sortedCategory.map((value: Category) => {
                return value.id == this.state.selectId ? {
                    ...value, subSubData: value.subSubData?.map((subsubCategoreyData: SubCategory) => {
                        return subsubCategoreyData.id == this.state.subsubSelectId[0] ? { ...subsubCategoreyData, subSubSubData: responseJsonSubSub_categories.data } : subsubCategoreyData
                    })
                } : value
            })
            this.getDynamicHight()
            this.setState({ sortedCategory: datas, filterStart: false })
        }
    }

    // Customizable Area Start
    async componentDidUpdate() {
        const navigationGender = await getStorageData("navigationGender");
        if (navigationGender !== this.state.gender) {
        this.setState({gender:navigationGender, showLoader: true, circularProgress: true ,setErrors:"",products:[]},()=> this.handleCategoryApi(navigationGender));
        }
    }
    responsePortable = (responseJsonPortable: { data: [], errors?: string }, prevState: {
        products: Product[];
        showLoader: boolean;
        currentPage: number;
        showScorl: boolean;
    }) => {
        let updatedProducts: Product[];
        if (!responseJsonPortable.errors) {
            if (this.state.showScorl) {
                updatedProducts = [...prevState.products, ...responseJsonPortable.data];
            } else {
                updatedProducts = responseJsonPortable.data;
            }
        } else {
            updatedProducts = [];
        }
        return updatedProducts;
    }

    handleSizeClick = (sizeId: number) => {
        let newlangSize: number[] = []
        if (this.state.dummySizesPush.includes(sizeId)) {
            newlangSize = this.state.dummySizesPush.filter(value => value !== sizeId)
        } else {
            newlangSize = [...this.state.dummySizesPush, sizeId]
        }
        this.setState({ dummySizesPush: newlangSize, currentPage: 1, genderActive: true, filterStart: true, showScorl: false });
        this.loadTodos(this.state.currentPage, 1)
    }
    handleColorClick = (colorId: number) => {
        let newlangColore: number[] = []
        if (this.state.colorePush.includes(colorId)) {
            newlangColore = this.state.colorePush.filter(value => value !== colorId)
        } else {
            newlangColore = [...this.state.colorePush, colorId]
        }
        this.setState({ colorePush: newlangColore, currentPage: 1, genderActive: true, filterStart: true, showScorl: false });
        this.loadTodos(this.state.currentPage, 1)
    }
    loadTodos = async (currentPage: number, total?: number) => {
        const token = await getStorageData("auth-token");
        const newGender = this.state.latestGen ? `category_ids[]=${this.state.latestGen}&` : ""
        const variant_size = this.state.dummySizesPush.length === 0 ? "" : `catalogue_variant_size_ids[]=${this.state.dummySizesPush.join(',')}&`
        const variant_color = this.state.colorePush.length === 0 ? "" : `catalogue_variant_color_ids[]=${this.state.colorePush.join(',')}&`
        const newsubsubsubLength = this.state.subsubsubPush.length === 0 ? "" : `sub_sub_category_ids[]=${this.state.subsubsubPush.join(',')}&`
        const newPrice = this.state.value.length && this.state.pruiceValueSlider ? `min_price=${this.state.value[0]}&max_price=${this.state.value[1]}&` : ''
        const inSubCatUrl = this.state.subsubSelectId.length === 0 ? '' : `sub_category_ids[]=${this.state.subsubSelectId.join(',')}&`
        let urlPrefix = token === null ? `${configJSON.getCatalogues}` : configJSON.cataloguesToken;
        let finalUrl = `${urlPrefix}?${variant_size}${variant_color}${newPrice}${newGender}${inSubCatUrl}${newsubsubsubLength}page=${total ? total : currentPage}&per_page=12`;
        finalUrl = token === null ? `${finalUrl}&currency=${this.state.currencySign === "$" ? "dollar": "dinar"}` : finalUrl
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postUserCallId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.product_cataloge
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            finalUrl
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
        this.setState({ showLoader: true, circularProgress: true });
    };
    async componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        const dataBox = document.getElementById('scroll') as HTMLElement;
        dataBox.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = async () => {
        const dataBox = await document.getElementById('scroll') as HTMLElement;
        const scrollY = dataBox.scrollTop;
        const windowHeight = dataBox.clientHeight;
        const documentHeight = dataBox.scrollHeight;
        if (scrollY + windowHeight >= documentHeight - 100) {
            this.setState({ showScorl: true }, () => {
                const conditions = [
                    this.state.setErrors === '',
                    this.state.nextpage !== null
                ];
                if (conditions.every(condition => condition)) {
                    this.loadTodos(this.state.currentPage);
                }
            })

        }

    };
    handleSingleChangeNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ numberData: event.target.value as string });
    };
    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
        const newdata: number[] = [...this.state.value];
        newdata[index] = parseInt(event.target.value.replace(/[^0-9.]/g, '')) | 0;
        this.setState({ value: newdata, pruiceValueSlider: true, circularProgress: true, filterStart: true, showScorl: false })
        this.loadTodos(this.state.currentPage, 1)
    };
    coloreTole = async () => {
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getColorCallId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.product_cataloge
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.catalogues_variants_colors
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };
    sizeTole = async () => {
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSizeCallId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.product_cataloge
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.catalogues_variants_sizes
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    categoryTole = async () => {
        this.setState({ sortedCategoryLoading: true })
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCategoryCallId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.product_cataloge
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.categories
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };
    sub_category = async (categoryId: number | undefined) => {
        this.setState({ selectId: categoryId, subCategoryLoading: true, filterStart: true, showScorl: false })
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSub_categoriesCallId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.product_cataloge
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sub_categories + categoryId
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }

    subsubCategorey = async (subsubCategoreyId: number) => {
        this.setState({ subsubSelectId: [subsubCategoreyId], filterStart: true, showScorl: false })
        this.loadTodos(this.state.currentPage, 1)
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSubSub_categoriesCallId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.product_cataloge
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.sub_sub_categories}=${subsubCategoreyId}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }
    navigateTo = async ({
        id,
        props,
        screenName,
        raiseMessage,
    }: INavigateTo) => {
        setStorageData("stylitOrder","buyerBuyNow")
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), props);
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
        raiseMessage &&
            message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        runEngine.sendMessage(message.id, message);
    }
    wishlistLike = async (productId: string) => {
        const wishlistId = await getStorageData("matchedClients")
        if (!this.state.tokens) {
            this.setState({
              modalOpen: true,circularWishlish:false
            });
            return false;
          }
        const token = await getStorageData("auth-token");
        const bodyLink = { "data": { "favouriteable_id": Number(productId),"wishlist_id":Number(wishlistId)} }
        const header = {
            "Content-Type": configJSON.categoryApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getproductCallId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostType
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.favouritesLink
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(bodyLink)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }
    wishlistDeleteGet = async (productId: string) => {
        if (!this.state.tokens) {
            this.setState({
              modalOpen: true,circularWishlish:false
            });
            return false;
          }
        const token = await getStorageData("auth-token");
        const bodyDelete = { "data": { "favouriteable_id": Number(productId) } }
        const header = {
            "Content-Type": configJSON.categoryApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getproductGetCallId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpDeleteType
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.favouriteableTokenDelete}=${productId}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(bodyDelete)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }
    is_wishlistData = (product: Product, event: React.MouseEvent) => {
        window.removeEventListener('scroll', this.handleScroll);
        const dataBox = document.getElementById('scroll') as HTMLElement;
        dataBox.removeEventListener('scroll', this.handleScroll);
        event.stopPropagation()
        if (product.attributes.is_wishlist) {
            this.setState({wishlistId:product.id,circularWishlish:true},()=>this.wishlistDeleteGet(product.id))
        } else {
            this.setState({wishlistId:product.id,circularWishlish:true}, ()=>this.wishlistLike(product.id))
           
        }

    }
    oncloseAlert = () => {
        this.setState({ isAlert: false });
      };
      renderDialog = () => {
        return (
           <AuthenticateUser 
                auth={this.state.tokens} 
                title="Log in to view your Wishlist."
                description="Shop them anything you like."
                btnText="Log In or Sign up"
                navigation={this.props.navigation}
                onClose={this.closeModal}
            />
        )
    }
    closeModal = () => {
      this.setState({modalOpen: false});
    };
    subsubsubCategorey = async (subsubsubCategoreyId: number,deepName:string) => {
        let newlang: number[] = []
        if (this.state.subsubsubPush.includes(subsubsubCategoreyId)) {
            newlang = this.state.subsubsubPush.filter(value => value !== subsubsubCategoreyId)
        } else {
            newlang = [...this.state.subsubsubPush, subsubsubCategoreyId]
        }
        this.setState({ subsubsubPush: newlang, currentPage: 1, filterStart: true, showScorl: false,catalogusName:deepName });
        this.loadTodos(this.state.currentPage, 1)
    }
    categoryNameData = (value: string) => {
        const categoryName1 = this.state.sortedCategory.filter((category: { attributes: { name: string } }, index: number) => { return category.attributes?.name.includes(value) })
        console.log("159159");

        !!categoryName1[0]?.id && this.loadTodos(this.state.currentPage, 1);
        this.setState({ categoryName: categoryName1[0]?.attributes.name, latestGen: categoryName1[0]?.id })
    }
    
    handleGenderChange = (event: React.ChangeEvent<{}>) => {
        const value = (event.target as HTMLInputElement).value;
        this.handleCategoryApi(value)
    }

    handleCategoryApi = async (catvalue:string) => {
        this.categoryNameData(catvalue);
        await setStorageData("navigationGender", catvalue)
        this.setState({ gender: catvalue, genderActive: true, filterStart: true, currentPage: 1 });
    }

    transFilter = (categoryKey: string) => {
        return i18n.t(categoryKey, { ns: "categoryFilter"})
    } 
    // Customizable Area End
}