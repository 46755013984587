import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area Start
type AlertType = 'success' | 'error' | 'warning' | 'info';
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../../../framework/src/Utilities";
interface INavigateTo {
  productId: string | undefined;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
interface PrimarySize {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  name_in_arabic: string;
}

interface PrimaryColour {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  name_in_arabic: string;
}

interface FavouriteableAttributes {
  name: string;
  brand: string | null;
  sku: string | null;
  description: string;
  manufacture_date: string | null;
  stock_qty: number | null;
  availability: string | null;
  price: string | null;
  discount: string | null;
  is_wishlist: boolean;
  product_number: string | null;
  primary_image: string;
  primary_price: string;
  gender: string;
  brand_name: string;
  material: string;
  fit: string;
  prodcut_care: string;
  list_the_product: string;
  fit_discription: string | null;
  primary_size: PrimarySize;
  primary_colour: PrimaryColour;
}

interface FavouriteableData {
  id: string;
  type: string;
  attributes: FavouriteableAttributes;
}

interface Favouriteable {
  data: FavouriteableData;
}

interface FavouriteAttributes {
  favouriteable_id: number;
  favouriteable_type: string;
  wishlist_id: number;
  notes: string | null;
  created_at: string;
  updated_at: string;
  favouriteable: Favouriteable;
}

interface Favourite {
  id: string;
  type: string;
  attributes: FavouriteAttributes;
}

// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  favouriteList: Favourite[];
  matchedClientsId: number | string;
  isLoder: boolean;
  clientName: string
  alertType: AlertType,
  already: string;
  isAlert: boolean;
  userRole: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylingClientWishlistController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getproductGetCallId: string = "";
  getWislistDesLickCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      favouriteList: [],
      userRole: "",
      matchedClientsId: "",
      isLoder: true,
      already: "",
      isAlert: false,
      alertType: 'success' as AlertType,
      clientName: ""
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getproductGetCallId !== null &&
      this.getproductGetCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortable = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ favouriteList: responseJsonPortable && responseJsonPortable.data, isLoder: false })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getWislistDesLickCallId !== null &&
      this.getWislistDesLickCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortable = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonPortable.message === "Destroy successfully") {
        this.setState({ already: "The item has been removed from wishlist", isAlert: true, isLoder: false }, () => this.ralphWishlistGet(this.state.matchedClientsId))
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true)
    const matchedClientsLocl = await getStorageData("matchedClients")
    const selectedClient = await getStorageData("selectedClient")
    this.setState({matchedClientsId:matchedClientsLocl, clientName: selectedClient ?? "", userRole: userData.userType})
    this.ralphWishlistGet(matchedClientsLocl)
    // Customizable Area End
  }

  handleStylishClientWLRedirect = (redirect:string) =>{
    if(this.state.userRole === "buyer"){
      this.props.navigation.goBack()
      return
    }
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }

  handleStylishGenderLRedirect = async (redirect:string,name:string) =>{
    await setStorageData("exploreBuyer", "true");
    await setStorageData("navigationGender", name)
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }

  ralphWishlistGet = async (matchedClientsLocl:string|number) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getproductGetCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouritesWishlist}=${matchedClientsLocl}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  navigateTo = ({
    productId,
    props,
    screenName,
  }: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    runEngine.sendMessage(message.id, message);
  }

  wislistDesLick = async (clientsWishlistId:string | number,event: React.MouseEvent) => {
    event?.stopPropagation()
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWislistDesLickCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favourWishlists}=${clientsWishlistId}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
    this.setState({isLoder:true})
  }
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
