export const enChat = {
 "New Order Request":"New Order Request",
 "Product Name":"Product Name",
 "Product name here":"Product name here",
 "Gender":"Gender",
 "Size":"Size",
 "Select a Product Size":"Select a Product Size",
 "Colour":"Colour",
 "Select a Product Colour":"Select a Product Colour",
 "Product Quantity":"Product Quantity",
 "Price Per Unit":"Price Per Unit",
 "Shipping Cost":"Shipping Cost",
 "Amount":"Amount",
 "In units":"In units",
 "Product Display Image":"Product Display Image",
 "Uploaded Preview":"Uploaded Preview",
 "Replace Image":"Replace Image",
 "Upload png, jpg, jpeg":"Upload png, jpg, jpeg",
 "upload":"upload",
 "Cancel":"Cancel",
 "Send Request":"Send Request",
 "Male":"Male",
 "Female":"Female",
 "Kids":"Kids",
 "* Product name must start with a letter and only contain letters":"* Product name must start with a letter and only contain letters",
 "* Product name is required. Please enter the necessary information":"* Product name is required. Please enter the necessary information",
 "* Gender is required. Please enter the necessary information":"* Gender is required. Please enter the necessary information",
 "* Size is required. Please enter the necessary information":"* Size is required. Please enter the necessary information",
 "* Color is required. Please enter the necessary information":"* Color is required. Please enter the necessary information",
 "* Product Quantity cannot contain spaces":"* Product Quantity cannot contain spaces",
 "* Product Quantity must be a positive integer and cannot start with 0":"* Product Quantity must be a positive integer and cannot start with 0",
 "* Product Quantity is required. Please enter the necessary information":"* Product Quantity is required. Please enter the necessary information",
 "* Price Per Unit cannot contain spaces":"* Price Per Unit cannot contain spaces",
 "* Price Per Unit must be a positive integer and cannot start with 0":"* Price Per Unit must be a positive integer and cannot start with 0",
 "* Price Per Unit is required. Please enter the necessary information":"* Price Per Unit is required. Please enter the necessary information",
 "* Shipping Cost cannot contain spaces":"* Shipping Cost cannot contain spaces",
 "* Shipping Cost must be a positive integer and cannot start with 0":"* Shipping Cost must be a positive integer and cannot start with 0",
 "* Shipping Cost is required. Please enter the necessary information":"* Shipping Cost is required. Please enter the necessary information",
 "* Image is required. Please enter the necessary information":"* Image is required. Please enter the necessary information",
 "File size is too large. Maximum 5MB is allowed.":"File size is too large. Maximum 5MB is allowed.",
 "* Unsupported file format. Only PNG, JPG, and JPEG are allowed.":"* Unsupported file format. Only PNG, JPG, and JPEG are allowed.",
 "Edit Terms & Conditions":"Edit Terms & Conditions",
 "Requesting Amount":"Requesting Amount",
 "Reason for Requesting":"Reason for Requesting",
 "New Payment Request":"New Payment Request",
 "* Request Reason must start with a letter and only contain letters":"* Request Reason must start with a letter and only contain letters",
 "* Request Reason is required. Please enter the necessary information":"* Request Reason is required. Please enter the necessary information",
 "* Request Amount cannot contain spaces":"* Request Amount cannot contain spaces",
 "* Request Amount must be a positive integer and cannot start with 0":"* Request Amount must be a positive integer and cannot start with 0",
 "* Request Amount is required. Please enter the necessary information":"* Request Amount is required. Please enter the necessary information",
 "Clients":"Clients",
 "Chats":"Chats",
 "Search Clients":"Search Clients",
 "No clients found":"No clients found",
 "Welcome!":"Welcome!",
 "Color":"Color",
 "Quantity":"Quantity",
 "Total Cost":"Total Cost",
 "I agree to the stylist’s":"I agree to the stylist’s",
 "terms and conditions":"terms and conditions",
 "of services.":"of services.",
 "Pay":"Pay",
 "UI: coming soon":"UI: coming soon",
 "No UI available":"No UI available",
 "Type your message here":"Type your message here",
 "attach file":"attach file",
 "Block":"Block",
 "Send New Order Request":"Send New Order Request",
 "Send Payment Request":"Send Payment Request",
 "Share Wishlist":"Share Wishlist",
 "Report person":"Report person",
 "Accept & Add to Cart": "Accept & Add to Cart",
 "Report":"Report",
 "Are you sure you want to report":"Are you sure you want to report",
 "Are you sure you want to block":"Are you sure you want to block",
 "Select the Reason":"Select the Reason",
 "Select the reason for report":"Select the reason for report",
 "Other Details":"Other Details",
 "Tell us what happened":"Tell us what happened",
 "Details must start with a letter and only contain letters":"Details must start with a letter and only contain letters",
 "Details is required. Please enter the necessary information":"Details is required. Please enter the necessary information",
 "Report reason is required, please select reason": "Report reason is required, please select reason",
 "?": "?",
 "Choose Service Plan": "Choose Service Plan",
 "No whishlist available": "No whishlist available",
 "Explore Wishlist" : "Explore Wishlist", 
 "Please accept Terms and Conditions": "Please accept Terms and Conditions",
 "Order Accepted" : "Order Accepted",
 "Paid" : "Paid"
}

export const arChat = {
 "New Order Request":"طلب أمر جديد",
 "Product Name":"اسم المنتج",
 "Product name here":"اسم المنتج هنا",
 "Gender":"جنس",
 "Size":"مقاس",
 "Select a Product Size":"حدد حجم المنتج",
 "Colour":"لون",
 "Select a Product Colour":"حدد لون المنتج",
 "Product Quantity":"كمية المنتج",
 "Price Per Unit":"السعر لكل وحدة",
 "Shipping Cost":"تكلفة الشحن",
 "Amount":"كمية",
 "In units":"في الوحدات",
 "Product Display Image":"صورة عرض المنتج",
 "Uploaded Preview":"المعاينة المرفوعة",
 "Replace Image":"استبدال الصورة",
 "Upload png, jpg, jpeg":"تحميل png، jpg، jpeg",
 "upload":"رفع",
 "Cancel":"الإلغاء",
 "Send Request":"إرسال الطلب",
 "Male":"ذكر",
 "Female":"أنثى",
 "Kids":"أطفال",
 "* Product name must start with a letter and only contain letters":"* اسم المنتج يجب أن يبدأ بحرف ويحتوي على حروف فقط",
 "* Product name is required. Please enter the necessary information":"* اسم المنتج مطلوب. الرجاء إدخال المعلومات اللازمة",
 "* Gender is required. Please enter the necessary information":"* الجنس مطلوب . الرجاء إدخال المعلومات اللازمة",
 "* Size is required. Please enter the necessary information":"* الحجم مطلوب. الرجاء إدخال المعلومات اللازمة",
 "* Color is required. Please enter the necessary information":"* اللون مطلوب. الرجاء إدخال المعلومات اللازمة",
 "* Product Quantity cannot contain spaces":"* لا يمكن أن تحتوي كمية المنتج على مسافات",
 "* Product Quantity must be a positive integer and cannot start with 0":"* يجب أن تكون كمية المنتج عددًا صحيحًا موجبًا ولا يمكن أن تبدأ بـ 0",
 "* Product Quantity is required. Please enter the necessary information":"* كمية المنتج مطلوبة. الرجاء إدخال المعلومات اللازمة",
 "* Price Per Unit cannot contain spaces":"* لا يمكن أن يحتوي سعر الوحدة على مسافات",
 "* Price Per Unit must be a positive integer and cannot start with 0":"* يجب أن يكون سعر الوحدة عددًا صحيحًا موجبًا ولا يمكن أن يبدأ بـ 0",
 "* Price Per Unit is required. Please enter the necessary information":"* السعر لكل وحدة مطلوب. الرجاء إدخال المعلومات اللازمة",
 "* Shipping Cost cannot contain spaces":"* تكلفة الشحن لا يمكن أن تحتوي على مسافات",
 "* Shipping Cost must be a positive integer and cannot start with 0":"* يجب أن تكون تكلفة الشحن عددًا صحيحًا موجبًا ولا يمكن أن تبدأ بـ 0",
 "* Shipping Cost is required. Please enter the necessary information":"* تكلفة الشحن مطلوبة. الرجاء إدخال المعلومات اللازمة",
 "* Image is required. Please enter the necessary information":"* الصورة مطلوبة. الرجاء إدخال المعلومات اللازمة",
 "File size is too large. Maximum 5MB is allowed.":"حجم الملف كبير جدًا. الحد الأقصى المسموح به هو 5 ميجابايت.",
 "* Unsupported file format. Only PNG, JPG, and JPEG are allowed.":"* تنسيق الملف غير معتمد. يُسمح فقط باستخدام PNG وJPG وJPEG.",
 "Edit Terms & Conditions":"تعديل الشروط والأحكام",
 "Requesting Amount":"طلب المبلغ",
 "Reason for Requesting":"سبب الطلب",
 "New Payment Request":"طلب دفع جديد",
 "* Request Reason must start with a letter and only contain letters":"* سبب الطلب يجب أن يبدأ بحرف ويحتوي على حروف فقط",
 "* Request Reason is required. Please enter the necessary information":"* سبب الطلب مطلوب. الرجاء إدخال المعلومات اللازمة",
 "* Request Amount cannot contain spaces":"* لا يمكن أن يحتوي مبلغ الطلب على مسافات",
 "* Request Amount must be a positive integer and cannot start with 0":"* يجب أن يكون مبلغ الطلب عددًا صحيحًا موجبًا ولا يمكن أن يبدأ بـ 0",
 "* Request Amount is required. Please enter the necessary information":"* مبلغ الطلب مطلوب. الرجاء إدخال المعلومات اللازمة",
 "Clients":"العملاء",
 "Chats":"الدردشات",
 "Search Clients":"عملاء البحث",
 "No clients found":"لم يتم العثور على عملاء",
 "Welcome!":"مرحباً!",
 "Color":"لون",
 "Quantity":"كمية",
 "Total Cost":"التكلفة الإجمالية",
 "I agree to the stylist’s":"أنا أوافق على المصمم",
 "terms and conditions":"الشروط والأحكام",
 "of services.":"من الخدمات.",
 "Pay":"يدفع",
 "UI: coming soon":"واجهة المستخدم: قريبا",
 "No UI available":"لا توجد واجهة مستخدم متاحة",
 "Type your message here":"اكتب رسالتك هنا",
 "attach file":"إرفاق الملف",
 "Block":"حظر",
 "Send New Order Request":"إرسال طلب طلب جديد",
 "Send Payment Request":"إرسال طلب الدفع",
 "Share Wishlist":"مشاركة قائمة الرغبات",
 "Report person":"الإبلاغ عن شخص",
 "Accept & Add to Cart": "قبول وإضافة إلى السلة",
 "Report":"التبليغ",
 "Are you sure you want to report":"هل أنت متأكد أنك تريد الإبلاغ",
 "Are you sure you want to block":"هل أنت متأكد أنك تريد الحظر",
 "Select the Reason":"حدد السبب",
 "Select the reason for report":"حدد سبب التقرير",
 "Other Details":"تفاصيل أخرى",
 "Tell us what happened":"أخبرنا بما حدث",
 "Details must start with a letter and only contain letters":"يجب أن تبدأ التفاصيل بحرف وتحتوي على أحرف فقط",
 "Details is required. Please enter the necessary information":"التفاصيل مطلوبة. الرجاء إدخال المعلومات اللازمة",
 "Report reason is required, please select reason": "سبب التقرير مطلوب، يرجى تحديد السبب",
 "?": "؟",
 "Choose Service Plan": "اختر خطة الخدمة",
 "No whishlist available" : "لا توجد قائمة أمنياتمتاحة",
 "Explore Wishlist": "استكشف قائمة الرغبات",
 "Please accept Terms and Conditions": "يرجى قبول الشروط والأحكام",
 "Order Accepted": "تم قبول الطلب",
 "Paid": "مدفوع"
}