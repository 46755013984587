import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent, createRef } from "react";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import * as Yup from "yup";
import parse from "date-fns/parse";
import dayjs from "dayjs";
import { logoutSellerNavigation } from "../../../components/src/Seller/logOut";
import { ApiKey } from "../../../components/src/APICall";
import i18n from "../../../components/src/i18next/i18n";

export interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
  isJsonStringify?: boolean;
}

export interface ErrorMessage {
  errors: [
    {
      token?: string;
      contact_number?: string;
      store_name?: string;
    }
  ];
}

interface StoreName {
  exists: boolean;
}

export interface FormError {
  [key: string]: string;
}
export interface FormErrorTouched {
  [key: string]: boolean;
}

interface IValidContactResp{
  owner_contact_valid: false,
  company_contact_valid: false
}

export interface CountrySuccess {
  numeric_code: string;
  country_full_name: string;
  country_code: string;
  country_flag: string;
}

export interface Istep2 {
  address: string;
  addressArabic: string;
  area: string;
  areaArabic: string;
  block: string;
  mallName: string;
  mallNameArabic: string;
  floor: string;
  floorArabic: string;
  unitNumber: string;
  city: string;
  cityArabic: string;
  zipCode: string;
  paymentMode: string[];
  instruction: string;
  instructionArabic: string;
  phoneCode: string;
  phoneNumber: string;
}


interface Istep3 {
  address: string;
  addressArabic: string;
  area: string;
  areaArabic: string;
  block: string;
  mallArabic: string;
  mallnameArabic: string;
  floor: string;
  floorArabic: string;
  unitnumber: string;
  city: string;
  cityArabic: string;
  zipCOde: string;
  paymentMode: string[];
  instructionArabic: string;
  phoneCode: string;
  phoneNumber: string;
}
export interface Step3State {
  monFrom: string | null;
  monTo: string | null;
  tueFrom: string | null;
  tueTo: string | null;
  wedFrom: string | null;
  wedTo: string | null;
  thuFrom: string | null;
  thuTo: string | null;
  friFrom: string | null;
  friTo: string | null;
  satFrom: string | null;
  satTo: string | null;
  sunFrom: string | null;
  sunTo: string | null;
  averageTime: string;
}

interface Stepp3atett{
  monFrom: string | null;
  monTo: string | null;
  
}
interface CreateStoreResponse{
  data: object;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeStep: number;
  errorsMessageStep1: {
    errorStoreName?: string;
    storeNameError?: boolean;
    errorStoreDescription?: string;
    storeDescriptionError?: boolean;
    errorStoreNameArabic?: string;
    storeNameErrorArabic?: boolean;
    errorStoreDescriptionArabic?: string;
    storeDescriptionErrorArabic?: boolean;
    errorStoreUpload?: string;
    storeUploadError?: boolean;
  };
  arabicMapVisible: boolean;
  step1: {
    storeName: string;
    storeNameArabic: string;
    storeDescriptionArabic: string;
    storeDescription: string;
    selectedFile: File | null;
    previewUrl: string;
  };
  step2: Istep2;
  step3: Step3State;
  step3Response: Step3State;
  invalidContact: string;
  backDropLoader: boolean;
  alertBox: {
    isAlert: boolean;
    message: string;
    severity: "error" | "warning" | "info" | "success";
  };
  payMentMode: string[];
  countryCode: CountrySuccess[];
  isSuccess: boolean
  mapVisible: boolean;
  selectedLocationStore: { lat: number; lng: number } | null;
  currentLocationStore: { lat: number; lng: number } | null;
  selectedLocationStoreAr: { lat: number; lng: number } | null;
  currentLocationStoreAr: { lat: number; lng: number } | null;
  mapsLoadedStore: boolean;
  latitude: number | null,
  longitude:number | null,
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateSellerStoreController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  storeNameAPICallId: string = "";
  countryCodeAPICallId: string = "";
  createStoreAPICallId: string = "";
  validPhoneNumberAPICallId: string = "";
  scrollContainerRef = createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      activeStep: 0,
      errorsMessageStep1: {
        errorStoreName: "",
        storeNameError: false,
        errorStoreDescription: "",
        storeDescriptionError: false,
        errorStoreUpload: "",
        storeUploadError: false,
      },
      backDropLoader: false,
      step1: {
        storeName: "",
        storeDescription: "",
        selectedFile: null,
        previewUrl: "",
        storeNameArabic: "",
        storeDescriptionArabic: ""
      },
      invalidContact: "",
      step2: {
        address: "",
        addressArabic: "",
        area: "",
        areaArabic: "",
        block: "",
        mallName: "",
        floor: "",
        floorArabic: "",
        unitNumber: "0",
        city: "",
        cityArabic: "",
        zipCode: "",
        paymentMode: [],
        instruction: "",
        instructionArabic: "",
        phoneCode: "+965",
        phoneNumber: "",
        mallNameArabic: ""
      },
      step3: {
        monFrom: null,
        monTo: null,
        tueFrom: null,
        tueTo: null,
        wedFrom: null,
        wedTo: null,
        thuFrom: null,
        thuTo: null,
        friFrom: null,
        friTo: null,
        satFrom: null,
        satTo: null,
        sunFrom: null,
        sunTo: null,
        averageTime: "",
      },
      step3Response: {
        monFrom: "",
        monTo: "",
        tueFrom: "",
        tueTo: "",
        wedFrom: "",
        wedTo: "",
        thuFrom: "",
        thuTo: "",
        friFrom: "",
        friTo: "",
        satFrom: "",
        satTo: "",
        sunFrom: "",
        sunTo: "",
        averageTime: "",
      },
      payMentMode: [],
      alertBox: {
        isAlert: false,
        message: "",
        severity: "error",
      },
      countryCode: [],
      isSuccess: false,
      mapVisible: false,
      selectedLocationStore: null,
      currentLocationStore: null,
      selectedLocationStoreAr: null,
      currentLocationStoreAr: null,
        mapsLoadedStore: false,
        latitude: null,
        longitude: null,
        arabicMapVisible: false
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.apiSuccessCall(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.apiFailureCall(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.getCountryCodeApi();
   
    // Customizable Area End
  }

  handleScrollToTop = () => {
    if (this.scrollContainerRef.current) {
      this.scrollContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  scrollToSection = (sectionIdName:string) => {
    const section = document.getElementById(sectionIdName);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  apiCallFunc = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body, token, isJsonStringify } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body ,
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiSuccessCall = async (
    apiRequestCallId: string,
    responseJson: StoreName & CountrySuccess[] & CreateStoreResponse & IValidContactResp
  ) => {
    switch (apiRequestCallId){
      case this.storeNameAPICallId: 
      this.handleStoreNameResp(responseJson)
    break;
    case this.countryCodeAPICallId:
      if (responseJson) {
        this.setState((prevState) => ({
          ...prevState,
          countryCode: responseJson,
        }));
      }
      break;
    case this.createStoreAPICallId:
        if(responseJson.data){
          this.setState((prevState) => ({
            ...prevState,
            isSuccess: true,
            backDropLoader: false
          }), () => {
            setTimeout(() => {
              this.handleRedirect("MyStore");
            }, 4000);
          });
        }
        break;
    case this.validPhoneNumberAPICallId:
      this.handleValidPhoneNumberResp(responseJson)
      break;
    default:
      break;
  };
}

handleStoreNameResp = (responseJson: StoreName) =>{
  if (responseJson) {
    this.setState((prevState) => ({
      ...prevState,
      errorsMessageStep1: {
        ...prevState.errorsMessageStep1,
        errorStoreName: responseJson.exists
          ? configJSON.storeNameExistMsg
          : "",
        storeNameError: responseJson.exists,
      },
      activeStep: responseJson.exists ? 0 : 1,
    }));
    this.handleScrollToTop()
  }
}

handleValidPhoneNumberResp = (responseJson:IValidContactResp)=>{
  if(responseJson.company_contact_valid){
    this.setState((prevState) => ({
      ...prevState,
      backDropLoader: false,
      activeStep: prevState.activeStep + 1,
    }),() => {
      this.handleScrollToTop()
    });}
  else{
    const {phoneCode, phoneNumber} = this.state.step2
    this.setState((prevState) => ({
      ...prevState,
      backDropLoader: false,
      invalidContact : `${phoneCode} ${phoneNumber} ${this.tranCreateStore("is not a valid phone number")}`,
    }),() => {
      this.scrollToSection("phoneNumber")
    });
  }
}

  apiFailureCall = (responseJson: ErrorMessage) => {
    if (responseJson.errors[0].token) {
      this.setState((prevState) => ({
        ...prevState,
        backDropLoader: false,
        alertBox: {
          ...prevState.alertBox,
          severity: "error",
          message: responseJson.errors[0].token as string,
          isAlert: true,
        },
      }), () => {
        setTimeout(() => {
          this.handleRedirect("Home");
          logoutSellerNavigation();
        }, 2000);
      });
    } else if (responseJson.errors[0].contact_number){
      const {phoneCode, phoneNumber} = this.state.step2;
      this.setState((prevState) => ({
        ...prevState,
        backDropLoader: false,
        invalidContact : `${phoneCode} ${phoneNumber} ${responseJson.errors[0].contact_number as string}`,
        activeStep: 1
      }));
      this.scrollToSection("phoneNumber")
    }
    else {
      this.setState((prevState) => ({
        ...prevState,
        alertBox: {
          ...prevState.alertBox,
          severity: "error",
          message:  responseJson.errors[0].store_name as string,
          isAlert: true,
        },
        backDropLoader: false,
      }));
    }
  };

  handleRedirect = (redirect:string) =>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const maxFileSize = 5 * 1024 * 1024;
    const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    
    if (file) {
      if(file.size > maxFileSize || !validFileTypes.includes(file.type)) {
        this.setState((prevState) => ({
          ...prevState,
          errorsMessageStep1: {
            ...prevState.errorsMessageStep1,
            errorStoreUpload: `${this.tranCreateStore('Unsupported file type OR File size too large')}`,
            storeUploadError: true,
          },
        }))
      } else {
      this.setState((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          selectedFile: file,
          previewUrl: URL.createObjectURL(file),
        },
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreUpload: "",
          storeUploadError: false,
        },
      }));
    }
    }
  };

  handleStoreName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const storeName = event.target.value;
    const validStoreName = event.target.value.trim();
    const hasLeadingOrTrailingSpaces = event.target.value !== validStoreName;
    let errorMessage = "";
    if (hasLeadingOrTrailingSpaces) {
      errorMessage = `${this.tranCreateStore(configJSON.storeName)} ${this.tranCreateStore(configJSON.spaceErr)}`;
    }
    if (hasLeadingOrTrailingSpaces) {
    this.setState((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        storeName: storeName,
      },
      errorsMessageStep1: {
        ...prevState.errorsMessageStep1,
        errorStoreName: errorMessage,
        storeNameError: true,
      },
    }));
  }
  else{
    this.setState((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        storeName: storeName,
      },
      errorsMessageStep1: {
        ...prevState.errorsMessageStep1,
        errorStoreName: "",
        storeNameError: false,
      },
    }));
  }
  };
  handleStoreNameArabic = (event: React.ChangeEvent<HTMLInputElement>) => {
    const storeName = event.target.value;
    const validStoreName = event.target.value.trim();
    const hasLeadingOrTrailingSpaces = event.target.value !== validStoreName;
    let errorMessage = "";
    if (hasLeadingOrTrailingSpaces) {
      errorMessage = `${this.tranCreateStore(configJSON.storeName)} ${this.tranCreateStore(configJSON.spaceErr)}`;
    }
    if (hasLeadingOrTrailingSpaces) {
    this.setState((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        storeNameArabic: storeName,
      },
      errorsMessageStep1: {
        ...prevState.errorsMessageStep1,
        errorStoreNameArabic: errorMessage,
        storeNameErrorArabic: true,
      },
    }));
  }
  else{
    this.setState((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        storeNameArabic: storeName,
      },
      errorsMessageStep1: {
        ...prevState.errorsMessageStep1,
        errorStoreNameArabic: "",
        storeNameErrorArabic: false,
      },
    }));
  }
  };
  handleStoreDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    const storeDescription = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        storeDescription: storeDescription,
      },
      errorsMessageStep1: {
        ...prevState.errorsMessageStep1,
        errorStoreDescription: "",
        storeDescriptionError: false,
      },
    }));
  };
  handleStoreDescriptionArabic = (event: React.ChangeEvent<HTMLInputElement>) => {
    const storeDescription = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        storeDescriptionArabic: storeDescription,
      },
      errorsMessageStep1: {
        ...prevState.errorsMessageStep1,
        errorStoreDescriptionArabic: "",
        storeDescriptionErrorArabic: false,
      },
    }));
  };
  validateStoreName = () => {
    const { storeName } = this.state.step1;
    if (storeName.trim() === "") {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreName: `${this.tranCreateStore(configJSON.storeNameValidMsg)}${this.tranCreateStore(configJSON.afterReqMessage)}`,
          storeNameError: true,
        },
      }));
      return false;
    }
    else if(storeName !== storeName.trim()){
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          storeNameError: true,
          errorStoreName: `${this.tranCreateStore(configJSON.storeName)} ${this.tranCreateStore(configJSON.spaceErr)}`,
        },
        step1: {
          ...prevState.step1,
          storeName: storeName,
        },
      }));
      return false;
    }
    return true;
  };

  validateStoreNameArabic = () => {
    const { storeNameArabic } = this.state.step1;
    if (storeNameArabic.trim() === "") {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreNameArabic: `${this.tranCreateStore(configJSON.storeNameValidMsg)}${this.tranCreateStore(configJSON.afterReqMessage)}`,
          storeNameErrorArabic: true,
        },
      }));
      return false;
    }
    else if(storeNameArabic !== storeNameArabic.trim()){
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          storeNameErrorArabic: true,
          errorStoreNameArabic: `${this.tranCreateStore(configJSON.storeName)} ${this.tranCreateStore(configJSON.spaceErr)}`,
        },
        step1: {
          ...prevState.step1,
          storeNameArabic: storeNameArabic,
        },
      }));
      return false;
    }
    return true;
  };

  validateStoreDescription = () => {
    const { storeDescription } = this.state.step1;
    if (storeDescription.trim() === "") {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreDescription: `${this.tranCreateStore(configJSON.storeDescriptionValidMsg)}${this.tranCreateStore(configJSON.afterReqMessage)}`,
          storeDescriptionError: true,
        },
      }));
      return false;
    }
    return true;
  };

  validateStoreDescriptionArabic = () => {
    const { storeDescriptionArabic } = this.state.step1;
    if (storeDescriptionArabic.trim() === "") {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreDescriptionArabic: `${this.tranCreateStore(configJSON.storeDescriptionValidMsg)}${this.tranCreateStore(configJSON.afterReqMessage)}`,
          storeDescriptionErrorArabic: true,
        },
      }));
      return false;
    }
    return true;
  };

  validateStoreUpload = () => {
    const { selectedFile } = this.state.step1;
    const maxFileSize = 5 * 1024 * 1024;
    const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!selectedFile) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreUpload: `${this.tranCreateStore(configJSON.storeUploadValidMsg)}. ${this.tranCreateStore("Please upload the image")}`,
          storeUploadError: true,
        },
      }));
      return false;
    } else if (selectedFile.size > maxFileSize) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreUpload: `${this.tranCreateStore("File size should be less than 5 MB")}`,
          storeUploadError: true,
        },
      }));
      return false;
    }else if (!validFileTypes.includes(selectedFile.type)) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreUpload: `${this.tranCreateStore("Only .png, .jpg, and .jpeg files are allowed")}`,
          storeUploadError: true,
        },
      }));
      return false;
    }

    return true;
  };

  handleSubmit1 = (submitEvent: {preventDefault: () => void}) => {
    submitEvent.preventDefault();
    if (!this.validateStoreUpload()) {
      return;
    }
    if (!this.validateStoreName()) {
      return;
    }
    if (!this.validateStoreNameArabic()) {
      return;
    }
    if (!this.validateStoreDescription()) {
      return;
    }

    if (!this.validateStoreDescriptionArabic()) {
      return;
    }
    this.getStoreNameApi();
  };

  getToken = async () => {
    return await getStorageData("auth-token")
  }

  getStoreNameApi = async () => {
    this.storeNameAPICallId = await this.apiCallFunc({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.validStoreNameEndPoint}${this.state.step1.storeName}`,
      token: await this.getToken(),
    });
  };

  getErrorAndHelperText = (
    field: string,
    errors: FormError,
    touched: FormErrorTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    const helperText: string = isError ? errors[field] ?? "" : "";
    return { isError, helperText:this.tranCreateStore(helperText) };
  };

  handleBack = () => {
    if(this.state.activeStep > 0){
    this.setState((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep - 1,
    }));
    this.handleScrollToTop();
  }
  else{
    this.handleRedirect("SellerStore");
  }
  };
  tranCreateStore = (transKey: string) => {
    return i18n.t(transKey, {ns: "store"});
  }


  getCountryCodeApi = async () => {
    this.countryCodeAPICallId = await this.apiCallFunc({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.countryCodeEndPoint,
    });
  };

  handleSubmit2 = async (values: Istep2) => {
    if(this.state.invalidContact!=="") return;
    this.setState((prevState) => ({
      ...prevState,
      step2: values,
    }), ()=> this.validMobileNumber());
  };

  validMobileNumber = async ()=>{
    const {step2} =this.state;
    const formData = new FormData();
    formData.append("data[attributes][company_contact_number]", `${step2.phoneCode}${step2.phoneNumber}`);
    this.validPhoneNumberAPICallId = await this.apiCallFunc({
      token: await this.getToken(),
      body: formData,
      isJsonStringify: false,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.validPhoneNumEndPoint,
    });
  }

  capitalizeFirstLetter = (day: string) =>
    day.charAt(0).toUpperCase() + day.slice(1);

  parseTime = (timeString: string) => {
    return parse(timeString, "hh:mm a", new Date());
  };

  valueStep3 = (timeValue: string | null) =>{
    return timeValue ? timeValue : null
  }

  parseValidFormate = (timeString: string | null) => {
    return dayjs(timeString).format('HH:MM')
  }


  handleSubmit3 = (values: Step3State) => {
    this.setState((prevState) => ({
      ...prevState,
      step3Response:{
        ...prevState.step3Response,
        monFrom: this.parseValidFormate(values.monFrom),
        monTo: this.parseValidFormate(values.monTo),
        tueFrom: this.parseValidFormate(values.tueFrom),
        tueTo: this.parseValidFormate(values.tueTo),
        wedFrom: this.parseValidFormate(values.wedFrom),
        wedTo: this.parseValidFormate(values.wedTo),
        thuFrom: this.parseValidFormate(values.thuFrom),
        thuTo: this.parseValidFormate(values.thuTo),
        friFrom: this.parseValidFormate(values.friFrom),
        friTo: this.parseValidFormate(values.friTo),
        satFrom: this.parseValidFormate(values.satFrom),
        satTo: this.parseValidFormate(values.satTo),
        sunFrom: this.parseValidFormate(values.sunFrom),
        sunTo: this.parseValidFormate(values.sunTo),
      },
      step3:{
        ...prevState.step3,
        averageTime: values.averageTime,
        monFrom: values.monFrom,
        monTo: values.monTo,
        tueFrom: values.tueFrom,
        tueTo: values.tueTo,
        wedFrom: values.wedFrom,
        wedTo: values.wedTo,
        thuFrom: values.thuFrom,
        thuTo: values.thuTo,
        friFrom: values.friFrom,
        friTo: values.friTo,
        satFrom: values.satFrom,
        satTo: values.satTo,
        sunFrom: values.sunFrom,
        sunTo: values.sunTo,
      }
    }));
    this.createStoreAPICallFunc();
  };

  createStoreAPICallFunc = async () => {
    const {step1, step2, step3Response, payMentMode, step3} =this.state;
    const formData = new FormData();
    formData.append("business[store_name]",step1.storeName);
    formData.append("business[store_name_arabic]",step1.storeNameArabic);
    formData.append("business[description]",step1.storeDescription);
    formData.append("business[description_arabic]",step1.storeDescriptionArabic);
    formData.append("business[address_arabic]",step2.addressArabic);
    if(step1.selectedFile){
      formData.append("business[image]", step1.selectedFile);
    }
    formData.append("business[address]", step2.address);
    formData.append("business[area_arabic]", step2.areaArabic);
    formData.append("business[area]",step2.area);
    formData.append("business[block]",step2.block);
    formData.append("business[block_arabic]",step2.block);
    formData.append("business[mall_name]",step2.mallName);
    formData.append("business[mall_name_arabic]",step2.mallNameArabic);
    formData.append("business[floor]",step2.floor);
    formData.append("business[floor_arabic]",step2.floorArabic);
    formData.append("business[unit_number]",step2.unitNumber);
    formData.append("business[city]",step2.city);
    formData.append("business[city_arabic]",step2.cityArabic);
    formData.append("business[zipcode]",step2.zipCode);
    formData.append("business[payment_mode][]", payMentMode.toString());
    formData.append("business[driver_instruction]", step2.instruction);
    formData.append("business[driver_instruction_arabic]", step2.instructionArabic);
    formData.append("business[contact_number]", `${step2.phoneCode}${step2.phoneNumber}`);
    if(step3Response.monFrom) formData.append("business[store_operating_hours][monday][open", step3Response.monFrom);
    
    if(step3Response.monTo) formData.append("business[store_operating_hours][monday][close]", step3Response.monTo);
    if(step3Response.tueFrom) formData.append("business[store_operating_hours][tuesday][open]", step3Response.tueFrom);
    if(step3Response.tueTo) formData.append("business[store_operating_hours][tuesday][close]", step3Response.tueTo);
    if(step3Response.wedFrom) formData.append("business[store_operating_hours][wednesday][open]", step3Response.wedFrom);
    if(step3Response.wedTo) formData.append("business[store_operating_hours][wednesday][close]", step3Response.wedTo);
    if(step3Response.thuFrom) formData.append("business[store_operating_hours][thursday][open]", step3Response.thuFrom);
    if(step3Response.thuTo) formData.append("business[store_operating_hours][thursday][close]", step3Response.thuTo);
    if(step3Response.friFrom) formData.append("business[store_operating_hours][friday][open]", step3Response.friFrom);
    if(step3Response.friTo) formData.append("business[store_operating_hours][friday][close]", step3Response.friTo);
    if(step3Response.satFrom) formData.append("business[store_operating_hours][saturday][open]", step3Response.satFrom);
    if(step3Response.satTo) formData.append("business[store_operating_hours][saturday][close]", step3Response.satTo);
    if(step3Response.sunFrom) formData.append("business[store_operating_hours][sunday][open]", step3Response.sunFrom);
    if(step3Response.sunTo) formData.append("business[store_operating_hours][sunday][close]", step3Response.sunTo);
    formData.append("business[average_shipping_time]", step3.averageTime);
    formData.append("business[latitude]", this.state.latitude ? String(this.state.latitude) : "");
    formData.append("business[longitude]", this.state.longitude ? String(this.state.longitude) : "");
    this.setState((prevState)=>({...prevState, backDropLoader: true}));
      this.createStoreAPICallId = await this.apiCallFunc({
        method: configJSON.httpPostMethod,
        endPoint: configJSON.createStoreEndPoint,
        token: await this.getToken(),
        body: formData,
        isJsonStringify: false
      });
  }

  onAlertBoxClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      alertBox: {
        ...prevState.alertBox,
        isAlert: !prevState?.alertBox.isAlert,
      },
    }));
  };
  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.activeStep !== prevState.activeStep) {
      const existingScriptAr = document.getElementById("google-maps-script-ar");
  
      if (!existingScriptAr) {
        const scriptAr = document.createElement("script");
        scriptAr.id = "google-maps-script";
        scriptAr.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey()}&libraries=places`;
        scriptAr.async = true;
        scriptAr.onload = this.handleScriptLoadArabic;
        document.body.appendChild(scriptAr);
      } else {
        this.handleScriptLoadArabic();
      }
    }
  }
  handleScriptLoad = () => {
    this.setState({ mapsLoadedStore: true });
  
    const input = document.getElementById("autocomplete5") as HTMLInputElement;

    if (input) {
        const autocomplete5 = new window.google.maps.places.Autocomplete(input);
        autocomplete5.setFields(["geometry", "formatted_address"]); 

        autocomplete5.addListener("place_changed", () => {
            const place = autocomplete5.getPlace();
            console.log(place); // Debugging line

            if (place.geometry && place.geometry.location) {
                const { lat, lng } = place.geometry.location;
                const fullAddress = place.formatted_address || ""; 

                this.setState({
                    selectedLocationStore: { lat: lat(), lng: lng() },
                    currentLocationStore: { lat: lat(), lng: lng() },
                    latitude: lat(),
                    longitude: lng(),
                    mapVisible: true,
                    step2:{
                      ...this.state.step2,
address:fullAddress
                    }

                });
            } else {
                console.error("No geometry found for this place.");
            }
        });
    }
};

handleScriptLoadArabic = () => {
  this.setState({ mapsLoadedStore: true });
  this.handleScriptLoad()
  const input = document.getElementById("autocomplete6") as HTMLInputElement;

  if (input) {
      const autocomplete6 = new window.google.maps.places.Autocomplete(input);
      autocomplete6.setFields(["geometry", "formatted_address"]); 

      autocomplete6.addListener("place_changed", () => {
          const place = autocomplete6.getPlace();
          console.log(place); // Debugging line

          if (place.geometry && place.geometry.location) {
              const { lat, lng } = place.geometry.location;
              const fullAddress = place.formatted_address || ""; 

              this.setState({
                  selectedLocationStoreAr: { lat: lat(), lng: lng() },
                  currentLocationStoreAr: { lat: lat(), lng: lng() },
                  latitude: lat(),
                  longitude: lng(),
                  arabicMapVisible: true,
                  step2:{
                    ...this.state.step2,
                    addressArabic:fullAddress
                  }

              });
          } else {
              console.error("No geometry found for this place.");
          }
      });
  }
};
  // Customizable Area End
}
// Customizable Area Start
export const step2Schema = Yup.object().shape({
  address: Yup.string().required(`${(configJSON.addressReq)}${configJSON.afterReqMessage}`).trim(),
  addressArabic: Yup.string().required(`${(configJSON.addressReq)}${configJSON.afterReqMessage}`).trim(),
  area: Yup.string().required(`${configJSON.areaReq}${configJSON.afterReqMessage}`).trim(),
  areaArabic: Yup.string().required(`${configJSON.areaReq}${configJSON.afterReqMessage}`).trim(),
  block: Yup.string().required(`${configJSON.blockReq}${configJSON.afterReqMessage}`).trim(),
  blockArabic: Yup.string().required(`${configJSON.blockReq}${configJSON.afterReqMessage}`).trim(),
  city: Yup.string().required(`${configJSON.cityReq}${configJSON.afterReqMessage}`)
  .matches(configJSON.alphaRegx, `*${configJSON.city} ${configJSON.alphaErr}`) // Ensures only alphabetic characters and spaces are allowed
  .trim(),
  cityArabic: Yup.string().required(`${configJSON.cityReq}${configJSON.afterReqMessage}`)
  .matches(configJSON.alphaRegx, `*${configJSON.city} ${configJSON.alphaErr}`) 
  .trim(),
  unitNumber: Yup.string().matches(configJSON.numRegx, configJSON.unitNumErr).notRequired(),
  zipCode: Yup.number()
    .typeError(configJSON.zipCodeNumErr)
    .required(`${configJSON.zipCodeReq}${configJSON.afterReqMessage}`),
  //paymentMode: Yup.array().of(Yup.string()).min(1, `${configJSON.paymentModeReq}${configJSON.afterReqMessage}`),
  instruction: Yup.string().required(`${configJSON.instructionReq}${configJSON.afterReqMessage}`),
  instructionArabic: Yup.string().required(`${configJSON.instructionReq}${configJSON.afterReqMessage}`),
  phoneNumber: Yup.string()
    .required(`${configJSON.phoneReq}${configJSON.afterReqMessage}`)
    .matches(configJSON.numRegx, configJSON.phoneNumErr) // Ensure it only contains digits
    .min(8, configJSON.phoneMinErr)
    .max(10, configJSON.phoneMaxErr),
});

export const paymentModes = [
  { label: "UPI", value: "UPI" },
  { label: "COD", value: "COD" },
  { label: "DEBIT CARD", value: "DEBIT CARD" },
  { label: "CREDIT CARD", value: "CREDIT CARD" },
];

export const averageDeliveryModes = [
  { label: "0 mins", value: "0 mins" },
  { label: "10 mins", value: "10 mins" },
  { label: "20 mins", value: "20 mins" },
  { label: "30 mins", value: "30 mins" },
  { label: "40 mins", value: "40 mins" },
  { label: "50 mins", value: "50 mins" },
  { label: "60 mins", value: "60 mins" },
];

export const step3Schema = Yup.object().shape({
  monFrom: Yup.date()
    .required("Monday From Time is required")
    .typeError("Invalid time format").nullable(),
  monTo: Yup.string()
    .required("Monday To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Monday To Time must be greater than Monday From Time",
      function (value) {
        const { monFrom } = this.parent;
        if (!monFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(monFrom).add(1, 'minute'));
      }
    ).nullable(),
  tueFrom: Yup.date()
    .required("Tuesday From Time is required")
    .typeError("Invalid time format").nullable(),
  tueTo: Yup.date()
    .required("Tuesday To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Tuesday To Time must be greater than Tuesday From Time",
      function (value) {
        const { tueFrom } = this.parent;
        if (!tueFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(tueFrom).add(1, 'minute'));
      }
    ).nullable(),
  wedFrom: Yup.date()
    .required("Wednesday From Time is required")
    .typeError("Invalid time format").nullable(),
  wedTo: Yup.date()
    .required("Wednesday To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Wednesday To Time must be greater than Wednesday From Time",
      function (value) {
        const { wedFrom } = this.parent;
        if (!wedFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(wedFrom).add(1, 'minute'));
      }
    ).nullable(),
  thuFrom: Yup.date()
    .required("Thursday From Time is required")
    .typeError("Invalid time format").nullable(),
  thuTo: Yup.date()
    .required("Thursday To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Thursday To Time must be greater than Thursday From Time",
      function (value) {
        const { thuFrom } = this.parent;
        if (!thuFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(thuFrom).add(1, 'minute'));
      }
    ).nullable(),
  friFrom: Yup.date()
    .required("Friday From Time is required")
    .typeError("Invalid time format").nullable(),
  friTo: Yup.date()
    .required("Friday To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Friday To Time must be greater than Friday From Time",
      function (value) {
        const { friFrom } = this.parent;
        if (!friFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(friFrom).add(1, 'minute'));
      }
    ).nullable(),
  satFrom: Yup.date()
    .required("saturday From Time is required")
    .typeError("Invalid time format").nullable(),
  satTo: Yup.date()
    .required("saturday To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "saturday To Time must be greater than saturday From Time",
      function (value) {
        const { satFrom } = this.parent;
        if (!satFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(satFrom).add(1, 'minute'));
      }
    ).nullable(),
  sunFrom: Yup.date()
    .required("Sunday From Time is required")
    .typeError("Invalid time format").nullable(),
  sunTo: Yup.date()
    .required("Sunday To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Sunday To Time must be greater than Sunday From Time",
      function (value) {
        const { sunFrom } = this.parent;
        if (!sunFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(sunFrom).add(1, 'minute'));
      }
    ).nullable(),
    averageTime: Yup.string().required("Average time to ship order is Required").trim()
});
// Customizable Area End
