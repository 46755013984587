export const enFooter = {
  "HELP": "HELP",
  "SHOP": "SHOP",
  "Women": "Women",
  "Men": "Men",
  "Kids": "Kids",
  "Stores": "Stores",
  "Stylist's": "Stylist's",
  "Customer Service": "Customer Service",
  "FAQ": "FAQ",
  "Terms Of Use": "Terms Of Use",
  "Privacy Policy": "Privacy Policy",
  "Shipping Policy": "Shipping Policy",
  "Return Policy": "Return Policy",
  "About Fash": "About Fash",
  "Fash": "Fash",
  "Log in to view customer service.": "Log in to view customer service.",
  "Log in to view stylists.": "Log in to view stylists.",
  "View list of stylists": "View list of stylists",
  "Log In or Sign up": "Log in or Sign up",
  "Sign in first !!": "Sign in first !!",
  "Access customer service options.": "Access customer service options.",
  "Home": "Home",
};

export const arFooter = {
  "Home": "الصفحة الرئيسية",
    "HELP": "يساعد",
    "SHOP": "محل",
    "Women": "امرأة",
    "Men": "رجل",
    "Kids": "أطفال",
    "Stores": "محل",
    "Stylist's": "المصمم",
    "Customer Service": "خدمة العملاء",
    "FAQ": "التعليمات",
    "Terms Of Use": "شروط الاستخدام",
    "Privacy Policy": "سياسة الخصوصية",
    "Shipping Policy": "سياسة الشحن",
    "Return Policy": "سياسة العائدات",
    "About Fash": "عن فاش",
    "Fash": "فاش",
    "Log in to view customer service.": "قم بتسجيل الدخول لعرض خدمة العملاء.",
    "Log in to view stylists.": "قم بتسجيل الدخول لعرض المصممين.",
    "View list of stylists": "عرض قائمة المصممون",
    "Log In or Sign up": "تسجيل الدخول أو الاشتراك",
    "Sign in first !!": "تسجيل الدخول أولا!!",
    "Access customer service options.": "الوصول إلى خيارات خدمة العملاء."
  };