import React from "react";

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CircularProgress from '@material-ui/core/CircularProgress';
// Customizable Area End

import MobileZylerController, {
  Props,
} from "./MobileZylerController";

export default class MobileZyler extends MobileZylerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box id="topId" style={webStyle.mainContainerStyle}>
        {this.state.isLoading && <CircularProgress style={{ color: '#375280' }} />}
        <span  className="legend zyler-try-on-cta"  ref={this.state.buttonRef} data-outfit-handle={this.state.zylerOutfitId} data-testid="TryitOut">{""}</span >
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    mainContainerStyle: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

// Customizable Area End
