import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import {
  Grid,
  styled,
  Box,
  Typography,
  Button,
  Dialog,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import SellerStoreProfileController, {
  btnData,
  configJSON,
  Props,
} from "./SellerStoreProfileController";
import {
  backBtnIcon,
  crossIcon,
  editFillUploadIcon,
  rightArrowIcon,
  selectEndIcon,
  uploadIcon,
} from "./assets";
import { AntSwitch } from "./SellerStore.web";
import {
  averageDeliveryModes,
  FormError,
  FormErrorTouched,
  step3Schema,
} from "./CreateSellerStoreController";
import { Formik } from "formik";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Step3Title, TimePickerContainer } from "./CreateSellerStore.web";
import ReusableSnackbar from "../../../components/src/AlertContainer";
import Loader from "../../../components/src/LoaderContainer";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class SellerStoreProfile extends SellerStoreProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  generateAddress = (): string => {
    // Destructure properties from the attributes object
    const { area = "", block = "", mall_name = "", floor = "", city = "", zipcode = "" } = this.state.storeIdResp.attributes;
  
    // Filter out any empty strings, and join with ", "
    return [area, block, mall_name, floor, city, zipcode]
      .filter((part) => part !== "")
      .join(", ");
  };

  selectIconRender = () => (
    <img
      src={selectEndIcon}
      alt="dropdown"
      style={{
        paddingRight: 10,
        position: "absolute",
        right: 0,
        zIndex: 1,
        pointerEvents: "none",
      }}
      data-test-id="select-drop-down-img"
    />
  );

  renderTimeDialog = () => (
    <Formik
      initialValues={this.state.initialTime}
      validationSchema={step3Schema}
      enableReinitialize
      onSubmit={this.handleTimeSubmit}
      data-test-id="stepper-3-form-data"
    >
      {({
        errors,
        getFieldProps,
        touched,
        handleSubmit,
        setFieldValue,
        values,
      }) => {
        const handleDateChange = (value: Date | null, field: string) => {
          setFieldValue(field, value ? value.toISOString() : "");
        };
        return (
          <form data-test-id="time-update-form" onSubmit={handleSubmit}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container className="main-grid-container" spacing={2}>
                <React.Fragment key={"mon"}>
                  <Grid item xs={2}>
                    <Step3Title>{this.capitalizeFirstLetter("mon")}</Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer >
                      <TimePicker
                        {...getFieldProps("monFrom")}
                        margin="normal"
                        variant="dialog"
                       
                        placeholder={this.transStoreProfile(configJSON.placeholderFrom)}
                        value={this.valueTime(values.monFrom)}
                        error={
                          this.getErrorAndHelperText(
                            "monFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        onChange={(time) => {
                          handleDateChange(time, "monFrom");
                        }}
                        helperText={
                          this.getErrorAndHelperText(
                            "monFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="mon-from-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("monTo")}
                        margin="normal"
                        placeholder={this.transStoreProfile(configJSON.placeholderTo)}
                        variant="dialog"
                        value={this.valueTime(values.monTo)}
                        onChange={(time) => {
                          handleDateChange(time, "monTo");
                        }}
                        error={
                          this.getErrorAndHelperText(
                            "monTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "monTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="mon-to-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"tue"}>
                  <Grid item xs={2}>
                    <Step3Title>{this.capitalizeFirstLetter("tue")}</Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("tueFrom")}
                        margin="normal"
                        variant="dialog"
                        placeholder={this.transStoreProfile(configJSON.placeholderFrom)}
                        onChange={(time) => handleDateChange(time, "tueFrom")}
                        error={
                          this.getErrorAndHelperText(
                            "tueFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        value={this.valueTime(values.tueFrom)}
                        helperText={
                          this.getErrorAndHelperText(
                            "tueFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="tue-from-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("tueTo")}
                        margin="normal"
                        variant="dialog"
                        placeholder={this.transStoreProfile(configJSON.placeholderTo)}
                        value={this.valueTime(values.tueTo)}
                        onChange={(time) => handleDateChange(time, "tueTo")}
                        error={
                          this.getErrorAndHelperText(
                            "tueTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "tueTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="tue-to-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"wed"}>
                  <Grid item xs={2}>
                    <Step3Title>{this.capitalizeFirstLetter("wed")}</Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("wedFrom")}
                        margin="normal"
                        placeholder={this.transStoreProfile(configJSON.placeholderFrom)}
                        error={
                          this.getErrorAndHelperText(
                            "wedFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        onChange={(time) => handleDateChange(time, "wedFrom")}
                        data-test-id="wed-from-date-test"
                        helperText={
                          this.getErrorAndHelperText(
                            "wedFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        value={this.valueTime(values.wedFrom)}
                        variant="dialog"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("wedTo")}
                        variant="dialog"
                        value={this.valueTime(values.wedTo)}
                        onChange={(time) => handleDateChange(time, "wedTo")}
                        helperText={
                          this.getErrorAndHelperText(
                            "wedTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        placeholder={this.transStoreProfile(configJSON.placeholderTo)}
                        error={
                          this.getErrorAndHelperText(
                            "wedTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        margin="normal"
                        data-test-id="wed-to-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"thu"}>
                  <Grid item xs={2}>
                    <Step3Title>{this.capitalizeFirstLetter("thu")}</Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("thuFrom")}
                        placeholder={this.transStoreProfile(configJSON.placeholderFrom)}
                        onChange={(time) => handleDateChange(time, "thuFrom")}
                        variant="dialog"
                        value={this.valueTime(values.thuFrom)}
                        error={
                          this.getErrorAndHelperText(
                            "thuFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        margin="normal"
                        data-test-id="thu-from-date-test"
                        helperText={
                          this.getErrorAndHelperText(
                            "thuFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("thuTo")}
                        variant="dialog"
                        value={this.valueTime(values.thuTo)}
                        margin="normal"
                        onChange={(time) => handleDateChange(time, "thuTo")}
                        error={
                          this.getErrorAndHelperText(
                            "thuTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        placeholder={this.transStoreProfile(configJSON.placeholderTo)}
                        helperText={
                          this.getErrorAndHelperText(
                            "thuTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="thu-to-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"fri"}>
                  <Grid item xs={2}>
                    <Step3Title>{this.capitalizeFirstLetter("fri")}</Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("friFrom")}
                        margin="normal"
                        error={
                          this.getErrorAndHelperText(
                            "friFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        data-test-id="fri-from-date-test"
                        variant="dialog"
                        placeholder={this.transStoreProfile(configJSON.placeholderFrom)}
                        helperText={
                          this.getErrorAndHelperText(
                            "friFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        value={this.valueTime(values.friFrom)}
                        onChange={(time) => handleDateChange(time, "friFrom")}
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("friTo")}
                        margin="normal"
                        variant="dialog"
                        data-test-id="fri-to-date-test"
                        placeholder={this.transStoreProfile(configJSON.placeholderTo)}
                        value={this.valueTime(values.friTo)}
                        onChange={(time) => handleDateChange(time, "friTo")}
                        error={
                          this.getErrorAndHelperText(
                            "friTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "friTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"sat"}>
                  <Grid item xs={2}>
                    <Step3Title>{this.capitalizeFirstLetter("sat")}</Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("satFrom")}
                        margin="normal"
                        placeholder={this.transStoreProfile(configJSON.placeholderFrom)}
                        onChange={(time) => handleDateChange(time, "satFrom")}
                        error={
                          this.getErrorAndHelperText(
                            "satFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        value={this.valueTime(values.satFrom)}
                        variant="dialog"
                        helperText={
                          this.getErrorAndHelperText(
                            "satFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="sat-from-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("satTo")}
                        margin="normal"
                        data-test-id="sat-to-date-test"
                        onChange={(time) => handleDateChange(time, "satTo")}
                        helperText={
                          this.getErrorAndHelperText(
                            "satTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        variant="dialog"
                        placeholder={this.transStoreProfile(configJSON.placeholderTo)}
                        value={this.valueTime(values.satTo)}
                        error={
                          this.getErrorAndHelperText(
                            "satTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"sun"}>
                  <Grid item xs={2}>
                    <Step3Title>{this.capitalizeFirstLetter("sun")}</Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("sunFrom")}
                        variant="dialog"
                        error={
                          this.getErrorAndHelperText(
                            "sunFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        data-test-id="sun-from-date-test"
                        placeholder={this.transStoreProfile(configJSON.placeholderFrom)}
                        onChange={(time) => handleDateChange(time, "sunFrom")}
                        margin="normal"
                        helperText={
                          this.getErrorAndHelperText(
                            "sunFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        value={this.valueTime(values.sunFrom)}
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("sunTo")}
                        onChange={(time) => handleDateChange(time, "sunTo")}
                        margin="normal"
                        placeholder={this.transStoreProfile(configJSON.placeholderTo)}
                        data-test-id="sun-to-date-test"
                        error={
                          this.getErrorAndHelperText(
                            "sunTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        value={this.valueTime(values.sunTo)}
                        helperText={
                          this.getErrorAndHelperText(
                            "sunTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        variant="dialog"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <Typography className={"inputHeadingClass"}>
                  {this.transStoreProfile("Average time to ship order")}
                </Typography>
                <FormControl
                  className="finance-status"
                  fullWidth
                  error={touched.averageTime && Boolean(errors.averageTime)}
                >
                  <Select
                  {...getFieldProps("averageTime")}
                    id="averageTime"
                    IconComponent={this.selectIconRender}
                    className="inputFeild2"
                    style={{
                      backgroundColor: "#F0F0F0",
                      marginTop: 5,
                      borderRadius: 2,
                      paddingRight: i18n.dir() === "rtl" ? "10px" : 0,
                      paddingLeft: i18n.dir() === "rtl" ? "10px" : 0
                    }}
                    MenuProps={{
                      style: { minWidth: "180px", height: "320px" },
                    }}
                    variant="outlined"
                    fullWidth
                    placeholder={this.transStoreProfile(`Select Average Delivery Time`)}
                    data-test-id="average-time-date-test"
                    displayEmpty
                     dir={i18n.dir()}
                  >
                    <MenuItem dir={i18n.dir()} disabled value="">
                      <span>{this.transStoreProfile(`Select Average Delivery Time`)}</span>
                    </MenuItem>
                    {averageDeliveryModes.map(({ label, value }) => (
                      <MenuItem 
                      dir={i18n.dir()}
                        key={value}
                        style={{
                          color: "#375280",
                          fontFamily: "Lato, sans-serif",
                          fontSize: 16,
                          textAlign: "inherit"
                        }}
                        value={value}
                      >
                        <ListItemText primary={this.transStoreProfile(label)} />
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.averageTime && errors.averageTime && (
                    <FormHelperText>{this.transStoreProfile(errors.averageTime)}</FormHelperText>
                  )}
                </FormControl>
                <Box className="btn-container">
                  <Button
                    variant="outlined"
                    onClick={() => this.handleTimeClose()}
                    className={"back-btn"}
                    disableElevation
                    data-test-id="back-icon-test-id"
                  >
                    {this.transStoreProfile(configJSON.back)}
                  </Button>
                  <Button
                    variant="contained"
                    className={"next-btn"}
                    disableElevation
                    type="submit"
                  >
                    {this.transStoreProfile(configJSON.update)}
                  </Button>
                </Box>
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        );
      }}
    </Formik>
  );

  renerRotateStyle = () : React.CSSProperties => {
    return {
       transform: i18n.dir() === "rtl" ? "rotate(180deg)" : "rotate(0)"
    }
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Grid container className="grid-seller-store-profile" dir={i18n.dir()}>
          <Grid
            item
            xl={2}
            md={3}
            className="sidebar-seller-store-profile"
            lg={2}
            sm={3}
            xs={3}
          >
            <SellerSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid
            item
            xl={10}
            md={9}
            className="header-seller-store-profile"
            lg={10}
            sm={9}
            xs={9}
          >
            <SellerHeader navigation={this.props.navigation} />
            <SellerStoreProfileContainer>
              <Box className="header-container">
                <Box className="title-container">
                  <img
                    src={backBtnIcon}
                    data-test-id="back-btn-test-id"
                    alt="backIcon"
                    style={this.renerRotateStyle()} 
                    onClick={() => this.handleRedirect("SellerStore")}
                  />
                  <Typography className="title">{this.transStoreProfile("Store Profile")}</Typography>
                </Box>
                <Box className="btn-container">
                  <Button
                    variant="outlined"
                    className={"back-btn"}
                    disableElevation
                    data-test-id="manage-timings-test-id"
                    onClick={() => this.handleTimeClose()}
                  >
                    {this.transStoreProfile("Manage Timings")}
                  </Button>
                  <Button
                    variant="contained"
                    className={"next-btn"}
                    disableElevation
                    data-test-id="redirect-edit-test"
                    onClick={() => this.handleRedirect("EditSellerStore")}
                  >
                    {this.transStoreProfile("Edit Details")}
                  </Button>
                </Box>
              </Box>
              {this.state.isLoading ? <Loader id="seller-store-profile-loader" dataTestId="seller-store-profile-loader-test" /> :<> <Box className="store-profile-image-container">
                <Box className="store-img-url-container">
                  <Box className="store-profile-image">
                    <img
                      className="store-profile-img"
                      src={this.state.storeIdResp?.attributes?.image}
                      alt="img"
                    />
                    <img
                      className="store-profile-edit-img"
                      style={{
                        right: i18n.dir() === "ltr" ? 0 : 'auto',
                        left: i18n.dir() === "rtl" ? 0 : 'auto',
                      }}
                      src={editFillUploadIcon}
                      alt="edit"
                      onClick={() => this.handleEditClose()}
                      data-test-id="upload-img-icon"
                    />
                  </Box>

                  <Box className="right-container">
                    <Typography className="store-name-title">
                      {this.state.storeIdResp?.attributes?.store_name}
                    </Typography>
                    <Box className="open-close-store-container">
                      <Box
                        className={`${
                          this.state.storeIdResp?.attributes?.is_open
                            ? "Open"
                            : "Close"
                        }-container`}
                      >
                        {this.state.storeIdResp?.attributes?.is_open
                          ? this.transStoreProfile("Open")
                          : this.transStoreProfile("Close")}
                      </Box>
                      {this.state?.storeIdResp?.attributes && (
                        <AntSwitch
                          checked={this.state?.storeIdResp?.attributes?.is_open}
                          name="checked"
                          data-test-id={`ant-switch-${this.state?.storeIdResp?.id}`}
                          onChange={() =>
                            this.handleStoreStatusUpdate(
                              this.state?.storeIdResp?.id,
                              this.state.storeIdResp?.attributes?.is_open
                            )
                          }
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
                <Grid container spacing={2}>
                  {btnData.map((btn: {label: string, path: string}) => (
                    <Grid item>
                      <Box className="seller-profile-show-btn" data-test-id={`data-test-${btn.path}`} onClick={()=>this.handleRedirect(btn.path)}>
                        <Typography className="seller-profile-btn-title">
                          {this.transStoreProfile(btn.label)}
                        </Typography>
                        <img src={rightArrowIcon} alt="right-icon" 
                          style={this.renerRotateStyle()} 
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box className="store-profile-description" dir={i18n.dir()}>
                <Typography className="description-title" dir={i18n.dir()}>
                  {this.transStoreProfile("Store Details")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box className="detail-container">
                      <Typography className="detail-title">
                        {this.transStoreProfile("Store Description")}
                      </Typography>
                      <Typography className="detail-description" dir={i18n.dir()}>
                        {this.state.storeIdResp?.attributes?.description}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Box className="detail-container">
                      <Typography className="detail-title">{this.transStoreProfile("Address")}</Typography>
                      <Typography className="detail-description" dir={i18n.dir()}>
                      {this.state.storeIdResp?.attributes?.block} {this.state.storeIdResp?.attributes?.address}, {this.state.storeIdResp?.attributes?.city}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Box className="detail-container">
                      <Typography className="detail-title">
                        {" "}
                        {this.transStoreProfile("Instructions for driver to reach store")}
                      </Typography>
                      <Typography className="detail-description" dir={i18n.dir()}>
                        {this.state.storeIdResp?.attributes?.driver_instruction}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Box className="detail-container">
                      <Typography className="detail-title">
                        {" "}
                        {this.transStoreProfile("Contact Number")}
                      </Typography>
                      <Typography className="detail-description" dir={i18n.dir()}>
                        {`${this.state.storeIdResp?.attributes?.contact_number?.country_code} ${this.state.storeIdResp?.attributes?.contact_number?.phone_number}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Box className="detail-container">
                      <Typography className="detail-title"> {this.transStoreProfile("Email")}</Typography>
                      <Typography className="detail-description" dir={i18n.dir()}>
                        {this.state?.storeIdResp?.attributes?.email}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box className="detail-container">
                      <Typography className="detail-title">
                        {" "}
                        {this.transStoreProfile("Store Hours")}
                      </Typography>
                      {this.state.operatingHours?.map((hours, index) => (
                        <Typography key={index} dir={i18n.dir()} className="detail-description">
                          {hours}
                        </Typography>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              </>}
            </SellerStoreProfileContainer>
            <DialogEditContainer
              data-test-id="upload-store-img-dialog"
              open={this.state.isImageDialog}
              onClose={() => this.handleEditClose()}
              dir={i18n.dir()}
            >
              <Box className="content-container">
                <Typography className="text-container">
                  {this.transStoreProfile("Edit Store Profile")}
                </Typography>
                <form data-test-id="formSubmit" onSubmit={this.handleSubmit}>
                  {!this.state.storeProfile.previewUrl ? (
                    <Button
                      variant="contained"
                      component="label"
                      className="upload-btn"
                    >
                      <img src={uploadIcon} alt="upload" />
                      <Typography className="upload-title">
                        {this.transStoreProfile("Upload Store Profile Image")}
                      </Typography>
                      <input
                        type="file"
                        hidden
                        data-test-id="upload-image-test"
                        onChange={this.handleFileChange}
                        accept=".png, .jpg, .jpeg"
                      />
                    </Button>
                  ) : (
                    <Box
                      className="image-preview-box"
                      style={{ position: "relative", marginTop: "16px" }}
                    >
                      <img
                        src={this.state.storeProfile.previewUrl}
                        alt="Uploaded Profile"
                        className="image-preview"
                        style={{
                          width: "100%",
                          height: 240,
                          objectFit: "cover",
                        }}
                      />
                      <Button
                        variant="contained"
                        component="label"
                        className="edit-btn"
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "0px",
                          background: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <img src={editFillUploadIcon} alt="edit" />
                        <input
                          type="file"
                          data-test-id="upload-image-test"
                          hidden
                          onChange={this.handleFileChange}
                          accept=".png, .jpg, .jpeg"
                        />
                      </Button>
                    </Box>
                  )}
                  <FormHelperText
                    data-test-id="upload-img-error"
                    style={{ color: "#f44336" }}
                  >
                    {this.state.errorsMessage.errorStoreUpload
                      ? this.state.errorsMessage.errorStoreUpload
                      : ""}
                  </FormHelperText>
                  <Button
                    variant="contained"
                    className={"upload-btn"}
                    disableElevation
                    type="submit"
                  >
                    {this.transStoreProfile("Upload")}
                  </Button>
                </form>
              </Box>
            </DialogEditContainer>
            <DialogTimeContainer
              data-test-id="update-store-time-dialog"
              open={this.state.isTimeDialogOpen}
              onClose={() => this.handleTimeClose()}
              dir={i18n.dir()}
            >
              <Box className="cross-icon-container">
                <img
                  src={crossIcon}
                  onClick={() => this.handleTimeClose()}
                  alt="cross-icon"
                  data-test-id="cross-icon-test"
                />
              </Box>
              <Box className="content-container">
                <Typography className="title-container">
                  {this.transStoreProfile("Manage Timings")}
                </Typography>
                {this.renderTimeDialog()}
              </Box>
            </DialogTimeContainer>
          </Grid>
        </Grid>
        <ReusableSnackbar
          onClose={this.oncloseAlert}
          open={this.state.alertState.isAlert}
          severity={this.state.alertState.severity}
          message={this.state.alertState.message}
          dataTestId="alertTestId"
          autoHideDuration={3000}
          dir={i18n.dir()}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const SellerStoreProfileContainer = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
  "& .header-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 44,
    "& .title-container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 20,
      height: "100%",
      "& .title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 700,
        fontSize: 24,
        color: "#375280",
      },
    },
    "& .btn-container": {
      display: "flex",
      gap: 16,
      height: "100%",
      "& .back-btn": {
        textTransform: "capitalize",
        color: "#375280",
        borderColor: "#CCBEB1",
        height: "100%",
        "& .MuiButton-label": {
          fontSize: "18px",
          fontWeight: 500,
        },
      },
      "& .next-btn": {
        textTransform: "capitalize",
        color: "white",
        gap: "8px",
        backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
        height: "100%",
        borderRadius: "2px",
        "& .MuiButton-label": {
          fontSize: "18px",
          fontWeight: 600,
        },
      },
    },
    "@media(max-width:800px)": {
      height: "max-content",
      "& .title-container": {
        "& .title": {
          fontWeight: 600,
          fontSize: "18px !important",
        },
      },
      "& .btn-container": {
        flexDirection: "column",
        gap: 4,
        height: "max-content",
        "& .back-btn": {
          height: "44px !important",
          "& .MuiButton-label": {
            fontSize: "16px !important",
          },
        },
        "& .next-btn": {
          height: "44px !important",
          "& .MuiButton-label": {
            fontSize: "16px !important",
          },
        },
      },
    },
  },
  "& .store-profile-image-container": {
    padding: 20,
    border: "1px solid #E2E8F0",
    marginTop: 20,
    "& .store-img-url-container": {
      display: "flex",
      alignItems: "center",
      gap: 20,
      "@media(max-width:450px)": {
        flexDirection: "column",
      },
      "& .store-profile-image": {
        display: "flex",
        gap: 20,
        position: "relative",
        width: 100,
        "& .store-profile-img": {
          borderRadius: "50%",
          width: 100,
          aspectRatio: 1,
        },
        "& .store-profile-edit-img": {
          position: "absolute",
          bottom: 0,
          right: 0,
          width: 30,
          aspectRatio: 1,
        },
      },
      "& .right-container": {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        "& .store-name-title": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 400,
          fontSize: 20,
          color: "#375280",
        },
        "& .open-close-store-container": {
          display: "flex",
          gap: 15,
          "& .Open-container": {
            width: "max-content",
            radius: 2,
            padding: "3px 14px 3px 14px",
            fontWeight: 500,
            backgroundColor: "#E2E8F0",
            fontSize: 16,
            fontFamily: "Lato, sans-serif",
            color: "#375280",
          },
          "& .Close-container": {
            radius: 2,
            width: "max-content",
            padding: "3px 14px 3px 14px",
            fontFamily: "Lato, sans-serif",
            color: "#DC2626",
            backgroundColor: "#FEE2E2",
            fontWeight: 500,
            fontSize: 16,
          },
        },
      },
    },
    "& .MuiGrid-root.MuiGrid-container": {
      paddingTop: 20,
      "& .MuiGrid-root.MuiGrid-item": {
        "& .seller-profile-show-btn": {
          borderRadius: 2,
          padding: "13px 20px 13px 20px",
          backgroundColor: "#F1F5F9",
          display: "flex",
          gap: 12,
          "& .seller-profile-btn-title": {
            fontFamily: "Lato, sans-serif",
            fontWeight: 500,
            fontSize: 16,
            color: "#375280",
          },
          "&:hover": {
            border: "1px solid #375280",
            "& .seller-profile-btn-title": {
              fontWeight: 700,
            },
          },
        },
      },
    },
  },
  "& .store-profile-description": {
    padding: 20,
    border: "1px solid #E2E8F0",
    marginTop: 20,
    "& .description-title": {
      fontFamily: "Lato, sans-serif",
      fontWeight: 700,
      fontSize: 20,
      color: "#375280",
    },
    "& .MuiGrid-root.MuiGrid-container": {
      paddingTop: 20,
      "& .MuiGrid-root.MuiGrid-item": {
        "& .detail-container": {
          display: "flex",
          flexDirection: "column",
          gap: 12,
          "& .detail-title": {
            fontFamily: "Lato, sans-serif",
            fontWeight: 700,
            fontSize: 18,
            color: "#375280",
          },
          "& .detail-description": {
            fontFamily: "Lato, sans-serif",
            fontWeight: 400,
            fontSize: 18,
            color: "#375280",
          },
          "& .detail-description.padding-bottom": {
            paddingBottom: 10,
          },
        },
      },
    },
  },
});

export const DialogEditContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "30px",
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      justifyContent: "center",
      "& .text-container": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 500,
        fontSize: 22,
        color: "#375280",
      },
    },
    "& .upload-btn": {
      paddingTop: 10,
      marginTop: 20,
      textTransform: "capitalize",
      color: "white",
      gap: "8px",
      backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
      width: "100%",
      height: "100%",
      borderRadius: "2px",
      "& .MuiButton-label": {
        fontSize: "18px",
        fontWeight: 600,
      },
    },
    "@media(max-width:520px)": {
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
    "@media(max-width:700px)": {
      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },
  },
});

export const DialogTimeContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    "& .cross-icon-container": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .content-container": {
      padding: "0px 30px 30px 30px",
      "& .title-container": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 500,
        fontSize: 30,
        display: "flex",
        alignItems: "center",
        color: "#375280",
        justifyContent: "center",
        paddingBottom: 30,
      },
      "& .inputHeadingClass": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        paddingTop: 26,
        textOverflow: "ellipsis",
        fontFamily: "Lato, sans-serif",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
      },
      "& .inputFeild2": {
        
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error": {
          border: "1px solid #f44336",
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            color: "#f44336",
          },
        },
        "& .MuiTextField-root": {
          "&:hover": {
            border: "1px solid #F0F0F0 !important",
          },
        },
        "& .MuiFormHelperText-contained": {
          margin: "0 !important",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root": {
          backgroundColor: "#F0F0F0",
          borderRadius: "2px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F0F0F0",
        },
        "& .MuiInputBase-input": {
          color: "#375280",
        },
      },
      "& .btn-container": {
        display: "flex",
        gap: 16,
        height: "54px",
        width: "100%",
        marginTop: 30,
        "& .back-btn": {
          textTransform: "capitalize",
          color: "#375280",
          borderColor: "#CCBEB1",
          width: "50%",
          height: "100%",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 500,
          },
        },
        "& .next-btn": {
          textTransform: "capitalize",
          color: "white",
          gap: "8px",
          backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
          width: "50%",
          height: "100%",
          borderRadius: "2px",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 600,
          },
        },
      },
      "@media(max-width:520px)": {
        padding: 10,
        "& .inputHeadingClass": {
          padding: 10,
          paddingTop: 26,
        },
        "& .MuiFormControl-root.finance-status": {
          padding: 10,
        },
      },
    },
  },
});
// Customizable Area End
