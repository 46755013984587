// Customizable Area Start
import React from "react"
import {
    Button,
    Dialog,
    DialogContent,
    Box,
    Typography,
    styled
} from "@material-ui/core";
import { BlockComponent } from "../../framework/src/BlockComponent";
import i18n from "./i18next/i18n";
import { getStorageData, removeStorageData, setStorageData } from "../../framework/src/Utilities";

interface IModalState {
    token: boolean 
}
export interface Props {
    navigation?: any;
    id?: string;
    auth: string;
    title: string;
    description?: string;
    btnText: string;
    onClose: () => void;
}

interface SS {
    id: string;
}

export default class AuthenticateUser extends BlockComponent<Props, IModalState, SS> {
    constructor(props: Props) {
        super(props);
        this.state = {
          token: !!this.props.auth 
        };
      }

      async componentDidMount(){
        removeStorageData('storedPath')
      }
    
      headerRedirect = (block: string) => {
        this.setState({ token: true }, async () => {
          await setStorageData('storedPath', document.location.href)
          this.props.onClose();
          this.props.navigation.navigate(block);
        });
      };

      translateLang = (key: string) => {
        return i18n.t(key, { ns: "header" } )
    }

    render() {

        return <Dialog open={!this.state.token} onClose={this.props.onClose}>
            <ModalWrapper>
                <Typography className="title">{this.props.title}</Typography>
                <DialogContent className="description" style={{ display: this.props.description ? "block" : "none"}}>{this.props.description || ""}</DialogContent>
                <Button className="btn" onClick={() => {
                    this.headerRedirect("EmailAccountLoginBlock")
                }}>{this.translateLang(this.props.btnText)}</Button>
            </ModalWrapper>
        </Dialog>
    }

}
const ModalWrapper = styled(Box)({
    width: "380px",
    padding: "25px",
    "& .title": {
        fontFamily: 'Lato, sans-serif',
        fontWeight: 800,
        fontSize: '20px',
        lineHeight: '26px',
        textAlign: 'center',
        color: '#375280',
    },
    "& .description": {
        fontFamily: 'Lato, sans-serif',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: '8px',
        textAlign: 'center',
        color: '#94A3B8'
    },
    "& .btn": {
        fontFamily: 'Lato, sans-serif',
        borderRadius: '2px',
        backgroundColor: '#CCBEB1',
        fontWeight: 700,
        fontSize: '20px',
        marginBottom: '6px',
        letterSpacing: '1%',
        color: '#FFFFFF',
        width: '100%',
        marginTop: '22px',
        textTransform: 'inherit',
        padding: '10px',
        cursor: "pointer"
    }
})
// Customizable Area End
