export const enLoginSignup = {
    "Welcome Back":	"Welcome Back",
    "Login and see our latest fashion style":	"Login and see our latest fashion style",
    "Email Address": "Email Address",
    "Enter your email": "Enter your email",
    "Password":	"Password",
    "Enter your password": "Enter your password",
    "Forgot your Password?": "Forgot your Password?",
    "Log In":	"Log In",
    "or continue with":	"or continue with",
    "Don't have an account or want to become a seller, or stylist?": "Don't have an account or want to become a seller, or stylist?",
    "Register Here": "Register Here",
    "Forgot Password": "Forgot Password",
    "Enter the email address associated with your account": "Enter the email address associated with your account",
    "Enter your associated email adress": "Enter your associated email adress",
    "OR": "OR",
    "Phone Number": "Phone Number",
    "Enter Phone Number": "Enter Phone Number",
    "Reset Password": "Reset Password",
    "Account not found":	"Account not found",
    "*Please enter a valid phone number": "*Please enter a valid phone number",
    "*Please enter a valid email address": "*Please enter a valid email address",
    "*Please enter your email address": "*Please enter your email address",
    "Please enter your phone number": "Please enter your phone number",
    "*Phone number must contain only numeric values": "*Phone number must contain only numeric values",
    "Please enter your new password": "Please enter your new password",
    "*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric": "*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric",
    "*Please enter new your password": "*Please enter new your password",
    "OTP Verification": "OTP Verification",
    "Didn't Received Code?": "Didn't Received Code?",
    "Resend Code": "Resend Code",
    "Verify Account": "Verify Account",
    "Confirm New Password": "Confirm New Password",
    "Enter New Password": "Enter New Password",
    "Enter your new password": "Enter your new password",
    "Enter your new confirm password": "Enter your new confirm password",
    "*Confirm New Password must be same as New Password": "*Confirm New Password must be same as New Password",
    "Password reset successfully!": "Password reset successfully!",
    "New Password is already in use, Please enter another new password!": "New Password is already in use, Please enter another new password!",
    "* Enter 4 digit OTP": "* Enter 4 digit OTP",
    "Please enter the 4 digit code sent to": "Please enter the 4 digit code sent to",
    "your email id": "your email id",
    "your mobile number id": "your mobile number id",
    "Message Recived": "Message Recived",
    "Login Successfully": "Login Successfully",
    "Registered Successfully":"Registered Successfully",
    "Internal Server Error": "Internal Server Error",
    "Full Name": "Full Name",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Re-enter Password": "Re-enter Password",
    "Create Account": "Create Account",
    "By clicking create account, you agree to our": "By clicking create account, you agree to our",
    "Terms & Conditions": "Terms & Conditions",
    "Privacy Statement.": "Privacy Statement.",
    "Login Here": "Login Here",
    "Aleady have an account?": "Aleady have an account?",
    " and ": " and ",
    "Sign up":"Sign up",
    "*Please enter a valid full name": "*Please enter a valid full name",
    "*Please enter a valid first name": "*Please enter a valid first name",
    "*Please enter a valid last name": "*Please enter a valid last name",
    "*Please enter your password": "*Please enter your password",
    "*Please re-enter your password": "*Please re-enter your password",
    "*Please enter your phone number": "*Please enter your phone number",
    "*Please accept Term and Condition and Privacy Statement.": "*Please accept Term and Condition and Privacy Statement.",
    "* Please re-enter your password": "* Please re-enter your password",
    "Enter your first name": "Enter your first name",
    "Enter your last name": "Enter your last name",
    "Re-enter your password": "Re-enter your password",
    "Enter phone Number": "Enter phone Number",
    "I have read and agree to the": "I have read and agree to the",
    "Please accept the terms & conditions and Privacy Statment": "Please accept the terms & conditions and Privacy Statment",
    "Your password must contain a minimum of 8 characters": "Your password must contain a minimum of 8 characters",
    "You have selected a different role to sign in. Please select role again.": "You have selected a different role to sign in. Please select role again.",
    "Add Phone Number":"Add Phone Number",
    "Resend OTP In":"Resend OTP In",
}
  
export const arLoginSignup = {
    "Welcome Back":	"مرحبًا بعودتك",
    "Login and see our latest fashion style":	"قم بتسجيل الدخول وشاهد أحدث صيحات الموضة لدينا",
    "Email Address": "عنوان البريد الإلكتروني",
    "Enter your email": "أدخل بريدك الإلكتروني",
    "Password":	"كلمة المرور",
    "Enter your password": "أدخل كلمة المرور الخاصة بك",
    "Forgot your Password?": "نسيت كلمة السر؟",
    "Log In":	"تسجيل الدخول",
    "or continue with":	"أو الاستمرار مع",
    "Don't have an account or want to become a seller, or stylist?": "ليس لديك حساب أو تريد أن تصبح بائعاً أو مصمماً؟",
    "Register Here": "سجل هنا",
    "Forgot Password": "هل نسيت كلمة السر",
    "Enter the email address associated with your account": "أدخل عنوان البريد الإلكتروني المرتبط بحسابك",
    "Enter your associated email adress": "أدخل عنوان البريد الإلكتروني المرتبط بك",
    "OR": "أو",
    "Phone Number": "رقم الهاتف",
    "Enter Phone Number": "أدخل رقم الهاتف",
    "Reset Password": "إعادة تعيين كلمة المرور",
    "Account not found": "لم يتم العثور على الحساب",
    "*Please enter a valid phone number": "*يرجى إدخال رقم هاتف صالح",
    "*Please enter a valid email address": "*يرجى إدخال بريد إلكتروني صالح ",
    "*Please enter your email address": "*يرجى إدخال عنوان البريد الإلكتروني الخاص بك",
    "Please enter your phone number": "الرجاء إدخال رقم هاتفك",
    "*Phone number must contain only numeric values": "*يجب أن يحتوي رقم الهاتف على قيم رقمية فقط",
    "Please enter your new password": "الرجاء إدخال كلمة المرور الجديدة",
    "*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric": "*يجب أن تكون كلمة المرور من 8 خانات على الأقل",
    "*Please enter new your password": "*الرجاء إدخال كلمة المرور الجديدة الخاصة بك",
    "OTP Verification": "التحقق من OTP",
    "Resend Code": "إعادة إرسال الرمز",
    "Verify Account": "التحقق من الحساب",
    "Confirm New Password": "تأكيد كلمة المرور الجديدة",
    "Enter New Password": "أدخل كلمة المرور الجديدة",
    "Enter your new password": "أدخل كلمة المرور الجديدة",
    "Enter your new confirm password": "أدخل تأكيد كلمة المرور الجديدة",
    "*Confirm New Password must be same as New Password": "*تأكيد كلمة المرور الجديدة يجب أن تكون نفس كلمة المرور الجديدة",
    "Password reset successfully!": "تم إعادة تعيين كلمة المرور بنجاح!",
    "New Password is already in use, Please enter another new password!": "كلمة المرور الجديدة قيد الاستخدام بالفعل، الرجاء إدخال كلمة مرور جديدة أخرى!",
    "* Enter 4 digit OTP": "* أدخل كلمة المرور لمرة واحدة المكونة من 4 أرقام",
    "Please enter the 4 digit code sent to": "الرجاء إدخال الرمز المكون من 4 أرقام المرسل إليه",
    "your email id ": "معرف البريد الإلكتروني الخاص بك ",
    "your mobile number id ": "معرف رقم هاتفك المحمول ",
    "Didn't Received Code?": "لم تتلق الرمز؟",
    "Message Recived": "تم استلام الرسالة",
    "Login Successfully": "تم تسجيل الدخول بنجاح",
    "Registered Successfully":"تم التسجيل بنجاح",
    "Internal Server Error": "خطأ في الخادم الداخلي",
    "Full Name": "الاسم الكامل",
    "First Name": "الاسم الأول",
    "Last Name": "اسم العائلة",
    "Re-enter Password": "أعد إدخال كلمة المرور",
    "Create Account": "إنشاء حساب",
    "By clicking create account, you agree to our": "بالنقر فوق إنشاء حساب، فإنك توافق على لدينا",
    "Terms & Conditions":"لشروط والأحكام ",
    "Privacy Statement.": "بيان الخصوصية",
    "Login Here": "تسجيل الدخول هنا",
    "Aleady have an account?": "هل لديك حساب مسبقا؟",
    " and ": " و ",
    "Sign up":	"اشتراك",
    "*Please enter a valid full name": "*الرجاء إدخال اسم كامل صالح",
    "*Please enter a valid first name": "*يرجى إدخال إسم أول صالح",
    "*Please enter a valid last name": "*يرجى إدخال إسم عائلة صالح",
    "*Please enter your password": "*يرجى إدخال كلمة مرور الخاصة بك",
    "*Please re-enter your password": "*الرجاء إعادة إدخال كلمة المرور الخاصة بك",
    "*Please enter your phone number": "*الرجاء إدخال رقم هاتفك",
    "*Please accept Term and Condition and Privacy Statement.": "*يرجى قبول الشروط والأحكام وبيان الخصوصية.",
    "* Please re-enter your password": "*كلمتا المرور غير متطابقتين. من فضلك أعد إدخال كلمة المرور الخاصة بك",
    "Enter your first name": "أدخل اسمك الأول",
    "Enter your last name": "أدخل اسمك الأخير",
    "Re-enter your password": "أعد إدخال كلمة المرور الخاصة بك",
    "Enter phone Number": "أدخل رقم الهاتف",
    "I have read and agree to the": "لقد قرأت ووافقت على ا",
    "Please accept the terms & conditions and Privacy Statment": "يرجى قبول الشروط والأحكام وبيان الخصوصية",
    "Your password must contain a minimum of 8 characters": "يجب أن تحتوي كلمة المرور الخاصة بك على 8 أحرف على الأقل",
    "You have selected a different role to sign/*  */ in. Please select role again.": "لقد حددت دورًا مختلفًا لتسجيل الدخول. يرجى تحديد الدور مرة أخرى.",
    "Add Phone Number":"إضافة رقم الهاتف",
    "Resend OTP In":"إعادة إرسال OTP في",
}/*  */