import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
type AlertType = 'success' | 'error' | 'warning' | 'info';
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface OrderItem {
    id: number;
    type: string;
    attributes: {
        quantity: number;
        unit_price: number;
        total_price: string;
        reason_of_rejection: string | null;
        status: string;
        catalogue_name: string;
        brand_name: string;
        catalogue_variant_color: string;
        catalogue_variant_sku: string;
        store_name: string;
        catalogue_variant_size: string;
        catalogue_variant_front_image: string;
        catalogue_variant_back_image: string;
        catalogue_variant_side_image: string;
        driver: string | null;
        applied_copon_code: string | null;
        currency_logo: string;
    };
}
interface ClothingSizes {
    [size: string]: string;
}
interface S {
    // Customizable Area Start
    sampleState: string,
    appliedCoponCode: string | null,
    cartData: { id: number, shipping_net_amt: string, sub_total: string, total: string, total_tax: string, coupon_code_id: string | null, applied_discount: string | number,applied_copon_code: string | null },
    cartItems: OrderItem[],
    alertType: AlertType,
    isAlert: boolean,
    alertMsg: string,
    countCart: number,
    errorLabale: string,
    circularProgress: boolean,
    visible: boolean;
    cardNumberId: number;
    cardIndeId: number;
    currencySign: string;
    stylishBuyer: boolean;
    isMultiDelete: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SampleController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getcategoriesId: string = "";
    getcategoriesCartId: string = "";
    getcategoriesCartRemove: string = "";
    getpromoCodeId: string = "";
    clothingSizes: ClothingSizes = {
        Small: 'S',
        Medium: 'M',
        Large: 'L',
        "Extra Small": 'XS',
        "Extra Large": 'XL',
        "Double Extra Large": 'XXL'
    }
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage)
        ];
        this.state = {
            countCart: 0,
            isAlert: false,
            sampleState: 'sample',
            cartData: { id: 0, shipping_net_amt: "", sub_total: "", total: "", total_tax: "",applied_copon_code: "", coupon_code_id: "", applied_discount: "" },
            cartItems: [],
            alertType: 'success' as AlertType,
            alertMsg: "",
            errorLabale: "",
            circularProgress: false,
            visible: false,
            cardNumberId: 0,
            cardIndeId: 0,
            appliedCoponCode: "",
            currencySign: "$", stylishBuyer: false,
            isMultiDelete: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        let isNewProduct = await getStorageData("addToCart")
        let userdetails = await getStorageData("userrole",true)
        let stylishbuyer = await getStorageData("exploreBuyer",true);
        if(stylishbuyer) this.setState({stylishBuyer: stylishbuyer})
        if(userdetails?.currencyLogo){
            this.setState({
                currencySign: userdetails?.currencyLogo
            })
        }
        if (isNewProduct === 'true') {
            this.setState({ errorLabale: "Item successfully added to your cart", isAlert: isNewProduct })
            setStorageData("addToCart", JSON.stringify(false))
        }
        this.categoreySimilar();
        removeStorageData('storedPath')
        // Customizable Area End
    }
    async componentDidUpdate() {
        let countOrderNumber = await getStorageData("orderNumber")
        if (countOrderNumber !== this.state.countCart) {
            this.setState({ countCart: countOrderNumber })
        }
    }

    // Customizable Area Start
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getcategoriesId !== null &&
            this.getcategoriesId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let cartDataItems: OrderItem[] = []
            let cartAttributeData: { id: number, shipping_net_amt: string, sub_total: string, total: string, total_tax: string, coupon_code_id: string | null,applied_copon_code: string | null, applied_discount: string | number } = { id: 0, shipping_net_amt: "", sub_total: "", total: "", total_tax: "", coupon_code_id: "", applied_discount: "",applied_copon_code: "" }
            let itemCountCart = 0
            if (responseJson && responseJson.data) {
                cartDataItems = responseJson.data.attributes.order_items
                cartAttributeData = responseJson.data.attributes
                itemCountCart = responseJson.data.attributes.order_items.length
            }
            if(cartDataItems?.length > 0){
                this.setState({
                    currencySign: cartDataItems[0]?.attributes?.currency_logo
                })
            }
            this.setState({ cartItems: cartDataItems, cartData: cartAttributeData, countCart: itemCountCart, circularProgress: false }, async () => { this.removedData() });
        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getcategoriesCartId !== null &&
            this.getcategoriesCartId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                this.categoreySimilar()
                if (responseJson.error) {
                    this.setState({ isAlert: true, alertType: "error", circularProgress: false, errorLabale: responseJson.error })
                } else {
                    this.setState({ circularProgress: false, isAlert: true, alertType: "success", errorLabale: "Quantity updated successfully" })
                }
            }
        }
        this.categoriesCartRemove(message)
        // Customizable Area End
    }
    // Customizable Area Start

    handleOpen = (acountId: number, index: number, quantity: number) => {
        this.setState({ visible: true, cardNumberId: acountId, cardIndeId: index, isMultiDelete: quantity > 1 })
    };
    handleCancel = () => {
        this.setState({ visible: false });
    };
    removedData = async () => {
        await setStorageData("orderNumber", this.state.countCart.toString());
    }
    categoriesCartRemove = (message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getcategoriesCartRemove !== null &&
            this.getcategoriesCartRemove ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.categoreySimilar()
            if (responseJson.message) {
                this.setState((prevState)=>({...prevState,isAlert: true, visible: false, errorLabale: prevState.isMultiDelete ? "Items have been removed from your cart" : "Item have been removed from your cart" }))
            }
        }
    }
    handleIncreaseQuantity = (index: number) => {
        const newCartItems = [...this.state.cartItems];
        newCartItems[index].attributes.quantity += 1;
        newCartItems[index].attributes.total_price = (newCartItems[index].attributes.unit_price * newCartItems[index].attributes.quantity + parseFloat(this.state.cartData.shipping_net_amt)).toFixed(1);
        this.setState({ cartItems: newCartItems ,circularProgress:true});
        const cardId = newCartItems[index].id;
        const quantity = newCartItems[index].attributes.quantity;
        const bodyPut = {
            "order_item_id": cardId,
            "quantity": quantity
        };
        this.categoreySimilarPut(bodyPut)
    };
    oncloseAlert = () => {
        this.setState({ isAlert: false });
    };
    handleDecreaseQuantity = (index: number, orderid: number) => {
        const newCartItems = [...this.state.cartItems];
        if (newCartItems[index].attributes.quantity === 1) {
            this.handleDelete(newCartItems[index].id, orderid);
            return;
        }
        newCartItems[index].attributes.quantity -= 1;
        newCartItems[index].attributes.total_price = (newCartItems[index].attributes.unit_price * newCartItems[index].attributes.quantity + parseFloat(this.state.cartData.shipping_net_amt)).toFixed(1);
        this.setState({ cartItems: newCartItems,circularProgress:true });
        this.categoreySimilarPut({
            "order_item_id": newCartItems[index].id,
            "quantity": newCartItems[index].attributes.quantity
        });
    };

    handleDelete = (index: number, orderId: number) => {
        const bodyRemove = {
            "order_id": orderId,
            "order_items_ids": [index]
        };
        this.categoreyRemove(bodyRemove)
    }
    categoreySimilar = async () => {
        this.setState({circularProgress:true})
        const unique_token = await getStorageData("unique_token");
        const token = await getStorageData("auth-token")
        const tokenAuthr =  token === null ? `${configJSON.getActiveCartView}=${unique_token}` :
        `${configJSON.getUnique_token}=${unique_token}`
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            unique_token: unique_token,
            token:token
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getcategoriesId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.product_cataloge
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            tokenAuthr
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }

    categoreyRemove = async (bodyRemove: { order_id: number, order_items_ids: number[] }) => {
        const unique_token = await getStorageData("unique_token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            unique_token: unique_token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getcategoriesCartRemove = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.product_addCart
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getRemove_order_items}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(bodyRemove)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }
    contioneShopping = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    proceedToCheckout = async () => {
        const userToken = await getStorageData('auth-token')
        if(!userToken) {
            await setStorageData("storedPath", document.location.href);
        }
        await setStorageData("payment-details", "CheckOut");
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Shippingaddressvalidation2");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }

    categoreySimilarPut = async (bodyPut: { order_item_id: number, quantity: number }) => {
        const unique_token = await getStorageData("unique_token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            unique_token: unique_token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getcategoriesCartId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.product_catalogeput
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getUnique_tokenPut}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(bodyPut)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }

    transPD = (pdKey: string) => {
        return i18n.t(pdKey, { ns: "shoppingCart" })
    }
    // Customizable Area End
}