import React from "react";

// Customizable Area Start
import {
  styled,
  Box, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Typography, CircularProgress,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerReadyForCollectionController, {
  Props,
  SellerOrderSeller,
  OrderItemSeller
} from "./SellerReadyForCollectionController.web";
import { Noimageavailablepng } from "./assets";
import moment from "moment";
import i18n from "../../../components/src/i18next/i18n";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class SellerReadyForCollection extends SellerReadyForCollectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handlePageChangeReadyFor = (event: React.ChangeEvent<unknown>, newPage: number) => {
    event.preventDefault()
    this.setState({ readyForPage: newPage, isLoading1: true },()=>this.getSellerOrdersProcessedApi());
};
  // Customizable Area End

  render() {
    // Customizable Area Start 
    const ROWS_PER_PAGE = 10;
        const endIndexInProcess = Math.min(this.state.metaReadyForData?.current_page * ROWS_PER_PAGE, this.state.metaReadyForData?.total_record);
        const startIndexInProcess = (this.state.metaReadyForData?.current_page - 1) * ROWS_PER_PAGE + 1;

      if (this.state.isLoadingReady) {
        return (
          <Box style={{ ...webStyle.tabsBoxReady }}>
          <CircularProgress style={webStyle.CircularProgressColor} />
        </Box>
        );
    }
    if (this.state.SellerOrdersProcessedView === undefined && this.state.isAlertReadyForCollection) {
      return (
          <Box style={{ ...webStyle.tabeLableReadyForCollection as React.CSSProperties }}>
              {this.transSellerReadyForCollection("There are no orders")}
          </Box>
      );
  }
    return (
      <ThemeProvider theme={theme}>
            <Box style={webStyle.readyForCollectionTableContainer} dir={i18n.dir()}>
                <CustomStyledTable className="container">
            <Box style={{ position: 'relative', ...webStyle.menBox, flexDirection: 'column' }}>
                    <Table stickyHeader size="small" className="custom-table">
                        <TableHead>
                            <TableRow style={webStyle.tableHeaderBg}>
                                <TableCell style={webStyle.tableHaderReady}><Typography className="seller-ready-for-collection-head-typo" variant="h6">{this.transSellerReadyForCollection("Order ID")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHaderReady}><Typography className="seller-ready-for-collection-head-typo" variant="h6">{this.transSellerReadyForCollection("Order Time")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHaderReady}><Typography className="seller-ready-for-collection-head-typo" variant="h6">{this.transSellerReadyForCollection("Products")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHaderReady}><Typography className="seller-ready-for-collection-head-typo" variant="h6">{this.transSellerReadyForCollection("Shop")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHaderReady}><Typography className="seller-ready-for-collection-head-typo" variant="h6">{this.transSellerReadyForCollection("Total Price")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHaderReady}><Typography className="seller-ready-for-collection-head-typo" variant="h6">{this.transSellerReadyForCollection("Status")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHaderReady}><Typography className="seller-ready-for-collection-head-typo" variant="h6">{this.transSellerReadyForCollection("Action")}</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ height: '100px', position: 'relative' }}>
                                {this.state.isLoading1 ?
                                    <Box data-testid="loader" style={{position: 'absolute',...webStyle.loderBox}}>
                                        <CircularProgress style={{ color: '#375280' }} />
                                    </Box> : this.state.SellerOrdersProcessedView?.map((sellerData : SellerOrderSeller, index: number) => (
                              <React.Fragment key={index}>
                                <CustomTableRow>
                                  <TableCell colSpan={7}>
                                  <Box style={webStyle.ArrivingCell}>
                                    <Typography variant="body1" style={webStyle.ArrivingText as React.CSSProperties}>{this.transSellerReadyForCollection("Anderson")}: <span style={webStyle.ArrivingTextValue}>{this.transSellerReadyForCollection("Arriving in")} 14 {this.transSellerReadyForCollection("mins")}</span></Typography>
                                 </Box>
                                  </TableCell>
                                </CustomTableRow>
                                <TableRow key={sellerData.id}>
                                    <TableCell style={{ ...webStyle.tableHaderrowReady }}><Typography className="seller-ready-for-collection-body-typo">#{sellerData.attributes.order_management_order.attributes.order_number}</Typography></TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowReady }}><Typography className="seller-ready-for-collection-body-typo">{moment(sellerData.attributes.order_items[0].attributes.placed_at).format("hh:mm A")}</Typography></TableCell>
                                  <TableCell data-testid="navigateToReadyForDetail" onClick={() => this.navigateToReadyForDetail({ productDetailId: sellerData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "SellerReadyForCollectionDetail" })}
>
                                    {sellerData.attributes.order_items.map((items : OrderItemSeller) => (
                                      <Box style={webStyle.productDflex}>
                                      <Box><img src={items.attributes.catalogue_variant_front_image ? items.attributes.catalogue_variant_front_image : Noimageavailablepng} style={webStyle.productIMg} /></Box>
                                      <Box style={{ ...webStyle.tableHaderrowReady }}>{items.attributes.catalogue_name} x {items.attributes.quantity}</Box>
                                    </Box>
                                    ))}
                                  </TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowReady }}><Typography className="seller-ready-for-collection-body-typo">{sellerData.attributes.order_items[0].attributes.store_name}</Typography></TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowReady }}><Typography className="seller-ready-for-collection-body-typo">{sellerData.attributes.order_items[0].attributes.currency_logo} {sellerData.attributes.order_items[0].attributes.total_price}</Typography></TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowReady }}><Box className="btn-container"><button className="radyToCollectionStatusBtn" >{this.transSellerReadyForCollection("Ready for Collection")}</button></Box></TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>
                                        <Box className="btn-container"><button className="radyToOrderStatusBtn" >{this.transSellerReadyForCollection("Order Status")}</button></Box>
                                    </TableCell>
                                </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
            </Box>
                </CustomStyledTable>
          <Box style={webStyle.paginationMainBox}>
            <Box style={webStyle.paginationSecBox}>
              {startIndexInProcess} - {endIndexInProcess} {this.transSellerReadyForCollection("of")} {this.state.metaReadyForData?.total_record} {this.transSellerReadyForCollection("results")}
            </Box>
            <CustomStyledPagination
              page={this.state.readyForPage}
              count={this.state.metaReadyForData?.total_pages}
              onChange={this.handlePageChangeReadyFor}
              shape="rounded"
              color="primary"
              dir={i18n.dir()}
              />
          </Box>
            </Box>
      </ThemeProvider> 
    ); 
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
  menBox:{height: 'auto ', display: 'flex'},
  tableHaderReady: {
    fontSize: '16px', 
    color: "#375280",
    fontWeight: 700, 
    fontFamily: "Lato", 
    letterSpacing: "1px",
    WebkitTextStrokeWidth: "0.5px", 
  },
  tabeLableReadyForCollection: {
    color: "rgb(55, 82, 128)", 
    fontSize: '20px', 
    position: "absolute",
    fontWeight: 800, 
    fontFamily: 'Lato', 
    height: '50vh', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    top: "50%",
    left: "50%",
    transform: "translate(20%, 20%)"
},
loderBox:{top: '50%',left: '50%',transform: 'translate(-50%, -50%)',display: 'flex',justifyContent: 'center',alignItems: 'center'},
  tableHaderrowReady: {
    fontSize: '16px', 
    color: "#375280", 
    fontWeight: 400,
    verticalAlign: 'top',
    fontFamily: "Lato", 
  },
  tableHeaderBg: {
    backgroundColor: "#f0f0f0"
  },
  productDflex: {
    display: "flex",
    gap: 10
  },
  productIMg: {
    width: '34px',
    height:'34px',
    borderRadius:'2px',
    marginBottom:"12px",
  },
  ArrivingCell: {
    padding: '12px 16px',
    backgroundColor: '#F4F4F4',
    display: "flex",
    justifyContent: "space-between"
  },
  ArrivingText: {
    fontWeight: 400,
    color: "#375280",
    fontSize: "14px",
  },
  ArrivingTextValue: {
    fontWeight: 700,
    color: "#375280",
    fontSize: "14px",
    marginLeft: "8px"
  },
  readyForCollectionTableContainer: {
    marginTop: "20px"
  },
  tabsBoxReady: {
    width: '100%',
    paddingBottom: '20px',
    height: '75%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  CircularProgressColor: {
    color: "#375280"
  },
  paginationMainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: "23px 0px"
  },
  paginationSecBox: {
    color: "#475569", 
    fontSize: "14px", 
    fontWeight: 500, 
    fontFamily: 'Lato'
  }
}
const CustomStyledTable = styled(TableContainer)({
  '& .custom-table': {
    border: '1px solid #E2E8F0',
    borderRadius: "5px 5px 0px 0px"
  },
  '& .cell': {
    backgroundColor: '#ffffff',
    borderRadius: "5px 5px 0px 0px"
  },
  '& .MuiTableCell-sizeSmall': {
    padding: "15px 30px 15px 30px"
  },
  "& .radyToOrderStatusBtn": {
    color: "rgb(55, 82, 128)",
    width: "147px",
    border: "1px solid rgb(204, 190, 177)",
    height: "34px",
    fontWeight: 700,
    fontSize: "12px",
    borderRadius: "2px",
    backgroundColor: "#ffffff",
    textTransform: "none",
  },
  "& .seller-ready-for-collection-body-typo":{
    fontSize: '16px', 
    color: "#375280", 
    fontWeight: 400,
    verticalAlign: 'top',
    fontFamily: "Lato", 
    display: "flex"
  },
  "& .radyToCollectionStatusBtn": {
    color: "#D97706",
    width: "147px",
    border: "none",
    height: "34px",
    fontWeight: 500,
    fontSize: "12px",
    borderRadius: "2px",
    backgroundColor: "#FEF3C7",
    textTransform: "none",
  },
  "& .btn-container":{
    display: "flex"
  },
  "& .radyToArrivingBox": {
    color: "#D97706",
    width: "100%",
    border: "none",
    height: "34px",
    fontWeight: 500,
    fontSize: "12px",
    borderRadius: "2px",
    backgroundColor: "#F4F4F4",
    textTransform: "none",
  },
  "& .seller-ready-for-collection-head-typo":{
    fontSize: '16px', 
    color: "#375280",
    fontWeight: 700, 
    fontFamily: "Lato", 
    letterSpacing: "1px",
    WebkitTextStrokeWidth: "0.5px",
    display: "flex"
  }
})
const CustomTableRow = styled(TableRow)({
  "& .MuiTableCell-root": {
    borderBottom: "none"
  }
})
const CustomStyledPagination = styled(Pagination)(({ dir }: { dir: "rtl" | "ltr" }) => ({
  "& li:first-of-type .MuiPaginationItem-icon": {
      transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
  },
  '& .MuiPaginationItem-textPrimary.Mui-selected': {
    backgroundColor: "#375280"
  },
  '& .MuiPaginationItem-root': {
      color: '#375280',
  },
  "& li:last-of-type .MuiPaginationItem-icon": {
    transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
  },
  '& .Mui-selected': {
      color: 'white',
  },
}));
// Customizable Area End
