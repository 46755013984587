import React from "react";
// Customizable Area Start
import { Grid, Typography, TextField, Box } from "@material-ui/core";

import { Vector, scroll } from "./assets";
import Switch from "@material-ui/core/Switch";
import Header from "../../../components/src/Header";
import Sidebar from "../../../components/src/Sidebar";
import Footer from "../../../components/src/Footer";
import { styled, withStyles } from "@material-ui/core/styles";
import { button_, location } from "../src/assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { GoogleMap, Marker } from "@react-google-maps/api";
import CountrySelect from "../../../components/src/CountryDropDown.web";
import i18n from "../../../components/src/i18next/i18n";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
// Customizable Area End
import EditAddressController, {
  Props,
  CountrySuccess
} from "./EditAddressController.web";

export default class SuccessAddress extends EditAddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleToggle = this.handleToggle.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    // Customizable Area End
  }

  // Customizable Area Start
  

  renderLocationButton() {
    return (
      <div
        className="address_child"
        style={{
          fontSize: "16px",
          fontWeight: 700,
          color: "#FFFFFF",
          backgroundColor: "#CCBEB1",
          padding: "10px 16px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          width: "254px",
          height: "64px",
          marginBottom: "22px",
          borderRadius: "2px",
          fontFamily: 'Lato, sans-serif',
          marginLeft: "10px",
          justifyContent: "center",
          flexDirection: i18n.dir() === "ltr" ? "row": "row-reverse"
        }}
        onClick={this.handleUseCurrentLocation}
      >
        <img src={location} alt="location icon" />
        {this.tranSuccessAddress("Use my current location")}
      </div>
    );
  }
  
  renderSearchInput() {
    return (
      this.state.mapsLoaded && (
        <div style={{ marginLeft: "10px", marginBottom: "20px" }}>
          <input
            id="autocomplete"
            type="text"
            placeholder={this.tranSuccessAddress("Search for a place")}
            style={{
              width: "254px",
              padding: "10px",
              borderRadius: "4px",
              backgroundColor: "white",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              display: this.state.mapVisible ? "block" : "none",
            }}
          />
        </div>
      )
    );
  }
  renderGoogleMap() {
    return (
      this.state.mapsLoaded &&
      this.state.mapVisible &&
      this.state.currentLocation && (
        <GoogleMap
          zoom={14}
          mapContainerStyle={{ width: "100%", height: "400px" }}
          center={this.state.currentLocation} // Center map on currentLocation
        >
          {this.state.selectedLocation && (
            <Marker position={this.state.selectedLocation} /> // Place marker on selected location
          )}
        </GoogleMap>
      )
    );
  }

  
  handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLatLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
  
          this.setState({
            currentLocation: currentLatLng,
            selectedLocation: currentLatLng, 
            mapVisible: true, 
            formData: {
              ...this.state.formData,
              latitude: currentLatLng.lat,
              longitude: currentLatLng.lng,
            },
          });
        },
        (error) => {
          alert("Unable to retrieve your location. Please enable location services.");
        }
      );
    } 
  };

  
  handleADDAddressImgCSS = () =>{
    return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
  }

  navHeaderADDAddressMargin = () =>{
    return i18n.dir() === "ltr" ? "27px 0px 20px 40px" : "27px 40px 20px 0px"
  }

  nameADDAddressCSS = () => {
    return i18n.dir() === "ltr" ? "31px 0px 20px 40px" : "31px 40px 20px 0px"
  }

  welcomeADDAddressCSS = () => {
    return i18n.dir() === "ltr" ? "0px 0px 37px 40px" : "0px 40px 37px 0px"
  }
  render() {
    const { formData,errors } = this.state;
    const GreenSwitch = withStyles({
      switchBase: {
        color: "#fff",
        "&$checked": {
          transform: "translateX(22px)",
          color: "#fff",
          "& + $track": {
            backgroundColor: "#CCBEB1",
            opacity: 1,
            border: "none",
            height: 17
          }
        },
        "&$focusVisible $thumb": {
          color: "#4cd964",
          border: "6px solid #fff"
        }
      },
      thumb: {
        width: 19,
        height: 21
      },
      track: {
        borderRadius: 16 / 2,
        backgroundColor: "#E5E5EA",
        border: "1px solid #E5E5EA",
        opacity: 1,
        transition: "background-color 0.3s"
      },
      checked: {},
      focusVisible: {}
    })(Switch);

    return (
      //Merge Engine DefaultContainer
      <>
      {this.state.exploreBuyer ? <StylishBuyerHeader navigation={this.props.navigation} /> : <Header navigation={this.props.navigation}/>}
        <Grid
          style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }}
          container
          dir={i18n.dir()}
        >
          <Grid item>
            <div style={{...webStyle.innerContainer, margin: this.navHeaderADDAddressMargin()}}>
              <Typography style={webStyle.innerContainer13}>{this.tranSuccessAddress("Home")}</Typography>
              <img src={Vector} style={{transform: this.handleADDAddressImgCSS()}}/>
              <Typography style={webStyle.innerContainer23}>
              {this.tranSuccessAddress("Address")}
              </Typography>
            </div>
            <div>
              <Typography style={{...webStyle.innerContainer33, margin: this.nameADDAddressCSS()}}>
                <img src={scroll} />
               {this.state.fullName}
              </Typography>
            </div>
            <Typography style={{...webStyle.innerContainer43, margin: this.welcomeADDAddressCSS()}}>
            {this.tranSuccessAddress("Welcome to your Account")}
            </Typography>
            <Sidebar navigation={this.props.navigation}/>
          </Grid>
          
          <Grid item xs={12} md={6} style={{ margin: "0px auto" }}>
            <div
              style={{
                fontSize: "20px",
                fontWeight: 800,
                lineHeight: "33.5px",
                marginBottom: "29px",
                color: "#375280",
                display: "flex",
                alignItems: "center",
                gap: 8,
                marginTop: "93px",
                fontFamily:'Lato , sans-serif',
              }}
            >
              <img
                src={button_}
                onClick={this.getNavigationAddAddress}
                style={{ cursor: "pointer", transform: this.handleADDAddressImgCSS() }}
              />
              {this.tranSuccessAddress("Add Address")}
            </div>
            <div style={{ backgroundColor: "#F8F8F8" }}>
              <div>
                <div  style={webStyle.addAddressContainer as React.CSSProperties}>
                 
                    <div
                      className="address_child"
                      style={{
                        fontSize: "20px",
                        color: "#375280",
                        marginBottom: "22px",
                        marginLeft:"10px",
                        fontFamily:'Lato , sans-serif',
                      }}
                    >
                      {this.tranSuccessAddress("Add Address")}
                    </div>
                      
                    <div style={{ position: "relative" }}>
      {this.renderLocationButton()}
      {this.renderSearchInput()}
      {this.renderGoogleMap()}
    </div>
                    <Grid
                      container
                      spacing={2}
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                            
                          }}
                          
                        >
                          {this.tranSuccessAddress("Full Name")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>

                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          name="name"
                          placeholder={this.tranSuccessAddress("Enter Full Name")}
                          variant="outlined"
                          value={formData.name}
                          onChange={this.handleInputChange}
                        />
                         {errors.name && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.name}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MainGridContainer container>
                          <Grid item style={{ width: "100%" }}>
                            <Box>
                              <Typography
                                data-test-id="countryId"
                                className={"inputHeading"}
                              >
                                {this.tranSuccessAddress("Phone Number")}
                                <span style={{ color: '#375280' }}>*</span>
                              </Typography>
                              <Box
                                className={"phoneInput"}
                                style={{ width: "100%" }}
                              >
                                <CustomDropDownBox>
                                  <CountrySelect
                                    id="phoneCode"
                                    name="phoneCode"
                                    value={this.state.selectedCountry?.numeric_code || "+965"}
                                    placeholder={this.tranSuccessAddress("Select Phone Code")}
                                    onChange={this.handleSuccessAddressPhoneCodeChange}
                                    countryCode={this.state.countrySuccess}
                                    isBorder
                                  />
                                </CustomDropDownBox>
                                <TextField
                                  data-test-id="phoneNumberTextFiled"
                                  variant="outlined"
                                  className="inputFeild2"
                                  style={{ width: "100%" }}
                                  placeholder={this.tranSuccessAddress("Enter Contact Number")}
                                  inputProps={{ maxLength: 12 }}
                                  name="phone_number"
                                   value={formData.phone_number}
                                   onChange={this.handleInputChange}
                                  InputProps={{
                                    style: {
                                      gap: "4px",
                                      border: "0px",
                                      borderRadius: "8px",
                                      width: "100%"
                                    }
                                  }}
                                />
                              </Box>
                                 {errors.phone_number && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.phone_number}</span>}
                            </Box>
                          </Grid>
                        </MainGridContainer>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          {this.tranSuccessAddress("Street Name")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          placeholder={this.tranSuccessAddress("Enter Street Name")}
                          variant="outlined"
                          name="street"
                          value={formData.street}
                          onClick={this.handleUseCurrentLocation}
                        />
                        {errors.street && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.street}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          {this.tranSuccessAddress("House / Building Number")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          placeholder={this.tranSuccessAddress("Enter House / Building Number")}
                          name="house_or_building_number"
                         value={formData.house_or_building_number}
                          onChange={this.handleInputChange}
                        />
                        {errors.house_or_building_number && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.house_or_building_number}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          {this.tranSuccessAddress("Block")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          placeholder={this.tranSuccessAddress("Enter Block")}                        
                          name="block"
                          value={formData.block}
                          onChange={this.handleInputChange}
                        />
                        {errors.block && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.block}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          {this.tranSuccessAddress("Area")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          name="area"
                          placeholder={this.tranSuccessAddress("Enter Area")}
                          value={formData.area}
                          onChange={this.handleInputChange}
                        />
                        {errors.area && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.area}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          {this.tranSuccessAddress("City")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          placeholder={this.tranSuccessAddress("Enter City")}
                          name="city"
                          value={formData.city}
                          onChange={this.handleInputChange}
                        />
                        {errors.city && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.city}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          {this.tranSuccessAddress("Zip Code")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          name="zipcode"
                          value={formData.zipcode}
                          placeholder={this.tranSuccessAddress("Enter Zip Code")}
                          onChange={this.handleInputChange}
                        />
                        {errors.zipcode && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.zipcode}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          {this.tranSuccessAddress("Address Name")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          name="address_name"
                          value={formData.address_name}
                          placeholder={this.tranSuccessAddress("Enter Address")}
                          onChange={this.handleInputChange}
                        />
                        {errors.address_name && <span style={{ color: '#f44336',fontSize:"16px",fontFamily: "Lato , sans-serif",fontWeight:100 }}>{errors.address_name}</span>}
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#375280",
                        fontSize: "16px",
                        fontWeight: 500
                      }}
                    >
                      <GreenSwitch
                        checked={this.state.isChecked}
                        onChange={this.handleToggle}
                        inputProps={{ "aria-label": "toggle switch" }}
                      />
                      {/* <p>Toggle is {this.state.isChecked ? 'ON' : 'OFF'}</p> */}
                      <p>{this.tranSuccessAddress("Make it default delivery address")}</p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 22,
                        paddingInline: 10
                      }}
                    >
                      <div style={webStyle.button12} onClick={this.getNavigationAddAddress}>{this.tranSuccessAddress("Cancel")}</div>
                      <div
                        style={{ ...webStyle.button12, ...webStyle.saveButton }}
                        onClick={this.handleSubmit}
                      >
                        {this.tranSuccessAddress("Save")}
                      </div>

                    </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Snackbar
                  open={this.state.isAlert}
                  autoHideDuration={3000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  onClose={this.oncloseAlert}
                  data-test-id="alertTestId"
                >
                  <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
                <Footer navigation={this.props.navigation}/>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  addAddressContainer: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    marginBottom: "24px",
    boxShadow: "0px 2px 8px 0px #00000014",
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Lato , sans-serif",
    lineHeight: "26px",
    padding:"20px"
  },
  button12: {
    padding: "10px 16px 10px 16px",
    width: "50%",
    height: "64px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
    color: "#375280",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    cursor:"pointer"
  },
  saveButton: {
    color: "white",
    backgroundColor: "#CCBEB1"
  },
  dropdown: {
    position: "absolute",
    top: "100%",
    left: 0,
    backgroundColor: "white",
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: "4px",
    zIndex: 1,
    width: "120px"
  },
  dropdownP: {
    borderBottom: "3px solid #D5D5D5",
    paddingBottom: "10px",
    paddingLeft: "6px",
    paddingRight: "6px",
    color: "#375280",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "22px"
  },
  dropdownP1: {
    paddingLeft: "6px",
    paddingRight: "6px",
    color: "#DC2626",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "22px"
  },
  innerContainer: {
    alignItems: "center",
    color: "#475569",
    display: "flex",
    fontWeight: 400,
    marginLeft: "40px",
    marginTop: "27px",
    lineHeight: "22px",
    gap: "10px"
  },
  innerContainer13: {
    lineHeight: "22px",
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
    padding: "4px 6px 4px 6px"
  },
  innerContainer23: {
    fontStyle: "normal",
    padding: "4px 6px 4px 6px",
    color: "#475569",
    fontFamily: "Lato , sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 700
  },
  innerContainer33: {
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "33.5px",
    fontFamily: "Lato , sans-serif",
    color: "#375280",
    marginLeft: "40px",
    gap: "15px",
    marginTop: "31px",
    display: "flex",
    fontSize: "28px"
  },
  innerContainer43: {
    marginLeft: "40px",
    fontWeight: 400,
    fontFamily: "Lato , sans-serif",
    fontSize: "14px",
    marginBottom: "37px",
    fontStyle: "normal",
    color: "#807D7E",
    lineHeight: "33.5px"
  }
};
const InputField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#FFFFFF"
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#FFFFFF"
    }
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#F0F0F0",
    height: "56px",
    margin: "auto",
    borderRadius: "8px",
    fontSize: "16px",
    "@media only screen and (max-width: 414px)": {
      fontSize: "14px"
    }
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#FFFFFF",
    borderWidth: "1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FFFFFF",
    borderWidth: "1px",
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 16px 10px 16px",
    color: "#1E293B",
    "&::placeholder": {
      color: "#94A3B8",
      fontSize: "16px",
      "@media only screen and (max-width: 414px)": {
        fontSize: "14px"
      }
    },
    "&:not(:placeholder-shown)": {
      color: "#1E293B"
    }
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1"
  }
});
const MainGridContainer = styled(Grid)({
  display: "flex",
  justifyContent: "center",

  "& .mainCotainer": {
    marginBottom: "40px",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir,sans-serif",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
    alignSelf: "flex-start"
  },

  "& .inputHeading": {
    fontFamily: "Lato, sans-serif",
    fontStyle: "normal",
    marginBottom: "10px",
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#375280"
  },
  "& .inputFeild": {
    margin: "0px 10px 0px 10px !important"
  },
  "& .phoneInput": {
    display: "flex",
    gap: "15px",
    height: "56px",
    width: "100%px"
  },

  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px",
    textOverflow: "ellipsis",
    cursor: "pointer",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px"
  },

  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      border: "none"
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    }
  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important"
      }
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "1px solid #F0F0F0 !important"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    }
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .phoneInput": { width: "100%", height: "56px" },
    "& .inputFeild2": {
      "& .MuiTextField-root": {
        "&:hover": {
          border: "1px solid #F0F0F0 !important"
        }
      },
      "& .MuiOutlinedInput-root:hover": {
        border: "1px solid #F0F0F0 !important"
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#F0F0F0",
        borderRadius: "2px"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiInputBase-input": {
        color: "#375280"
      },
      "& .MuiFormHelperText-contained": {
        margin: "0 !important"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      width: "300px"
    }
  }
});
const CustomDropDownBox = styled(Box)({
  position: "relative",
  cursor: "pointer",
  width: "210px"
});
const DropDownHeaderMenu = styled(Box)({
  padding: "10px",
  gap: "15px",
  display: "flex",
  alignItems: "center",
  border: "none",
  borderRadius: "2px",
  background: "#F0F0F0",
  height: "36px",
  color: "#375280",
  fontSize: "18px",
  "&.isActive": {
    border: "1px solid red"
  },
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});

const SelectedCountryList = styled(Box)({
  display: "flex",
  alignItems: "center",
});
const DropDownOptionsList = styled(Box)({
  left: 0,
  width: "100%",
  border: "1px solid #ccc",
  borderTop: "none",
  borderRadius: "0 0 4px 4px",
  backgroundColor: "#fff",
  position: "absolute",
  zIndex: 1,
  height: "60vh",
  overflowX: "hidden",
  color: "#375280",
});
const OptionsValueList = styled(Box)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});
// Customizable Area End
