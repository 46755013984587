import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { Grid, styled, Box, Typography } from "@material-ui/core";
import StylishCatalogueController, {
  Props,
} from "./StylishCatalogueController";
import StylishSidebar from "../../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../../components/src/Stylish/StylishHeader";
import { ThemeProvider } from "react-native-elements";
import { rightArrowIcon } from "../../../customform/src/assets";
import i18n from "../../../../components/src/i18next/i18n";
import Loader from "../../../../components/src/LoaderContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylishCatalogue extends StylishCatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleBackCSS = () => {
    return i18n.dir()==='ltr' ? "rotate(0deg)": "rotate(180deg)";
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container dir={i18n.dir()}>
          <Grid item xl={2} md={2} id="stylish-sidebar-catalogue" lg={2} sm={2} xs={2}>
            <StylishSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid item xl={10} md={10} id="stylish-header-catalogue" lg={10} sm={10} xs={10}>
            <StylishHeader navigation={this.props.navigation} />
            <StylishCatalogueContainer>
            {this.state.languageLoading ? (
                <Box className="loader-container">
                  <Loader
                    id="my-offer-loader"
                    dataTestId="my-offer-loader-test"
                  />
                </Box>
              ):<><Typography className="main-title">{this.transStylishCatalogue("Catalogus")}</Typography>
              <Grid container spacing={3}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box
                    data-test-id={`product-sourcing-test-id`}
                    onClick={()=>this.handleCatalogueRedirect("StylishProductSourcing")}
                    className={`btn-container`}
                  >
                    <Typography className={`btn-name-title`}>
                      {this.transStylishCatalogue("Product Sourcing")}
                    </Typography>
                    <img src={rightArrowIcon} style={{transform: this.handleBackCSS(), cursor: "pointer"}} alt="right-icon" />
                  </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box
                    data-test-id={`catalogue-test-id`}
                    onClick={()=>this.getNavigationMessage()}
                    className={`btn-container`}
                  >
                    <Typography className={`btn-name-title`}>
                    {this.transStylishCatalogue("Catalogue")}
                    </Typography>
                    <img src={rightArrowIcon} style={{transform: this.handleBackCSS(), cursor: "pointer"}} alt="right-icon" />
                  </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box
                    data-test-id={`catalogue-test1-id`}
                    onClick={()=>this.getNavigateServiceMessage()}
                    className={`btn-container`}
                  >
                    <Typography className={`btn-name-title`}>
                    {this.transStylishCatalogue("Styling Services")}
                    </Typography>
                    <img src={rightArrowIcon} style={{transform: this.handleBackCSS(), cursor: "pointer"}} alt="right-icon" />
                  </Box>
                </Grid>
              </Grid></>}
            </StylishCatalogueContainer>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const StylishCatalogueContainer = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
  "& .main-title": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    fontSize: 24,
    color: "#375280",
    width: "100%",
    paddingBottom: 47,
  },
  "& .btn-container": {
    border: "1px solid #E2E8F0",
    borderRadius: 2,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "38px 26px",
    boxShadow: "0px 2px 8px 0px #00000014",
    "& .btn-name-title": {
      fontFamily: "Lato, sans-serif",
      color: "#375280",
      fontSize: 22,
      fontWeight: 700,
    },
    "&:hover": {
      backgroundColor: "#F1F5F9",
      "& .btn-name-title": {
        fontSize: 24,
      },
    },
  },
});
// Customizable Area End
