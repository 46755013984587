import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { availableResources } from "./resource";
import { getStorageData } from "../../../framework/src/Utilities";

const initI18n = async () => {
  const language = await getStorageData('lang') || 'en';

  try {
    i18n
      .use(initReactI18next)
      .init({
        lng: language,
        resources: availableResources,
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false,
        },
      });
  } catch (error) {
    console.error('Error initializing i18n:', error);
  }
};

initI18n();
export default i18n;

export const i18nUpdate = async () => {
    const currentLang = await getStorageData('lang');
    if (currentLang) {
        i18n.changeLanguage(currentLang);
    }
}

export const ensureI18nInitialized = async (callback: () => void) => {
  if (i18n.isInitialized) {
    callback();
  } else {
    i18n.on('initialized', () => {
      callback();
    });
  }
};
