import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React, { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  zylerOutfitId: string | null;
  buttonRef: React.RefObject<HTMLInputElement>
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MobileZylerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCatalogueApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: true,
      zylerOutfitId: "",
      buttonRef: createRef()
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCatalogueApiCallId != null &&
      this.getCatalogueApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
  console.log("message")
    }
    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start

  // Customizable Area End
  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    const params = new URLSearchParams(window.location.search);
    const zylerOutfitId = params.get('id');
    this.setState({zylerOutfitId})
    

    setTimeout(this.checkAndClick, 1500);
    // Customizable Area End
  }
   checkAndClick = () => {
    if (this.state.buttonRef.current) {
      try {
        this.state.buttonRef.current.click();
        this.setState({isLoading: false});
      } catch (error) {
        console.error("Error triggering Zyler popup:", error);
      }
    }
  };
  // Customizable Area End
}